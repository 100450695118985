import { reactive, toRefs } from "vue";
import { domainUser } from "@/stores/domainUserStore";
import { priceList } from "@/stores/priceListStore";
import { domain } from "@/stores/domainStore";
import { useDomainUserStore } from "@/stores/customDomainUserStore";
import { tax } from "@/stores/taxStore";
import { changesLog } from "@/stores/changesLogStore";
import { useLookupStore } from "@/stores/customLookupStore";
export function usePriceList() {
    const domainUserStore = useDomainUserStore();
    const lookupStore = useLookupStore();
    const state = reactive({
        tax,
        domain,
        domainUser,
        priceList,
        oldDataLog: null,
        columnChooserMode: "select",
        admin: domainUserStore.getDomainUser,
        domainPermissions: domainUserStore.domainPermissions
    });
    function handleRowInitPriceList(e) {
        const now = new Date();
        e.data = {
            ...e.data,
            createdById: state.admin.id,
            modifiedById: state.admin.id,
            createdDate: now.toISOString(),
            modifiedDate: now.toISOString(),
            domainId: state.admin.domainId
        };
    }
    function handleRowUpdatingPriceList(e) {
        const now = new Date();
        e.newData = {
            ...e.newData,
            modifiedById: state.admin.id,
            modifiedDate: now.toISOString()
        };
        state.oldDataLog = [{ priceList: e.oldData }];
    }
    async function handleRowUpdatedPriceList(e) {
        const newData = await priceList.byKey(e.key);
        const newDataLog = [{ priceList: newData }];
        const changesLogData = {
            actionType: "UPDATE",
            documentType: "price_list",
            oldValue: JSON.stringify(state.oldDataLog),
            newValue: JSON.stringify(newDataLog),
            modifiedById: state.admin.id,
            modifiedDate: new Date().toISOString()
        };

        await changesLog.insert(changesLogData);
        await lookupStore.fetchLookups(state.admin.domainId);
    }
    async function handleRowInsertedPriceList(e) {
        const logData = [{ priceList: e.data }];
        const changesLogData = {
            actionType: "INSERT",
            documentType: "price_list",
            oldValue: null,
            newValue: JSON.stringify(logData),
            modifiedById: state.admin.id,
            modifiedDate: new Date().toISOString()
        };
        await changesLog.insert(changesLogData);
        await lookupStore.fetchLookups(state.admin.domainId);
    }

    return {
        ...toRefs(state),
        handleRowInitPriceList,
        handleRowUpdatingPriceList,
        handleRowInsertedPriceList,
        handleRowUpdatedPriceList
    };
}
