<template>
    <!-- <div class="table-container"> -->
    <div class="table-name">
        <p>E-mail log</p>
    </div>
    <DxDataGrid
        id="emailLogTable"
        :data-source="emailLog"
        :repaint-changes-only="true"
        :show-column-lines="true"
        :show-row-lines="true"
        :show-borders="true"
        :column-auto-width="true"
        :hover-state-enabled="true"
        :focused-row-enabled="true"
        :row-alternation-enabled="true"
        :allow-column-resizing="true"
        @initialized="getEmailLogTableInstance"
        @focused-row-changed="onFocusedRowChangedEmailLog"
    >
        <DxPaging :page-size="20" />
        <DxPager
            :visible="true"
            display-mode="adaptive"
            :show-info="true"
            info-text="Stranica {0} od {1} ({2} stavki)"
            :show-navigation-buttons="true"
            :show-page-size-selector="true"
            :allowed-page-sizes="[10, 20, 30]"
        />
        <DxStateStoring
            :enabled="true"
            type="localStorage"
            storage-key="emailLogTableState"
        />
        <DxToolbar>
            <DxItem
                :options="emailLogRefreshButtonOptions"
                location="after"
                locate-in-menu="auto"
                widget="dxButton"
            />
            <!-- <DxItem
                :options="columnChooserButtonOptions"
                location="after"
                locate-in-menu="auto"
                widget="dxButton"
            /> -->
        </DxToolbar>
        <DxHeaderFilter :visible="true" />
        <DxScrolling column-rendering-mode="virtual" />
        <DxColumn
            :width="80"
            data-field="id"
            data-type="number"
            caption="ID"
            :allow-hiding="false"
        />
        <DxColumn
            data-field="dateSent"
            data-type="datetime"
            format="dd/MM/yyyy HH:mm"
            caption="Datum"
            :allow-hiding="false"
        />
        <DxColumn
            data-field="sourceType"
            data-type="string"
            caption="Tip izvora"
            :allow-hiding="false"
        />
        <DxColumn
            data-field="sourceId"
            data-type="number"
            caption="ID izvora"
            :allow-hiding="false"
        />
        <!-- <DxColumn
            data-field="senderId"
            data-type="number"
            caption="Pošiljatelj"
            :allow-hiding="false"
        >
            <DxLookup
                :data-source="domainUser"
                value-expr="id"
                display-expr="username"
            />
        </DxColumn> -->
        <DxColumn
            data-field="emailFrom"
            data-type="string"
            caption="Pošiljatelj"
            :allow-hiding="false"
        />
        <!-- <DxColumn
            data-field="clientId"
            data-type="number"
            caption="Primatelj"
            :allow-hiding="false"
        >
            <DxLookup
                :data-source="domainUser"
                value-expr="id"
                display-expr="username" />
            <DxLookup
                :data-source="customer"
                value-expr="id"
                :display-expr="username" />
            <DxLookup
                :data-source="user"
                value-expr="id"
                :display-expr="username"
        /></DxColumn> -->
        <DxColumn
            data-field="emailTo"
            data-type="string"
            caption="Primatelj"
            :allow-hiding="false"
        />
        <!-- <DxColumn
            data-field="subject"
            data-type="string"
            caption="Naslov"
            :allow-hiding="false"
        />
        <DxColumn
            data-field="message"
            data-type="string"
            caption="Poruka"
            :allow-hiding="false"
        /> -->
        <DxColumn
            type="buttons"
            :auto-width="true"
            :fixed="true"
            :show-in-column-chooser="false"
        >
            <DxButton
                hint="Informacije o mailu"
                icon="info"
                @click="openEmailLogPopup"
            />
        </DxColumn>
        <!-- <DxColumnChooser :enabled="true" :mode="columnChooserMode">
            <DxColumnChooserSelection
                :allow-select-all="true"
                :select-by-click="true"
                :recursive="true"
            />
        </DxColumnChooser> -->
    </DxDataGrid>
    <DxPopup
        id="emailLogPopup"
        :resize-enabled="true"
        :drag-outside-boundary="true"
        :toolbarItems="emailLogPopupToolbarItems"
        title="Informacije o poslanom E-mail-u"
        @initialized="getEmailLogPopupInstance"
    >
        <p style="margin-bottom: 1vh">Subjekt:</p>
        <DxTextBox :value="subject" :read-only="true" />
        <p style="margin-bottom: 1vh">Poruka:</p>
        <DxHtmlEditor
            value-type="html"
            :value="message"
            :read-only="true"
            label="Poruka"
            height="725px"
            class="dxhtmleditor"
            @initialized="getHtmlEditorInstance"
        >
        </DxHtmlEditor>
    </DxPopup>
    <!-- </div> -->
    <!-- <div class="split"></div> -->
</template>
<script>
//DevExpress
import {
    DxDataGrid,
    DxColumn,
    DxScrolling,
    DxButton,
    DxToolbar,
    DxItem,
    // DxSearchPanel,
    DxHeaderFilter,
    // DxLookup,
    DxStateStoring,
    // DxColumnChooser,
    // DxColumnChooserSelection,
    DxPaging,
    DxPager
} from "devextreme-vue/data-grid";
import DxTextBox from "devextreme-vue/text-box";
import notify from "devextreme/ui/notify";
import { emailLog } from "@/stores/emailLogStore";
import { domainUser } from "@/stores/domainUserStore";
import { customer } from "@/stores/customerStore";
import { user } from "@/stores/userStore";
import { DxPopup } from "devextreme-vue/popup";
import { DxHtmlEditor } from "devextreme-vue/html-editor";
// import eventBus from "../../eventBus.js";
import { ref } from "vue";

export default {
    name: "emailLogTableComponent",
    components: {
        DxItem,
        DxDataGrid,
        DxColumn,
        DxScrolling,
        DxButton,
        DxTextBox,
        DxToolbar,
        DxHeaderFilter,
        // DxLookup,
        DxStateStoring,
        DxPopup,
        // DxColumnChooser,
        // DxColumnChooserSelection,
        DxPaging,
        DxPager,
        DxHtmlEditor
    },
    setup() {
        //table instances
        const tableInstance = ref(null);
        const popupInstance = ref(null);
        const htmlEditorInstance = ref(null);
        const subject = ref("TEST");
        const message = ref(`TESTI<br>--<br>
            <strong>Luka Benković</strong><br>
            `);
        const emailLogPopupToolbarItems = ref([
            {
                widget: "dxButton",
                location: "after",
                toolbar: "bottom",
                options: {
                    text: "OK",
                    type: "success",
                    icon: "check",
                    width: 120,
                    useSubmitBehavior: true,
                    onClick: () => {
                        popupInstance.value.hide();
                    }
                }
            }
        ]);
        //general data
        // const selectedRowKey = ref(null);

        //button item options
        const emailLogRefreshButtonOptions = ref({
            icon: "refresh",
            onClick: () => {
                refreshData();
            }
        });
        // const columnChooserButtonOptions = ref({
        //     icon: "columnchooser",
        //     onClick: () => {
        //         openColumnChooser();
        //     }
        // });

        //instances
        const getEmailLogTableInstance = (e) => {
            tableInstance.value = e.component;
        };
        const getEmailLogPopupInstance = (e) => {
            popupInstance.value = e.component;
        };
        const getHtmlEditorInstance = (e) => {
            htmlEditorInstance.value = e.component;
        };
        //dispatch note table methods
        const onFocusedRowChangedEmailLog = (e) => {
            message.value = e.row.data.message;
            subject.value = e.row.data.subject;
        };

        //button methods
        const openEmailLogPopup = () => {
            popupInstance.value.show();
        };

        const refreshData = () => {
            const message = "Podaci osvježeni!";
            tableInstance.value.refresh();
            notify({ message, width: 450 }, "success");
        };
        // const openColumnChooser = () => {
        //     tableInstance.value.showColumnChooser();
        // };

        //General methods
        const username = (item) => {
            return item.userType === "business"
                ? `${item.id} | ${item.companyName}, ${item.name} ${item.lastname}`
                : item.name || item.lastname
                ? `${item.id} | ${item.name} ${item.lastname}`
                : `${item.id} | ${item.sign}`;
        };

        return {
            emailLogRefreshButtonOptions,
            // columnChooserButtonOptions,
            getEmailLogTableInstance,
            // onFocusedRowChangedEmailLog,
            username,
            emailLog,
            domainUser,
            customer,
            user,
            emailLogPopupToolbarItems,
            getEmailLogPopupInstance,
            openEmailLogPopup,
            getHtmlEditorInstance,
            message,
            subject,
            onFocusedRowChangedEmailLog
        };
    }
};
</script>
<style scoped>
.table-container {
    margin: 2vh;
    max-width: 100%;
}
#eInvoiceOutgoingTable {
    max-width: 100%;
}
#dispatchNoteItemTable {
    max-width: 100%;
}
.table-name {
    font-size: 1.6rem;
    text-align: center;
    margin: 1vh;
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.split {
    border: 0.2px solid #1c1c1c;
    margin: 0.5vh;
    /* width: 84.5vw; */
    max-width: 100vw;
}
</style>
