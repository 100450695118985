<template>
    <!-- <div class="table-container"> -->
    <DxDataGrid
        v-if="shouldShowTheDataGrid && !lookupLoading"
        id="priceListItemsTable"
        :data-source="priceListItem"
        :repaint-changes-only="true"
        :show-column-lines="true"
        :show-row-lines="true"
        :show-borders="true"
        :row-alternation-enabled="true"
        :column-min-width="70"
        :column-auto-width="true"
        :allow-column-resizing="true"
        @initialized="getPriceListItemTableInstance"
        @init-new-row="onInitNewRowPriceListItem"
        @row-inserting="onRowInsertingPriceListItem"
        @row-inserted="onRowInsertedPriceListItem"
        @row-updating="onRowUpdatingPriceListItem"
        @row-updated="onRowUpdatedPriceListItem"
        @row-removed="onRowRemovedPriceListItem"
        @editing-start="onEditingStartPriceListItem"
        @exporting="onExporting"
    >
        <DxExport :enabled="true" :formats="['pdf']" />
        <DxStateStoring
            :enabled="true"
            type="localStorage"
            storage-key="priceListItemTableState"
        />
        <DxToolbar>
            <DxItem
                :options="addButtonOptions"
                location="after"
                locate-in-menu="auto"
                widget="dxButton"
            />
            <DxItem
                :options="refreshButtonOptions"
                location="after"
                locate-in-menu="auto"
                widget="dxButton"
            />
            <DxItem
                :options="columnChooserButtonOptions"
                location="after"
                locate-in-menu="auto"
                widget="dxButton"
            />
            <DxItem name="exportButton" />
            <DxItem name="searchPanel" location="after" locate-in-menu="auto" />
        </DxToolbar>
        <DxSearchPanel :visible="true" />
        <DxHeaderFilter :visible="true" />
        <DxScrolling column-rendering-mode="virtual" />
        <DxPager
            :visible="true"
            display-mode="adaptive"
            :show-info="true"
            info-text="Stranica {0} od {1} ({2} stavki)"
            :allowed-page-sizes="[10, 20, 30]"
            :show-navigation-buttons="true"
            :show-page-size-selector="true"
        />
        <DxPaging :page-size="10" />
        <DxEditing :allow-updating="true" :use-icons="true" mode="popup">
            <DxPopup
                :show-title="true"
                title="Informacije o stavci"
                :drag-outside-boundary="true"
            />
            <DxForm>
                <DxItem :col-count="3" :col-span="2" item-type="group">
                    <DxItem
                        data-field="id"
                        data-type="number"
                        :visible="false"
                    />
                    <DxItem
                        item-type="group"
                        :col-count="3"
                        :col-span="3"
                        caption="Osnovne informacije"
                    >
                        <!-- <DxItem
                                data-field="itemGroupId"
                                data-type="number"
                            /> -->
                        <DxItem data-field="code" data-type="string">
                            <DxRequiredRule />
                        </DxItem>
                        <DxItem data-field="name" data-type="string"
                            ><DxRequiredRule
                        /></DxItem>
                        <DxItem data-field="stockNumber" data-type="string" />
                        <DxItem data-field="foreignName" data-type="string" />
                        <DxItem data-field="ean" data-type="string" />
                        <DxItem data-field="customsNumber" data-type="string" />
                    </DxItem>
                    <DxItem
                        item-type="group"
                        :col-count="3"
                        :col-span="3"
                        caption="Tip/Dobavljač"
                    >
                        <DxItem data-field="measureUnit" data-type="string"
                            ><DxRequiredRule
                        /></DxItem>
                        <DxItem
                            data-field="vatId"
                            data-type="number"
                            editor-type="dxSelectBox"
                            :editor-options="vatIdOptions"
                            ><DxRequiredRule
                        /></DxItem>
                        <DxItem
                            data-field="itemType"
                            data-type="string"
                            editor-type="dxSelectBox"
                            :editor-options="itemTypeOptions"
                            ><DxRequiredRule
                        /></DxItem>
                        <DxItem
                            data-field="supplierId"
                            data-type="number"
                            editor-type="dxSelectBox"
                            :editor-options="supplierSelectBoxOptions"
                        ></DxItem>
                    </DxItem>
                    <DxItem
                        item-type="group"
                        :col-count="3"
                        :col-span="3"
                        caption="Informacije o cijeni"
                    >
                        <DxItem
                            data-field="latestProcurementPrice"
                            data-type="number"
                            :disabled="true"
                        />
                        <DxItem
                            data-field="priceCalculationType"
                            data-type="string"
                            editor-type="dxSelectBox"
                            :editor-options="priceCalculationTypeOptions"
                        />
                        <DxItem
                            data-field="wholesalePrice"
                            data-type="number"
                            :editor-options="wholesalePriceOptions"
                        >
                            <DxRequiredRule
                        /></DxItem>
                        <DxItem
                            data-field="vatAmount"
                            data-type="number"
                            :editor-options="vatAmountOptions"
                        />
                        <DxItem
                            data-field="retailPrice"
                            data-type="number"
                            :editor-options="retailPriceOptions"
                            ><DxRequiredRule
                        /></DxItem>
                        <DxItem data-field="margin" data-type="number" />
                    </DxItem>
                    <DxItem
                        item-type="group"
                        :col-count="3"
                        :col-span="3"
                        caption="Ostale informacije"
                    >
                        <DxItem
                            data-field="returnPackagingQty"
                            data-type="number"
                        />
                        <DxItem data-field="orderedQty" data-type="number" />
                        <DxItem data-field="minStock" data-type="number" />
                        <DxItem data-field="maxStock" data-type="number" />
                        <DxItem
                            data-field="hidden"
                            data-type="number"
                            :editor-options="hiddenOptions"
                            editor-type="dxSelectBox"
                        />
                    </DxItem>
                </DxItem>
            </DxForm>
        </DxEditing>
        <DxColumnFixing :enabled="true" />
        <DxColumn
            caption="Rb."
            :width="50"
            alignment="center"
            :calculate-cell-value="setRowIndex"
            :allow-hiding="false"
            :show-in-column-chooser="false"
        />
        <DxColumn
            data-field="id"
            data-type="number"
            caption="ID"
            :allow-exporting="false"
        />
        <!-- <DxColumn
                data-field="itemGroupId"
                data-type="number"
                caption="Grupa"
            /> -->
        <DxColumn
            data-field="code"
            data-type="string"
            caption="Šifra"
            sort-order="asc"
            :calculate-cell-value="formatedNumber"
        />
        <DxColumn data-field="name" data-type="string" caption="Naziv" />
        <DxColumn
            data-field="stockNumber"
            data-type="string"
            caption="Kataloški broj"
            :allow-exporting="false"
        />
        <DxColumn
            data-field="foreignName"
            data-type="string"
            caption="Strani naziv"
            :allow-exporting="false"
        />
        <DxColumn
            data-field="ean"
            data-type="string"
            caption="EAN"
            :allow-exporting="false"
        />
        <DxColumn
            data-field="customsNumber"
            data-type="string"
            caption="Carinski broj"
            :allow-exporting="false"
        />
        <DxColumn data-field="measureUnit" data-type="string" caption="JMJ" />
        <DxColumn
            data-field="vatId"
            data-type="number"
            caption="PDV"
            :allow-exporting="false"
            format="###,###,###,##0.00"
        >
            <DxLookup
                :items="lookupData.tax"
                value-expr="id"
                display-expr="taxValue"
            />
        </DxColumn>
        <DxColumn
            data-field="itemType"
            data-type="string"
            caption="Roba/Usluga"
            :allow-exporting="false"
        />
        <DxColumn
            data-field="returnPackagingQty"
            data-type="number"
            caption="Povratna ambalaža"
            :allow-exporting="false"
        />
        <DxColumn
            data-field="orderedQty"
            data-type="number"
            caption="Naručeno"
            :allow-exporting="false"
        />
        <DxColumn
            data-field="minStock"
            data-type="number"
            caption="Min. zaliha"
            :allow-exporting="false"
        />
        <DxColumn
            data-field="maxStock"
            data-type="number"
            caption="Max. zaliha"
            :allow-exporting="false"
        />
        <DxColumn
            data-field="latestProcurementPrice"
            data-type="number"
            caption="Zadnja nabavna"
            :allow-exporting="false"
            format="###,###,###,##0.00"
        />
        <DxColumn
            data-field="priceCalculationType"
            data-type="string"
            caption="Izračun cijene"
            :allow-exporting="false"
        />
        <DxColumn
            data-field="wholesalePrice"
            data-type="number"
            caption="Veleprodajna"
            format="###,###,###,##0.00"
        />
        <DxColumn
            data-field="vatAmount"
            data-type="number"
            caption="Iznos PDV-a"
            :allow-exporting="false"
            format="###,###,###,##0.00"
        />
        <DxColumn
            data-field="retailPrice"
            data-type="number"
            caption="Maloprodajna"
            :allow-exporting="false"
            format="###,###,###,##0.00"
        />
        <DxColumn
            data-field="margin"
            data-type="number"
            caption="Marža"
            :allow-exporting="false"
            format="###,###,###,##0.00"
        />
        <DxColumn
            data-field="createdById"
            data-type="number"
            caption="Kreirao"
            :allow-exporting="false"
        >
            <DxLookup
                :items="lookupData.domainUser"
                value-expr="id"
                display-expr="username"
            />
        </DxColumn>
        <DxColumn
            data-field="createdDate"
            data-type="datetime"
            format="dd/MM/yyyy HH:mm"
            caption="Kreirano"
            :allow-exporting="false"
        />
        <DxColumn
            data-field="modifiedById"
            data-type="number"
            caption="Uredio"
            :allow-exporting="false"
        >
            <DxLookup
                :items="lookupData.domainUser"
                value-expr="id"
                display-expr="username"
            />
        </DxColumn>
        <DxColumn
            data-field="modifiedDate"
            data-type="datetime"
            format="dd/MM/yyyy HH:mm"
            caption="Uređeno"
            :allow-exporting="false"
        />
        <DxColumn
            data-field="supplierId"
            data-type="number"
            caption="Dobavljač"
            :allow-exporting="false"
        >
            <DxLookup
                :items="lookupData.supplier"
                value-expr="id"
                :displayExpr="supplierName"
            />
        </DxColumn>
        <DxColumn
            data-field="priceListId"
            data-type="number"
            caption="Cijenik"
            :allow-exporting="false"
        >
            <DxLookup
                :items="lookupData.priceList"
                value-expr="id"
                display-expr="name"
            />
        </DxColumn>
        <DxColumn
            data-field="hidden"
            data-type="number"
            caption="Skriveno"
            :allow-exporting="false"
        />
        <DxColumn
            type="buttons"
            :auto-width="true"
            :fixed="true"
            :show-in-column-chooser="false"
        >
            <DxButton
                hint="Uredi"
                icon="edit"
                @click="editPriceListItem"
                :visible="areEditAndDeleteIconsVisible"
            />
            <!-- <DxButton
                    hint="Obriši"
                    icon="trash"
                    @click="deletePriceListItem"
                    :visible="areEditAndDeleteIconsVisible"
                /> -->
            <DxButton
                hint="Spremi"
                icon="check"
                @click="savePriceListItem"
                :visible="areSaveAndCancelIconsVisible"
            />
            <DxButton
                hint="Odustani"
                icon="close"
                @click="cancelPriceListItem"
                :visible="areSaveAndCancelIconsVisible"
            />
        </DxColumn>
        <DxColumnChooser :enabled="true" :mode="columnChooserMode">
            <DxColumnChooserSelection
                :allow-select-all="true"
                :select-by-click="true"
                :recursive="true"
            />
        </DxColumnChooser>
    </DxDataGrid>
    <!-- <subscriptionPlansPopupComponent /> -->
    <!-- </div> -->
</template>
<script>
//DevExpress
import {
    DxItem,
    DxForm,
    DxPopup,
    DxPager,
    DxExport,
    DxPaging,
    DxColumn,
    DxButton,
    DxLookup,
    DxToolbar,
    DxEditing,
    DxDataGrid,
    DxScrolling,
    DxSearchPanel,
    DxRequiredRule,
    DxStateStoring,
    DxColumnFixing,
    DxHeaderFilter,
    DxColumnChooser,
    DxColumnChooserSelection
} from "devextreme-vue/data-grid";
import notify from "devextreme/ui/notify";
// import subscriptionPlansPopupComponent from "./subscriptionPlansPopupComponent.vue";
import { checkSubscriptionLimit } from "@/utils/checkSubscriptionLimits.js";
import { useLookupStore } from "@/stores/customLookupStore.js";

import { usePriceListItem } from "@/composables/usePriceListItem";
import { ref, watch, onMounted, onUnmounted } from "vue";

// //Utils
import eventBus from "../../eventBus.js";

export default {
    name: "priceListItemTableComponent",
    components: {
        DxItem,
        DxForm,
        DxPopup,
        DxColumn,
        DxExport,
        DxButton,
        DxLookup,
        DxEditing,
        DxToolbar,
        DxDataGrid,
        DxPager,
        DxPaging,
        DxScrolling,
        DxSearchPanel,
        DxColumnFixing,
        DxStateStoring,
        DxHeaderFilter,
        DxRequiredRule,
        DxColumnChooser,
        DxColumnChooserSelection
        // subscriptionPlansPopupComponent
    },
    setup() {
        //composable
        const {
            admin,
            tax,
            vatId,
            supplier,
            vatAmount,
            priceList,
            domainUser,
            retailPrice,
            priceListItem,
            hiddenOptions,
            priceListData,
            wholesalePrice,
            itemTypeOptions,
            updateRetailPrice,
            columnChooserMode,
            updateWholesalePrice,
            getPriceListDataByKey,
            supplierSelectBoxOptions,
            handlePriceListExporting,
            priceCalculationTypeOptions,
            handleNewRowInitPriceListItem,
            handleRowUpdatingPriceListItem,
            handleEditingStartPriceListItem,
            handleRowInsertingPriceListItem,
            handleRowInsertedPriceListItem,
            handleRowUpdatedPriceListItem,
            getDomainData
        } = usePriceListItem();
        const lookupStore = useLookupStore();
        const lookupData = ref(null);
        onMounted(async () => {
            eventBus.on("newFocusedRowPriceList", handleNewFocusedRowPriceList);
            eventBus.on("newPriceListItemAdded", handleNewPriceListItem);
            eventBus.on("lookupLoading", handleLookupLoading);
            // console.log(lookupStore.isLoading);
            if (
                !lookupStore.isLoading &&
                Object.keys(lookupStore.lookupData).length > 0
            ) {
                lookupData.value = lookupStore.lookupData;
                lookupLoading.value = false;
            }
            await getDomainData();
        });
        onUnmounted(() => {
            eventBus.off(
                "newFocusedRowPriceList",
                handleNewFocusedRowPriceList
            );
            eventBus.off("newPriceListItemAdded", handleNewPriceListItem);
            eventBus.off("lookupLoading", handleLookupLoading);
        });
        // eventBus.on("newFocusedRowPriceList", async (id) => {
        //     if (id !== null) {
        //         await getPriceListDataByKey(id);
        //         focusedId.value = true;
        //         focusedRowId.value = id;
        //     } else {
        //         focusedId.value = false;
        //         focusedRowId.value = null;
        //     }
        //     tableInstance.value.refresh();
        // });
        // eventBus.on("newPriceListItemAdded", () => {
        //     tableInstance.value.refresh();
        // });
        //Data
        const shouldShowTheDataGrid = ref(true);
        const focusedId = ref(false);
        const focusedRowId = ref(null);
        const lookupLoading = ref(true);

        //Instances
        const tableInstance = ref(null);
        const vatAmountInstance = ref(null);
        const wholesalePriceInstance = ref(null);
        const retailPriceInstance = ref(null);

        //button options
        const addButtonOptions = ref({
            icon: "add",
            onClick: () => {
                addNewPriceListItem();
            }
        });
        const refreshButtonOptions = ref({
            icon: "refresh",
            onClick: () => {
                refreshData();
            }
        });
        const columnChooserButtonOptions = ref({
            icon: "columnchooser",
            onClick: () => {
                openColumnChooser();
            }
        });

        //form item options
        const vatIdOptions = {
            dataSource: tax.value,
            valueExpr: "id",
            displayExpr: "taxValue",
            placeholder: "Odaberi stopu poreza",
            onValueChanged: async (e) => {
                vatId.value = e.value;
                await updateWholesalePrice(
                    wholesalePrice.value,
                    wholesalePriceInstance.value,
                    retailPriceInstance.value,
                    vatAmountInstance.value
                );
            }
        };
        const vatAmountOptions = {
            value: vatAmount.value,
            format: "###,###,###,##0.00",
            readOnly: true,
            onInitialized: (e) => {
                vatAmountInstance.value = e.component;
            }
        };
        const wholesalePriceOptions = {
            value: wholesalePrice.value,
            format: "###,###,###,##0.00",
            // format: { style: 'currency'},
            onInitialized: (e) => {
                wholesalePriceInstance.value = e.component;
                wholesalePriceInstance.value.option(
                    "value",
                    wholesalePrice.value
                );
            },
            onValueChanged: async (e) =>
                await updateWholesalePrice(
                    e,
                    wholesalePriceInstance.value,
                    retailPriceInstance.value,
                    vatAmountInstance.value
                )
        };
        const retailPriceOptions = {
            value: retailPrice.value,
            format: "###,###,###,##0.00",
            onInitialized: (e) => {
                retailPriceInstance.value = e.component;
                retailPriceInstance.value.option("value", retailPrice.value);
            },
            onValueChanged: async (e) =>
                await updateRetailPrice(
                    e,
                    retailPriceInstance.value,
                    wholesalePriceInstance.value,
                    vatAmountInstance.value
                )
        };

        //instance
        const getPriceListItemTableInstance = (e) => {
            tableInstance.value = e.component;
            if (!focusedId.value) shouldShowTheDataGrid.value = false;
        };
        const addNewPriceListItem = async () => {
            const res = await checkSubscriptionLimit("priceListItem");
            if (res.limitReached) {
                eventBus.emit("openPlans");
            } else {
                tableInstance.value.addRow();
            }
        };
        const editPriceListItem = (e) => {
            tableInstance.value.editRow(e.row.rowIndex);
        };
        const cancelPriceListItem = () => {
            tableInstance.value.cancelEditData();
        };
        const deletePriceListItem = (e) => {
            tableInstance.value.deleteRow(e.row.rowIndex);
        };
        const onInitNewRowPriceListItem = (e) => {
            handleNewRowInitPriceListItem(e, focusedRowId.value);
        };
        const savePriceListItem = () => {
            tableInstance.value.saveEditData();
            eventBus.emit("newFocusedRowPriceList", focusedRowId.value);
        };
        const onRowInsertingPriceListItem = (e) => {
            handleRowInsertingPriceListItem(e);
        };
        const onRowInsertedPriceListItem = async (e) => {
            eventBus.emit("newFocusedRowPriceList", focusedRowId.value);
            eventBus.emit("checkLimits");
            lookupLoading.value = true;
            await handleRowInsertedPriceListItem(e);
            lookupLoading.value = false;
        };
        const onEditingStartPriceListItem = (e) => {
            handleEditingStartPriceListItem(e, tableInstance.value);
        };
        const onRowUpdatingPriceListItem = (e) => {
            handleRowUpdatingPriceListItem(e);
        };
        const onRowUpdatedPriceListItem = async (e) => {
            eventBus.emit("newFocusedRowPriceList", focusedRowId.value);
            await handleRowUpdatedPriceListItem(e);
        };
        const onRowRemovedPriceListItem = async () => {
            eventBus.emit("newFocusedRowPriceList", focusedRowId.value);
        };
        const onExporting = (e) => {
            handlePriceListExporting(e);
        };

        //button methods
        const refreshData = async () => {
            eventBus.emit("newFocusedRowPriceList", focusedRowId.value);
            const message = "Podaci osvježeni!";
            // tableInstance.value.refresh();
            notify({ message, width: 450 }, "success");
            await lookupStore.fetchLookups(admin.value.domainId);
        };
        const exportToPdf = () => {
            tableInstance.value.exportPDF();
        };
        const openColumnChooser = () => {
            tableInstance.value.showColumnChooser();
        };
        watch(
            () => focusedId.value,
            () => {
                shouldShowTheDataGrid.value = focusedId.value ? true : false;
            }
        );
        watch(
            () => lookupStore.lookupData,
            () => {
                shouldShowTheDataGrid.value =
                    Object.keys(lookupStore.lookupData).length > 0
                        ? true
                        : false;
                lookupData.value = lookupStore.lookupData;
            }
        );
        const supplierName = (item) => {
            return `${item.companyName}`;
        };
        const areEditAndDeleteIconsVisible = (e) => {
            return !e.row.isEditing ? true : false;
        };
        const areSaveAndCancelIconsVisible = (e) => {
            return e.row.isEditing ? true : false;
        };
        const isNumeric = (str) => {
            return /^[0-9]+$/.test(str);
        };
        const formatedNumber = (e) => {
            const number = isNumeric(e.code);
            if (number) {
                return Number(e.code);
            } else {
                return e.code;
            }
        };
        const handleNewFocusedRowPriceList = async (id) => {
            if (id !== null) {
                await getPriceListDataByKey(id);
                focusedId.value = true;
                focusedRowId.value = id;
            } else {
                focusedId.value = false;
                focusedRowId.value = null;
            }
            tableInstance.value.refresh();
        };
        const handleNewPriceListItem = () => {
            tableInstance.value.refresh();
        };
        const handleLookupLoading = (e) => {
            lookupData.value = lookupStore.getLookupData;
            lookupLoading.value = e;
        };
        const setRowIndex = (rowData) => {
            if (!tableInstance.value) return "";
            const currentPageIndex = tableInstance.value.pageIndex();
            const pageSize = tableInstance.value.pageSize();

            // Find row index in current page (works for UI & export)
            const data = tableInstance.value.getDataSource().items();
            const rowIndexInPage = data.findIndex(
                (item) => item.id === rowData.id
            );

            return currentPageIndex * pageSize + (rowIndexInPage + 1);
        };

        return {
            formatedNumber,
            setRowIndex,
            lookupLoading,
            lookupData,
            tax,
            supplier,
            priceList,
            vatAmount,
            domainUser,
            exportToPdf,
            onExporting,
            refreshData,
            retailPrice,
            supplierName,
            priceListItem,
            hiddenOptions,
            priceListData,
            wholesalePrice,
            itemTypeOptions,
            vatIdOptions,
            vatAmountOptions,
            addButtonOptions,
            openColumnChooser,
            columnChooserMode,
            editPriceListItem,
            savePriceListItem,
            retailPriceOptions,
            cancelPriceListItem,
            addNewPriceListItem,
            deletePriceListItem,
            refreshButtonOptions,
            shouldShowTheDataGrid,
            wholesalePriceOptions,
            supplierSelectBoxOptions,
            onInitNewRowPriceListItem,
            onRowUpdatedPriceListItem,
            onRowRemovedPriceListItem,
            columnChooserButtonOptions,
            onRowInsertedPriceListItem,
            onRowUpdatingPriceListItem,
            onRowInsertingPriceListItem,
            priceCalculationTypeOptions,
            onEditingStartPriceListItem,
            areEditAndDeleteIconsVisible,
            areSaveAndCancelIconsVisible,
            getPriceListItemTableInstance
        };
    }
};
</script>
<style scoped>
.table-container {
    margin: 2vh;
    max-width: 100%;
}
#priceListItemTable {
    max-width: 100%;
}
</style>
