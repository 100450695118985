import CustomStore from "devextreme/data/custom_store";
import { sendRequest } from "@/helpers/sendRequestHelper";
import config from "../../config.json";
import eventBus from "../../eventBus";

let filterId = null;
let userDomainId;
let isDispatchNoteOrReceipt = false;
let isSubscribed = false;
const subscribeToEvent = () => {
    if (!isSubscribed) {
        eventBus.on("newFocusedRowPriceList", (id) => {
            filterId = id;
        });
        eventBus.on("newUserLoggedIn", (data) => {
            userDomainId = data.userDomainId;
        });
        eventBus.on("priceListItemForDispatchNoteOrReceipt", (data) => {
            isDispatchNoteOrReceipt = data;
        });
        isSubscribed = true;
    }
};
subscribeToEvent();
export const priceListItem = new CustomStore({
    key: "id",
    load: async (loadOptions) => {
        // console.log(filterId);
        // console.log(isDispatchNoteOrReceipt);
        try {
            const filterCondition = {
                where: {
                    and: [{ domainId: userDomainId }]
                }
            };
            if (loadOptions.skip || loadOptions.take) {
                filterCondition.limit = loadOptions.take + 1;
                filterCondition.skip = loadOptions.skip;
                if (isDispatchNoteOrReceipt) {
                    filterCondition.where.and.push({ itemType: "Roba" });
                }
            }
            if (
                filterId === null ||
                loadOptions.searchValue === null ||
                loadOptions.searchValue === ""
            ) {
                if (filterId !== null) {
                    filterCondition.where.and.push({ priceListId: filterId });
                }
                // else {
                //     filterCondition.where.and.splice(1, 1);
                // }
                else if (
                    loadOptions.searchValue !== null &&
                    loadOptions.searchValue !== ""
                ) {
                    filterCondition.where.and.push({
                        or: [
                            {
                                name: {
                                    regexp: `/${loadOptions.searchValue}/i`
                                }
                            },
                            {
                                code: {
                                    regexp: `/${loadOptions.searchValue}/i`
                                }
                            }
                        ]
                    });
                } else {
                    filterCondition.where.and.splice(1, 1);
                }
                const newData = await sendRequest(
                    `${config.API_URL}/${
                        config.PATHS.PRICE_LIST_ITEM
                    }?filter=${JSON.stringify(filterCondition)}`
                );
                filterId = null;
                return newData;
            } else {
                const newData = await sendRequest(
                    `${config.API_URL}/${
                        config.PATHS.PRICE_LIST_ITEM
                    }filter=${JSON.stringify(filterCondition)}`
                );
                filterId = null;
                return newData;
            }
        } catch (error) {
            console.error("Error loading data:", error);
            throw error;
        }
    },
    totalCount: async (key) => {
        try {
            const filterCondition = {
                domainId: key
            };
            const res = await sendRequest(
                `${config.API_URL}/${
                    config.PATHS.PRICE_LIST_ITEM
                }/count?where=${JSON.stringify(filterCondition)}`
            );
            return res.count;
        } catch (error) {
            console.error("Error loading data:", error);
            throw error;
        }
    },
    byKey: async (key) => {
        try {
            const res = await sendRequest(
                `${config.API_URL}/${config.PATHS.PRICE_LIST_ITEM}/${key}`
            );
            return res;
        } catch (error) {
            console.error("Error loading data:", error);
            throw error;
        }
    },
    update: async (id, values) => {
        try {
            await sendRequest(
                `${config.API_URL}/${config.PATHS.PRICE_LIST_ITEM}/${id}`,
                "PATCH",
                {
                    values: values
                }
            );
        } catch (error) {
            console.error("Error updating data:", error);
            throw error;
        }
    },
    insert: async (values) => {
        try {
            const res = await sendRequest(
                `${config.API_URL}/${config.PATHS.PRICE_LIST_ITEM}`,
                "POST",
                {
                    values: values
                }
            );
            return res;
        } catch (error) {
            console.error("Error inserting data:", error);
            throw error;
        }
    },
    remove: async (id) => {
        try {
            await sendRequest(
                `${config.API_URL}/${config.PATHS.PRICE_LIST_ITEM}/${id}`,
                "DELETE"
            );
        } catch (error) {
            console.error("Error removing data:", error);
            throw error;
        }
    }
});
