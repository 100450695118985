<template>
    <div style="margin: 3vh auto; max-width: 500px; text-align: center">
        <img
            class="logo-img"
            src="@/assets/logo-minierp.svg"
            width="90"
            height="90"
        />
    </div>
    <div style="max-width: 50vw; margin: 3vh auto; text-align: center">
        <h2 v-if="errorMessage">
            {{ errorMessage }}
        </h2>
        <h2 v-if="successMessage">{{ successMessage }}</h2>
        <h2 v-if="cancelMessage">{{ cancelMessage }}</h2>
        <p>Bit ćete preusmjereni u aplikaciju za... {{ sec }}</p>
    </div>
</template>

<script>
import { useDomainUserStore } from "@/stores/customDomainUserStore";
import { generateSHA512Return } from "@/utils/hash.js";
import { domain } from "@/stores/domainStore";
import { sendRequest } from "@/helpers/sendRequestHelper";
import config from "../../config.json";
export default {
    name: "paymentStatus",
    props: [
        "CustomerFirstname",
        "CustomerSurname",
        "CancelCode",
        "ErrorMsg",
        "Success",
        "ApprovalCode",
        "Signature",
        "WsPayOrderId",
        "DateTime",
        "ShoppingCartID"
        // "Token",
        // "TokenNumber",
        // "TokenExp"
    ],
    data() {
        return {
            domain: domain,
            admin: null,
            errorMessage: "",
            successMessage: "",
            cancelMessage: "",
            sec: 5
        };
    },

    created() {
        const domainUserStore = useDomainUserStore();
        this.admin = domainUserStore.getDomainUser;
        // console.log(this.Token, this.TokenNumber, this.TokenExp);
        if (
            Number(this.Success) === 1 &&
            this.ApprovalCode &&
            this.Signature &&
            this.WsPayOrderId &&
            this.DateTime &&
            this.ShoppingCartID
        ) {
            this.handleSuccess();
        } else if (
            Number(this.Success) === 0 &&
            this.ApprovalCode === "" &&
            this.ErrorMsg
        ) {
            this.handleError(this.ErrorMsg);
        } else if (this.CancelCode) {
            this.handleCancel(this.CancelCode);
        }
    },

    methods: {
        handleCancel(cancel) {
            if (Number(cancel) === 15) {
                let countdown = 5;
                this.countdownInterval = setInterval(() => {
                    if (countdown >= 0) {
                        this.sec = countdown;
                        countdown--;
                    } else {
                        clearInterval(this.countdownInterval);
                        this.$router.push("/home");
                    }
                }, 1000);
                this.cancelMessage = "Plaćanje otkazano.";
            } else {
                let countdown = 5;
                this.countdownInterval = setInterval(() => {
                    if (countdown >= 0) {
                        this.sec = countdown;
                        countdown--;
                    } else {
                        clearInterval(this.countdownInterval);
                        this.$router.push("/home");
                    }
                }, 1000);
                this.cancelMessage =
                    "Prekoračeno je vremensko ograničenje zahtjeva. Pokušajte ponovno kasnije.";
            }
            localStorage.removeItem("ws_signature");
        },
        handleError(error) {
            let countdown = 5;
            this.countdownInterval = setInterval(() => {
                if (countdown >= 0) {
                    this.sec = countdown;
                    countdown--;
                } else {
                    clearInterval(this.countdownInterval);
                    this.$router.push("/home");
                }
            }, 1000);
            this.errorMessage = error;
        },

        async handleSuccess() {
            let countdown = 5;
            this.countdownInterval = setInterval(() => {
                if (countdown >= 0) {
                    this.sec = countdown;
                    countdown--;
                } else {
                    clearInterval(this.countdownInterval);
                    this.$router.push("/home");
                }
            }, 1000);
            const returnSignature = await generateSHA512Return(
                "GTNET",
                this.ShoppingCartID,
                Number(this.Success),
                Number(this.ApprovalCode)
            );
            if (this.Signature === returnSignature) {
                this.successMessage =
                    "Plaćanje uspješno. Uskoro ćete na e-mail primiti račun.";
                const planId = this.ShoppingCartID.split("-")[0];
                const dateTimeString = this.DateTime;
                const year = parseInt(dateTimeString.substring(0, 4), 10);
                const month = parseInt(dateTimeString.substring(4, 6), 10) - 1;
                const day = parseInt(dateTimeString.substring(6, 8), 10);
                const startDate = new Date(year, month, day);
                let endDate = new Date(startDate);

                const isMonthly = planId === "2";
                const isAnnual = planId === "4";

                if (isMonthly) {
                    endDate.setMonth(endDate.getMonth() + 1);
                } else if (isAnnual) {
                    endDate.setFullYear(endDate.getFullYear() + 1);
                }

                try {
                    const domainData = await this.domain.byKey(
                        this.admin.domainId
                    );
                    const now = new Date();
                    if (domainData && domainData.subscriptionEnd !== null) {
                        endDate = new Date(domainData.subscriptionEnd);
                        endDate = new Date(Math.max(endDate, now));
                        if (isMonthly) {
                            endDate.setMonth(endDate.getMonth() + 1);
                        } else if (isAnnual) {
                            endDate.setFullYear(endDate.getFullYear() + 1);
                        }

                        await this.domain.update(this.admin.domainId, {
                            subscriptionId: Number(planId),
                            wsPayOrderId: this.WsPayOrderId,
                            subscriptionEnd: endDate.toISOString()
                        });
                    } else {
                        await this.domain.update(this.admin.domainId, {
                            subscriptionId: Number(planId),
                            wsPayOrderId: this.WsPayOrderId,
                            subscriptionBegin: startDate.toISOString(),
                            subscriptionEnd: endDate.toISOString()
                        });
                    }
                    const invoiceData = {
                        name: this.CustomerFirstname,
                        lastname: this.CustomerSurname,
                        domainId: this.admin.domainId,
                        subscriptionId: Number(planId),
                        userId: this.admin.id
                    };
                    await sendRequest(
                        `${config.API_URL}/${config.PATHS.SUBSCRIPTION_INVOICE_MINIERP}`,
                        "POST",
                        {
                            values: invoiceData
                        }
                    );
                } catch (e) {
                    console.error(e);
                    this.successMessage =
                        "Došlo je do pogreške prilikom ažuriranja pretplate. Kontaktirajte administratora.";
                }
            } else {
                this.successMessage =
                    "Došlo je do pogreške prilikom plaćanja. Kontaktirajte administratora.";
            }
        }
    }
};
</script>
