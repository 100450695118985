import CustomStore from "devextreme/data/custom_store";
import { sendRequest } from "@/helpers/sendRequestHelper";
import config from "../../config.json";
import eventBus from "../../eventBus";

// EventBus
let searchValue = null;
let isSubscribed = false; // Prevent duplicate subscriptions
const subscribeToEvent = () => {
    if (!isSubscribed) {
        eventBus.on("searchValue", (data) => {
            searchValue = data;
        });
        isSubscribed = true;
    }
};
subscribeToEvent();
export const sudregApi = new CustomStore({
    load: async () => {
        try {
            const filterCondition = {};
            let res;
            if (searchValue !== null && searchValue !== "") {
                filterCondition.where = {
                    or: [
                        {
                            oib: {
                                regexp: `/${searchValue}/i`
                            }
                        },
                        {
                            mbs: {
                                regexp: `/${searchValue}/i`
                            }
                        },
                        {
                            ime: {
                                regexp: `/${searchValue}/i`
                            }
                        }
                    ]
                };
                res = await sendRequest(
                    `${config.API_URL}/${
                        config.PATHS.V_TVRTKA_SUBJEKT
                    }?filter=${JSON.stringify(filterCondition)}`
                );
            } else {
                if (filterCondition.where) {
                    delete filterCondition.where;
                }
                res = [];
            }
            return res;
        } catch (error) {
            console.error("Error loading data:", error);
            throw error;
        }
    }
});
