<template>
    <!-- <div class="table-container"> -->
    <DxDataGrid
        v-if="shouldShowTheDataGrid && !lookupLoading"
        id="invoiceItemTable"
        :data-source="invoiceItem"
        :repaint-changes-only="true"
        :allow-column-resizing="true"
        :show-column-lines="true"
        :show-row-lines="true"
        :show-borders="true"
        :column-auto-width="true"
        :show-toolbar="false"
        @initialized="getInvoiceItemTableInstance"
    >
        <DxStateStoring
            :enabled="true"
            type="localStorage"
            storage-key="invoiceItemTableState"
        />
        <DxToolbar>
            <DxItem
                :options="refreshButtonOptions"
                location="after"
                locate-in-menu="auto"
                widget="dxButton"
            />
            <DxItem name="searchPanel" location="after" locate-in-menu="auto" />
        </DxToolbar>
        <DxSearchPanel :visible="true" />
        <DxScrolling column-rendering-mode="virtual" />
        <DxPager
            :visible="true"
            display-mode="adaptive"
            :show-info="true"
            info-text="Stranica {0} od {1} ({2} stavki)"
            :allowed-page-sizes="[10, 20, 30]"
            :show-navigation-buttons="true"
            :show-page-size-selector="true"
        />
        <DxPaging :page-size="10" />
        <DxColumnFixing :enabled="true" />
        <DxColumn
            caption="Rb."
            :width="50"
            alignment="center"
            :calculate-cell-value="setRowIndex"
            :allow-hiding="false"
            :show-in-column-chooser="false"
        />
        <DxColumn data-field="id" data-type="number" :visible="false" />
        <DxColumn
            :allow-exporting="false"
            data-field="priceListItemId"
            data-type="number"
            caption="Šifra"
            name="codeColumn"
        >
            <DxLookup
                :items="lookupData.priceListItem"
                value-expr="id"
                display-expr="code"
            />
        </DxColumn>
        <DxColumn
            data-field="priceListItemId"
            data-type="number"
            caption="Naziv"
            name="nameColumn"
            :width="200"
        >
            <DxLookup
                :items="lookupData.priceListItem"
                value-expr="id"
                display-expr="name"
            />
        </DxColumn>
        <DxColumn
            data-field="priceListItemId"
            data-type="number"
            caption="JMJ"
            name="measureUnitColumn"
        >
            <DxLookup
                :items="lookupData.priceListItem"
                value-expr="id"
                display-expr="measureUnit"
            />
        </DxColumn>
        <DxColumn
            width="120"
            data-field="qty"
            data-type="number"
            caption="Kol."
            format="###,###,###,##0.00"
        />
        <DxColumn
            data-field="price"
            data-type="number"
            caption="Cijena"
            :format="priceTotalFormat"
        />
        <!-- <DxLookup
                :items="lookupData.invoiceItem"
                value-expr="id"
                display-expr="price"
            />
        </DxColumn> -->
        <DxColumn
            data-field="priceByQty"
            data-type="number"
            caption="Iznos"
            :format="priceTotalFormat"
        />
        <!-- <DxLookup
                :items="lookupData.invoiceItem"
                value-expr="id"
                display-expr="priceByQty"
            />
        </DxColumn> -->
        <DxColumn
            data-field="discountAmount"
            data-type="number"
            caption="Rabat"
            :format="priceTotalFormat"
        />
        <!-- <DxLookup
                :items="lookupData.invoiceItem"
                value-expr="id"
                display-expr="discountAmount"
            />
        </DxColumn> -->
        <DxColumn
            data-field="discountPercentage"
            data-type="number"
            caption="Rab%"
            :format="priceTotalFormat"
        />
        <!-- <DxLookup
                :items="lookupData.invoiceItem"
                value-expr="id"
                display-expr="discountPercentage"
            />
        </DxColumn> -->
        <DxColumn
            data-field="priceTotal"
            data-type="number"
            caption="Konačan iznos"
            :format="priceTotalFormat"
        />
        <!-- <DxLookup
                :items="lookupData.invoiceItem"
                value-expr="id"
                display-expr="priceTotal"
            />
        </DxColumn> -->
        <!-- <DxSummary :allow-exporting="false">
            <DxTotalItem
                column="priceTotal"
                summary-type="sum"
                :value-format="priceTotalFormat"
                display-format="UKUPNO: {0}"
            />
        </DxSummary> -->
    </DxDataGrid>
    <!-- </div> -->
</template>
<script>
//DevExpress
import {
    DxDataGrid,
    DxColumn,
    DxColumnFixing,
    DxScrolling,
    DxLookup,
    DxStateStoring,
    DxPager,
    DxSearchPanel,
    DxToolbar,
    DxItem,
    DxPaging
    // DxSummary,
    // DxTotalItem
} from "devextreme-vue/data-grid";
import notify from "devextreme/ui/notify";
// import { exportDataGrid as exportPDF } from "devextreme/pdf_exporter";
import { useInvoiceItem } from "@/composables/useInvoiceItem.js";
import eventBus from "../../eventBus.js";
import { ref, watch, onMounted, onUnmounted } from "vue";
import { useLookupStore } from "@/stores/customLookupStore.js";
// import bwipjs from "bwip-js";

export default {
    name: "invoiceItemTableComponent",
    components: {
        DxPager,
        DxColumn,
        DxLookup,
        DxDataGrid,
        DxScrolling,
        DxColumnFixing,
        DxStateStoring,
        DxSearchPanel,
        DxToolbar,
        DxItem,
        DxPaging
        // DxSummary,
        // DxTotalItem
    },
    setup() {
        const {
            admin,
            customer,
            invoice,
            domain,
            invoiceItem,
            businessYear,
            priceListItem,
            priceTotalFormat,
            getDomainData,
            getInvoiceByKey,
            getUserByKey,
            getBusinessYearByKey,
            handleInvoiceExporting
        } = useInvoiceItem();
        const lookupStore = useLookupStore();
        const lookupData = ref(null);
        const lookupLoading = ref(true);
        const tableInstance = ref(null);
        const focusedId = ref(false);
        const shouldShowTheDataGrid = ref(true);
        const refreshButtonOptions = ref({
            icon: "refresh",
            onClick: () => {
                refreshData();
            }
        });
        onMounted(async () => {
            eventBus.on("newFocusedRowInvoice", handleNewFocusedRowInvoice);
            eventBus.on("exportInvoiceItemToPDF", handleExportInvoice);
            eventBus.on(
                "exportInvoiceItemToPDFForEInvoice",
                handleExportEInvoice
            );
            eventBus.on("newInvoiceItemAdded", handleNewInvoiceItemAdded);
            eventBus.on("lookupLoading", handleLookupLoading);
            // console.log(lookupStore.isLoading);
            if (
                !lookupStore.isLoading &&
                Object.keys(lookupStore.lookupData).length > 0
            ) {
                lookupData.value = lookupStore.lookupData;
                lookupLoading.value = false;
            }
            await getDomainData();
        });
        onUnmounted(() => {
            eventBus.off("newFocusedRowInvoice", handleNewFocusedRowInvoice);
            eventBus.off("exportInvoiceItemToPDF", handleExportInvoice);
            eventBus.off(
                "exportInvoiceItemToPDFForEInvoice",
                handleExportEInvoice
            );
            eventBus.off("newInvoiceItemAdded", handleNewInvoiceItemAdded);
            eventBus.off("lookupLoading", handleLookupLoading);
        });
        // eventBus.on("newInvoiceItemAdded", async (key) => {
        //     await getInvoiceByKey(key);
        //     await getUserByKey();
        //     await getBusinessYearByKey();
        //     tableInstance.value.refresh();
        // });
        // eventBus.on("newFocusedRowInvoice", async (data) => {
        //     if (data !== null) {
        //         focusedId.value = true;
        //         await getInvoiceByKey(data);
        //         await getUserByKey();
        //         await getBusinessYearByKey();
        //     } else {
        //         focusedId.value = false;
        //     }
        //     tableInstance.value.refresh();
        // });
        // eventBus.on("exportInvoiceItemToPDF", (sendEmailFlag) => {
        //     handleInvoiceExporting(tableInstance.value, sendEmailFlag, false);
        // });
        // eventBus.on("exportInvoiceItemToPDFForEInvoice", (eInvoiceFlag) => {
        //     handleInvoiceExporting(tableInstance.value, false, eInvoiceFlag);
        // });

        const getInvoiceItemTableInstance = (e) => {
            tableInstance.value = e.component;
            if (!focusedId.value) shouldShowTheDataGrid.value = false;
        };
        const setRowIndex = (rowData) => {
            if (!tableInstance.value) return "";
            const currentPageIndex = tableInstance.value.pageIndex();
            const pageSize = tableInstance.value.pageSize();

            // Find row index in current page (works for UI & export)
            const data = tableInstance.value.getDataSource().items();
            const rowIndexInPage = data.findIndex(
                (item) => item.id === rowData.id
            );

            return currentPageIndex * pageSize + (rowIndexInPage + 1);
        };
        const refreshData = async () => {
            const message = "Podaci osvježeni!";
            tableInstance.value.refresh();
            notify({ message, width: 450 }, "success");
            await lookupStore.fetchLookups(admin.value.domainId);
        };
        const handleLookupLoading = (e) => {
            lookupData.value = lookupStore.getLookupData;
            lookupLoading.value = e;
        };
        const handleNewFocusedRowInvoice = async (data) => {
            if (data !== null) {
                focusedId.value = true;
                await getInvoiceByKey(data);
                await getUserByKey();
                await getBusinessYearByKey();
            } else {
                focusedId.value = false;
            }
            tableInstance.value.refresh();
        };
        const handleExportInvoice = (sendEmailFlag) => {
            handleInvoiceExporting(tableInstance.value, sendEmailFlag, false);
        };
        const handleExportEInvoice = (eInvoiceFlag) => {
            handleInvoiceExporting(tableInstance.value, false, eInvoiceFlag);
        };
        const handleNewInvoiceItemAdded = async (key) => {
            await getInvoiceByKey(key);
            await getUserByKey();
            await getBusinessYearByKey();
            tableInstance.value.refresh();
        };
        watch(
            () => focusedId.value,
            () => {
                shouldShowTheDataGrid.value = focusedId.value ? true : false;
            }
        );
        watch(
            () => lookupStore.lookupData,
            () => {
                shouldShowTheDataGrid.value =
                    Object.keys(lookupStore.lookupData).length > 0
                        ? true
                        : false;
                lookupData.value = lookupStore.lookupData;
            }
        );

        return {
            refreshButtonOptions,
            setRowIndex,
            customer,
            invoice,
            domain,
            invoiceItem,
            businessYear,
            priceListItem,
            priceTotalFormat,
            getInvoiceItemTableInstance,
            shouldShowTheDataGrid,
            lookupLoading,
            lookupData
        };
    }
};
</script>
<style scoped>
.table-container {
    margin: 2vh;
    max-width: 100%;
}
#invoiceItemTable {
    width: 100%;
}
</style>
