import CustomStore from "devextreme/data/custom_store";
import { sendRequest } from "@/helpers/sendRequestHelper";
import config from "../../config.json";
import eventBus from "../../eventBus";

let userDomainId;
let isSubscribed = false; // Prevent duplicate subscriptions
const subscribeToEvent = () => {
    if (!isSubscribed) {
        eventBus.on("newUserLoggedIn", (data) => {
            userDomainId = data.userDomainId;
        });
        isSubscribed = true;
    }
};
subscribeToEvent();
export const domainUser = new CustomStore({
    key: "id",
    load: async (loadOptions) => {
        try {
            let filterCondition;
            if (userDomainId) {
                filterCondition = {
                    where: {
                        and: [
                            {
                                domainId: userDomainId
                            }
                        ]
                    }
                };
            }
            if (loadOptions.roleId) {
                filterCondition.where.and.push({
                    roleId: loadOptions.roleId
                });
            } else {
                filterCondition.where.and.splice(1, 1);
            }
            const res = await sendRequest(
                `${config.API_URL}/${
                    config.PATHS.DOMAIN_USER
                }?filter=${JSON.stringify(filterCondition)}`
            );
            // console.log(res);
            return res;
        } catch (error) {
            console.error("Error loading data:", error);
            throw error;
        }
    },
    totalCount: async (key) => {
        try {
            const filterCondition = {
                domainId: key
            };
            const res = await sendRequest(
                `${config.API_URL}/${
                    config.PATHS.DOMAIN_USER
                }/count?where=${JSON.stringify(filterCondition)}`
            );
            return res.count;
        } catch (error) {
            console.error("Error loading data:", error);
            throw error;
        }
    },
    byKey: async (key) => {
        try {
            const res = await sendRequest(
                `${config.API_URL}/${config.PATHS.DOMAIN_USER}/${key}`
            );
            return res;
        } catch (error) {
            console.error("Error loading data:", error);
            throw error;
        }
    },
    insert: async (values) => {
        try {
            await sendRequest(
                `${config.API_URL}/${config.PATHS.DOMAIN_USER}`,
                "POST",
                {
                    values: values
                }
            );
        } catch (error) {
            console.error("Error inserting data:", error);
            throw error;
        }
    },
    update: async (id, values) => {
        try {
            await sendRequest(
                `${config.API_URL}/${config.PATHS.DOMAIN_USER}/${id}`,
                "PATCH",
                {
                    values: values
                }
            );
        } catch (error) {
            console.error("Error updating data:", error);
            throw error;
        }
    },
    remove: async (id) => {
        try {
            await sendRequest(
                `${config.API_URL}/${config.PATHS.DOMAIN_USER}/${id}`,
                "DELETE"
            );
        } catch (error) {
            console.error("Error removing data:", error);
            throw error;
        }
    }
});
