import config from "../../config.json";
export async function generateSHA512Request(
    shopID,
    shoppingCartID,
    totalAmount
) {
    const secretKey = config.WSPAY_CODE;
    const data =
        shopID +
        secretKey +
        shoppingCartID +
        secretKey +
        totalAmount +
        secretKey;

    const encoder = new TextEncoder();
    const dataBuffer = encoder.encode(data);
    const hashBuffer = await crypto.subtle.digest("SHA-512", dataBuffer);
    return Array.from(new Uint8Array(hashBuffer))
        .map((byte) => byte.toString(16).padStart(2, "0"))
        .join("");
}

export async function generateSHA512Return(
    shopID,
    shoppingCartID,
    success,
    approvalCode
) {
    const secretKey = config.WSPAY_CODE;
    const data =
        shopID +
        secretKey +
        shoppingCartID +
        secretKey +
        success +
        secretKey +
        approvalCode +
        secretKey;

    const encoder = new TextEncoder();
    const dataBuffer = encoder.encode(data);
    const hashBuffer = await crypto.subtle.digest("SHA-512", dataBuffer);
    return Array.from(new Uint8Array(hashBuffer))
        .map((byte) => byte.toString(16).padStart(2, "0"))
        .join("");
}
