import CustomStore from "devextreme/data/custom_store";
import { sendRequest } from "@/helpers/sendRequestHelper";
import config from "../../config.json";
import eventBus from "../../eventBus";

let userDomainId;
let isSubscribed = false; // Prevent duplicate subscriptions
const subscribeToEvent = () => {
    if (!isSubscribed) {
        eventBus.on("newUserLoggedIn", (data) => {
            userDomainId = data.userDomainId;
        });
        isSubscribed = true;
    }
};
subscribeToEvent();

export const priceList = new CustomStore({
    key: "id",
    load: async () => {
        try {
            if (userDomainId) {
                let filterCondition = {
                    where: {
                        domainId: userDomainId
                    }
                };
                const newData = await sendRequest(
                    `${config.API_URL}/${
                        config.PATHS.PRICE_LIST
                    }?filter=${JSON.stringify(filterCondition)}`
                );
                return newData;
            }
        } catch (error) {
            console.error("Error loading data:", error);
            throw error;
        }
    },
    totalCount: async (key) => {
        try {
            const filterCondition = {
                domainId: key
            };
            const res = await sendRequest(
                `${config.API_URL}/${
                    config.PATHS.PRICE_LIST
                }/count?where=${JSON.stringify(filterCondition)}`
            );
            return res.count;
        } catch (error) {
            console.error("Error loading data:", error);
            throw error;
        }
    },
    insert: async (values) => {
        try {
            const response = await sendRequest(
                `${config.API_URL}/${config.PATHS.PRICE_LIST}`,
                "POST",
                {
                    values: values
                }
            );
            return response;
        } catch (error) {
            console.error("Error inserting data:", error);
            throw error;
        }
    },
    byKey: async (key) => {
        try {
            const res = await sendRequest(
                `${config.API_URL}/${config.PATHS.PRICE_LIST}/${key}`
            );
            return res;
        } catch (error) {
            console.error("Error loading data:", error);
            throw error;
        }
    },
    update: async (id, values) => {
        try {
            await sendRequest(
                `${config.API_URL}/${config.PATHS.PRICE_LIST}/${id}`,
                "PATCH",
                {
                    values: values
                }
            );
        } catch (error) {
            console.error("Error updating data:", error);
            throw error;
        }
    },
    remove: async (id) => {
        try {
            await sendRequest(
                `${config.API_URL}/${config.PATHS.PRICE_LIST}/${id}`,
                "DELETE"
            );
        } catch (error) {
            console.error("Error removing data:", error);
            throw error;
        }
    }
});
