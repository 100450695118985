import { reactive, toRefs } from "vue";
//Stores
import { offer } from "@/stores/offerStore.js";
import { offerItem } from "@/stores/offerItemStore.js";
import { invoiceItem } from "@/stores/invoiceItemStore.js";
import { invoice } from "@/stores/invoiceStore.js";
import { user } from "@/stores/userStore.js";
import { customer } from "@/stores/customerStore.js";
import { domain } from "@/stores/domainStore";
import { domainUser } from "@/stores/domainUserStore.js";
import { warehouse } from "@/stores/warehouseStore";
import { businessYear } from "@/stores/businessYearStore";
import { priceList } from "@/stores/priceListStore.js";
import { priceListItem } from "@/stores/priceListItemStore";
import { warehouseItemStatus } from "@/stores/warehouseItemStatusStore.js";
import { emailSignatureDefault } from "@/stores/emailSignatureDefaultStore.js";
import { warehouseItemStatusPriceAverage } from "@/stores/warehouseItemStatusPriceAverageStore.js";
import { useEmailStore } from "@/stores/customEmailStore.js";
import moment from "moment";
import eventBus from "../../eventBus.js";
import { generateInvoiceData } from "@/utils/generateInvoiceData.js";
import { jsPDF } from "jspdf";
import { poppins } from "@/poppinsbase64";
import { poppinsBold } from "@/poppinsboldbase64.js";
import { exportDataGrid as exportPDF } from "devextreme/pdf_exporter";
import { getCurrentDateTime } from "@/utils/getCurrentDateTime";
import { divWrapper } from "@/utils/divWrapper";
import { useDomainUserStore } from "@/stores/customDomainUserStore";
import { tax } from "@/stores/taxStore.js";
import { changesLog } from "@/stores/changesLogStore";
import { checkWatermark } from "@/utils/checkSubscriptionLimits.js";
import { useLookupStore } from "@/stores/customLookupStore";

export function useOffer() {
    const domainUserStore = useDomainUserStore();
    const emailStore = useEmailStore();
    const lookupStore = useLookupStore();
    const state = reactive({
        offer,
        offerItem,
        invoiceItem,
        invoice,
        user,
        customer,
        domain,
        domainUser,
        warehouse,
        priceList,
        businessYear,
        priceListItem,
        warehouseItemStatus,
        emailSignatureDefault,
        warehouseItemStatusPriceAverage,
        taxId: null,
        taxValue: null,
        taxName: null,
        documentHeader: null,
        documentFooter: null,
        domainName: null,
        invoiceItemData: null,
        editingOffer: null,
        editingOfferItem: null,
        signature: null,
        offerItemData: [],
        rowsToBeDeleted: [],
        offerItemsBeforeEdit: [],
        snapshotOfferItems: [],
        editingOfferItemOldData: [],
        columnChooserMode: "select",
        userSignature: null,
        pdfForEmail: null,
        emailFormData: {
            sourceType: "offer",
            sourceId: null,
            senderId: null,
            dateSent: null,
            clientId: null,
            emailFrom: null,
            emailTo: null,
            subject: null,
            message: null,
            domainId: domainUserStore.user.domainId
        },
        priceTotalFormat: {
            type: "fixedPoint",
            precision: 2
        },
        admin: domainUserStore.getDomainUser,
        domainPermissions: domainUserStore.domainPermissions,
        businessYearId: localStorage.getItem("businessYearId") || 15
    });
    async function getDomainData() {
        const domainData = await domain.byKey(state.admin.domainId);
        state.domainName = domainData.name;
        if (
            domainData.documentSections &&
            domainData.documentSections !== null
        ) {
            const sections = JSON.parse(domainData.documentSections);
            state.documentHeader = sections.header
                ? sections.header
                : "Ovdje postavite svoje zaglavlje za dokumente.";
            state.documentFooter = sections.footer
                ? sections.footer
                : "Ovdje postavite svoje podnožje za dokumente.";
        } else {
            state.documentHeader =
                "Ovdje postavite svoje zaglavlje za dokumente.";
            state.documentFooter =
                "Ovdje postavite svoje podnožje za dokumente.";
        }
        state.documentHeader = divWrapper(state.documentHeader);
        state.documentFooter = divWrapper(state.documentFooter);
    }
    async function updateTaxValue(id) {
        const res = await tax.byKey(id);
        state.taxValue = res.taxValue;
        state.taxName = res.taxName;
    }
    async function handleGenerateInvoice(e) {
        const offerData = e.row.data;
        const invoiceData = await generateInvoiceData(
            state.admin.id,
            state.admin.domainId,
            state.businessYearId,
            null,
            offerData,
            null,
            null,
            null
        );

        const newInvoice = await invoice.insert(invoiceData);
        const invoiceItems = await offerItem.load({
            offerId: offerData.id
        });
        for (let i = 0; i < invoiceItems.length; i++) {
            const item = invoiceItems[i];
            delete item.id;
            delete item.offerId;
            item.invoiceId = newInvoice.id;
            await invoiceItem.insert(item);
        }
    }
    async function handleInitNewRowOffer(e, duplicateOfferData = null) {
        const loadOptions = {
            createdById: state.admin.id
        };
        const res = await offer.load(loadOptions);
        let number = 1;
        if (res.length > 0) {
            number = res[0].number + 1;
        }
        if (duplicateOfferData !== null) {
            e.data = duplicateOfferData;
        } else {
            e.data = {
                businessYearId: Number(state.businessYearId)
            };
            state.offerItemData = [];
        }
        return number;
    }
    function handleRowInsertingOffer(e, date, expireDate, number) {
        const now = new Date().toISOString();
        delete e.data.id;
        e.data = {
            ...e.data,
            businessYearId: Number(state.businessYearId),
            date: date ? date : e.data.date,
            expireDate: expireDate ? expireDate : e.data.expireDate,
            number: number ? number : e.data.number,
            createdById: state.admin.id,
            modifiedById: state.admin.id,
            createdDate: now,
            modifiedDate: now,
            domainId: state.admin.domainId,
            status: "new",
            taxId: state.taxId !== null ? state.taxId : e.data.taxId,
            taxValue:
                state.taxValue !== null ? state.taxValue : e.data.taxValue,
            taxName: state.taxName !== null ? state.taxName : e.data.taxName,
            usluge: null
        };
    }
    async function handleRowInsertedOffer(e) {
        let totalPriceByQty = 0;
        let totalDiscountAmount = 0;
        let totalAmountDiscounted = 0;
        let totalAmountTaxed = 0;
        const userData =
            state.admin.domainId === 1
                ? await user.byKey(e.data.userId)
                : await customer.byKey(e.data.userId);
        const now = new Date();
        const allItemPromises = state.offerItemData.map((item) => {
            return priceListItem
                .byKey(item.priceListItemId)
                .then((res) => {
                    if (item.id) {
                        delete item.id;
                    }
                    delete item.__KEY__;
                    Object.assign(item, {
                        offerId: e.key,
                        priceTotal: Number(item.priceTotal),
                        createdById: state.admin.id,
                        modifiedById: state.admin.id,
                        createdDate: now.toISOString(),
                        modifiedDate: now.toISOString(),
                        priceListItem: JSON.stringify(res)
                    });
                })
                .then((res) => {
                    console.log(res);
                    offerItem.insert(item);
                    totalDiscountAmount += item.discountAmount;
                    totalAmountDiscounted += Number(item.priceTotal);
                    totalPriceByQty += item.priceByQty;
                    totalAmountTaxed +=
                        Number(item.priceTotal) * (1 + res.vat / 100);
                });
        });
        await Promise.all(allItemPromises);
        const taxVal =
            state.taxValue !== null ? state.taxValue : e.data.taxValue;
        const totalAmountAfterTax = totalAmountDiscounted * (1 + taxVal / 100);
        console.log(
            "totalAmountTaxed stavke:",
            totalAmountTaxed,
            "totalAmountAfterTax:",
            totalAmountAfterTax
        );
        const totalTaxAmount = totalAmountTaxed - totalAmountDiscounted;
        await offer.update(e.key, {
            totalAmountTaxed: totalAmountTaxed,
            totalTaxAmount: totalTaxAmount,
            totalAmountDiscounted: totalAmountDiscounted,
            totalDisocuntAmount: totalDiscountAmount,
            totalAmount: totalPriceByQty,
            user: JSON.stringify(userData)
        });
        const updatedOffer = await offer.byKey(e.key);
        const logData = [{ offer: updatedOffer }];
        const offerItems = await offerItem.load({
            offerId: e.key
        });
        offerItems.forEach((item) => {
            logData.push({ item });
        });
        const changesLogData = {
            actionType: "INSERT",
            documentType: "offer",
            oldValue: null,
            newValue: JSON.stringify(logData),
            modifiedById: state.admin.id,
            modifiedDate: new Date().toISOString()
        };
        await changesLog.insert(changesLogData);
        state.offerItemData = [];
        await lookupStore.fetchLookups(state.admin.domainId);
    }
    function handleEditingStartOffer(e) {
        state.editingOffer = e.data;
        state.taxId = e.data.taxId;
        state.taxValue = e.data.taxValue;
        state.taxName = e.data.taxName;
        offerItem.load();
    }
    function handleEditCanceledOffer() {
        state.offerItemData = [];
        state.rowsToBeDeleted = [];
    }
    async function handleRowUpdatingOffer(e) {
        const now = new Date();
        const snapshotOfferItems =
            state.snapshotOfferItems.length === 0
                ? [...state.offerItemData]
                : [...state.snapshotOfferItems];
        let totalPriceByQty = 0;
        let totalDiscountAmount = 0;
        let totalAmountDiscounted = 0;
        let totalAmountTaxed = 0;
        const userData = e.newData.userId
            ? state.admin.domainId === 1
                ? await user.byKey(e.newData.userId)
                : await customer.byKey(e.newData.userId)
            : null;
        const allPromises = [];
        // const snapshotOfferItems = [...state.offerItemData];
        const oldOffer = await offer.byKey(e.key);
        const oldOfferItems = await offerItem.load({
            offerId: e.key
        });
        const oldDataLog = [{ offer: oldOffer }];
        oldOfferItems.forEach((item) => {
            oldDataLog.push({ item });
        });
        const deletePromises = state.rowsToBeDeleted.map((item) => {
            return offerItem.remove(item.id).then(() => {
                state.rowsToBeDeleted = [];
            });
        });
        allPromises.push(...deletePromises);
        const insertPromises = snapshotOfferItems.map((item) => {
            return priceListItem.byKey(item.priceListItemId).then((res) => {
                if (item.id) {
                    offerItem.remove(item.id);
                    delete item.id;
                }
                if (item.__KEY__) {
                    delete item.__KEY__;
                }
                Object.assign(item, {
                    offerId: e.key,
                    priceTotal: Number(item.priceTotal),
                    createdById: state.admin.id,
                    modifiedById: state.admin.id,
                    createdDate: now.toISOString(),
                    modifiedDate: now.toISOString(),
                    priceListItem: JSON.stringify(res)
                });
                return offerItem.insert(item).then(() => {
                    totalDiscountAmount += item.discountAmount;
                    totalAmountDiscounted += Number(item.priceTotal);
                    totalPriceByQty += item.priceByQty;
                    totalAmountTaxed +=
                        Number(item.priceTotal) * (1 + res.vat / 100);
                });
            });
        });
        allPromises.push(...insertPromises);
        await Promise.all(allPromises);
        const taxVal =
            state.taxValue !== null
                ? state.taxValue
                : e.oldData.taxValue
                ? e.oldData.taxValue
                : e.newData.taxValue;
        const totalAmountAfterTax = totalAmountDiscounted * (1 + taxVal / 100);
        console.log(
            "totalAmountTaxed stavke:",
            totalAmountTaxed,
            "totalAmountAfterTax:",
            totalAmountAfterTax
        );
        const totalTaxAmount = totalAmountTaxed - totalAmountDiscounted;
        const updateData = {
            totalAmountTaxed: totalAmountTaxed,
            totalTaxAmount: totalTaxAmount,
            totalAmountDiscounted: totalAmountDiscounted,
            totalDisocuntAmount: totalDiscountAmount,
            totalAmount: totalPriceByQty
        };
        if (userData !== null) {
            updateData.user = JSON.stringify(userData);
        }
        await offer.update(e.key, updateData);
        const updatedOffer = await offer.byKey(e.key);
        const updatedOfferItems = await offerItem.load({
            offerId: e.key
        });

        const newDataLog = [{ offer: updatedOffer }];
        updatedOfferItems.forEach((item) => {
            newDataLog.push({ item });
        });

        const changesLogData = {
            actionType: "UPDATE",
            documentType: "offer",
            oldValue: JSON.stringify(oldDataLog),
            newValue: JSON.stringify(newDataLog),
            modifiedById: state.admin.id,
            modifiedDate: now
        };

        await changesLog.insert(changesLogData);
        state.offerItemData = [];
        await lookupStore.fetchLookups(state.admin.domainId);
        eventBus.emit("newOfferItemAdded", e.key);
    }
    async function handleDeleteOffer(e) {
        const oldOffer = await offer.byKey(e.row.data.id);
        const oldOfferItems = await offerItem.load({
            offerId: e.row.data.id
        });
        const oldDataLog = [{ offer: oldOffer }];
        oldOfferItems.forEach((item) => {
            oldDataLog.push({ item });
        });
        const changesLogData = {
            actionType: "DELETE",
            documentType: "offer",
            oldValue: JSON.stringify(oldDataLog),
            newValue: null,
            modifiedById: state.admin.id,
            modifiedDate: new Date().toISOString()
        };
        await changesLog.insert(changesLogData);
        await lookupStore.fetchLookups(state.admin.domainId);
    }
    async function handleStatusChangeOffer(e) {
        const oldDataLog = [{ offer: e.row.data }];
        const updatedOffer = await offer.byKey(e.row.key);
        const newDataLog = [{ offer: updatedOffer }];

        const changesLogData = {
            actionType: "UPDATE",
            documentType: "offer",
            oldValue: JSON.stringify(oldDataLog),
            newValue: JSON.stringify(newDataLog),
            modifiedById: state.admin.id,
            modifiedDate: new Date().toISOString()
        };
        await changesLog.insert(changesLogData);
    }
    async function handleExportingOfferRecap(table) {
        const doc = new jsPDF();
        doc.addFileToVFS("@/assets/Poppins-Regular.ttf", poppins);
        doc.addFileToVFS("@/assets/Poppins-Bold.ttf", poppinsBold);
        doc.addFont(
            "@/assets/Poppins-Regular.ttf",
            "Poppins-Regular",
            "normal"
        );
        doc.addFont("@/assets/Poppins-Bold.ttf", "Poppins-Bold", "bold");
        doc.setFont("Poppins-Regular", "normal");
        doc.setFontSize(8);
        const lastPoint = { x: 0, y: 0 };
        doc.setTextColor(0, 0, 0);
        // const combinedFilter = table.getCombinedFilter(true);
        // let filterValueDate = null;
        // let filterValueYear = null;
        // if (combinedFilter && combinedFilter[0][0] === "date") {
        //     if (typeof combinedFilter.filterValue === "string") {
        //         filterValueDate = combinedFilter.filterValue;
        //     } else {
        //         filterValueYear = combinedFilter.filterValue;
        //     }
        // }
        // let formattedDateRange = null;
        // if (filterValueDate) {
        //     const [year, month] = filterValueDate.split("/").map(Number);
        //     const startDate = new Date(year, month - 1, 1);
        //     const endDate = new Date(year, month, 0);
        //     const formatDate = (date) => {
        //         const day = String(date.getDate()).padStart(2, "0");
        //         const month = String(date.getMonth() + 1).padStart(2, "0");
        //         const year = date.getFullYear();
        //         return `${day}.${month}.${year}`;
        //     };
        //     const startString = formatDate(startDate);
        //     const endString = formatDate(endDate);
        //     formattedDateRange = `Datum: od ${startString}. do ${endString}.`;
        // } else if (filterValueYear) {
        //     formattedDateRange = `Datum: Godina ${filterValueYear}.`;
        // } else {
        //     formattedDateRange = `Datum: Nije odabran`;
        // }
        exportPDF({
            jsPDFDocument: doc,
            component: table,
            repeatHeaders: true,
            topLeft: { x: 1, y: 40 },
            columnWidths: [10, 20, 15, 55, 16, 16, 16, 16, 16],
            onRowExporting: (e) => {
                if (e.rowCells[0].backgroundColor === "#D3D3D3") {
                    e.rowHeight = 9;
                } else {
                    e.rowHeight = 13;
                }
            },
            customDrawCell({ rect, gridCell, pdfCell }) {
                if (gridCell.rowType === "header") {
                    pdfCell.font.size = 8;
                } else if (gridCell.rowType === "data") {
                    pdfCell.font.size = 7;
                } else if (gridCell.rowType === "totalFooter") {
                    pdfCell.font.style = "normal";
                    pdfCell.font.size = 7;
                }
                if (lastPoint.x < rect.x + rect.w) {
                    lastPoint.x = rect.x + rect.w;
                }
                if (lastPoint.y < rect.y + rect.h) {
                    lastPoint.y = rect.y + rect.h;
                }
            },
            customizeCell({ gridCell, pdfCell }) {
                pdfCell.padding = 0;
                pdfCell.borderColor = "#D3D3D3";
                pdfCell.horizontalAlign = "center";
                pdfCell.wordWrapEnabled = true;
                if (gridCell.rowType === "header") {
                    pdfCell.textColor = "#000000";
                    pdfCell.horizontalAlign = "center";
                    pdfCell.backgroundColor = "#D3D3D3";
                } else if (gridCell.rowType === "totalFooter") {
                    pdfCell.font.size = 9;
                }
            }
        }).then(() => {
            const pageWidth = doc.internal.pageSize.getWidth();
            const pageHeight = doc.internal.pageSize.getHeight();
            const generatedTime = getCurrentDateTime();
            const generated = `Vrijeme izrade: ${generatedTime}`;
            const watermark = `Dokument izrađen u besplatnoj verziji aplikacije "Skladište" - skladiste.gtnet.hr`;
            if (checkWatermark()) {
                doc.setFontSize(8);
                doc.setTextColor("gainsboro");
                doc.text(watermark, 7.5, pageHeight - 10, {
                    angle: 90,
                    rotationDirection: "1"
                });
                doc.setTextColor(0, 0, 0);
            }
            doc.setFontSize(10);
            doc.line(15, 47, pageWidth - 15, 47);
            doc.setFont("Poppins-Bold", "bold");
            doc.text("Rekapitulacija ponuda", 15, 45);
            doc.setFont("Poppins-Regular", "normal");
            doc.text(generated, pageWidth - 15, 45, { align: "right" });
            // doc.text(formattedDateRange, 15, 27);
            // doc.save("Rekapitulacija-ponuda.pdf");
            const documentName = "Rekapitulacija-ponuda.pdf";
            doc.html(state.documentHeader, {
                x: 15,
                y: 10,
                width: 180,
                margin: [0, 0, 0, 0],
                windowWidth: 800,
                callback: (doc) => {
                    doc.html(state.documentFooter, {
                        x: 15,
                        y: pageHeight - 10,
                        width: 180,
                        windowWidth: 800,
                        margin: [0, 0, 0, 0],
                        callback: (doc) => {
                            doc.save(documentName);
                        }
                    });
                }
            });
        });
    }
    function handleCancelOfferItem() {
        state.editingOfferItemOldData.pop();
        state.editingOfferItem = null;
    }
    function handleDeleteOfferItem(e) {
        state.offerItemData.splice(e.row.rowIndex, 1);
        if (e.row.data.id) {
            state.rowsToBeDeleted.push(e.row.data);
        }
    }
    function handleSaveNewOfferItem(qty, price, priceTotal) {
        const now = new Date();
        if (state.editingOfferItem !== null) {
            state.editingOfferItem.qty = qty;
            state.editingOfferItem.price = price;
            state.editingOfferItem.priceTotal = Number(priceTotal);
            state.editingOfferItem.modifiedDate = now.toISOString();
            state.editingOfferItem.modifiedById = Number(state.admin.id);
            const index = state.offerItemData.findIndex((el) => {
                return (
                    (state.editingOfferItem.__KEY__ &&
                        el.__KEY__ === state.editingOfferItem.__KEY__) ||
                    el.id === state.editingOfferItem.id
                );
            });
            if (index !== -1) {
                state.offerItemData[index] = state.editingOfferItem;
                state.snapshotOfferItems = [...state.offerItemData];
            }
            // state.offerItemData.find((el) => {
            //     if (
            //         state.editingOfferItem.__KEY__ &&
            //         el.__KEY__ === state.editingOfferItem.__KEY__
            //     ) {
            //         el = state.editingOfferItem;
            //     } else if (el.id === state.editingOfferItem.id) {
            //         el = state.editingOfferItem;
            //     }
            // });
            state.editingOfferItem = null;
        } else {
            state.editingOfferItem = null;
        }
    }
    function handleRowInsertingOfferItem(
        e,
        qty,
        price,
        priceTotal,
        discountAmount,
        discountPercentage,
        priceListItemId
    ) {
        e.data = {
            ...e.data,
            qty: qty,
            price: price,
            priceTotal: Number(priceTotal),
            discountAmount: discountAmount,
            discountPercentage: discountPercentage,
            priceListItemId: priceListItemId
        };
    }
    function handleEditingStartOfferItem(e) {
        state.editingOfferItem = e.data;
        const oldData = {
            ...state.editingOfferItem
        };
        state.editingOfferItemOldData.push(oldData);
    }
    async function handleSendEmailPopupData(selectedRowKey) {
        const offerData = await offer.byKey(selectedRowKey);
        let offerItemData = "";
        state.offerItemData.forEach((e, i) => {
            const item = JSON.parse(e.priceListItem);
            const itemName = item.name;
            if (state.offerItemData.length === 1) {
                offerItemData += itemName;
            } else if (i === state.offerItemData.length - 1) {
                offerItemData = offerItemData.slice(0, -2);
                offerItemData += ` i ${itemName}`;
            } else {
                offerItemData += `${itemName}, `;
            }
        });
        const offerUserData = JSON.parse(offerData.user);
        state.emailFormData.emailTo = offerUserData.email;

        state.emailFormData.senderId = state.admin.id;
        state.emailFormData.clientId = offerUserData.id;
        state.emailFormData.sourceId = selectedRowKey;
        state.emailFormData.dateSent = moment().format("YYYY-MM-DD HH:mm:ss");
        const domainUserData = await domainUser.byKey(state.admin.id);
        const businessYearData = await businessYear.byKey(
            offerData.businessYearId
        );
        const domainRes = await domain.byKey(domainUserData.domainId);
        if (domainRes) {
            const defaultEmailContent = JSON.parse(
                domainRes.defaultEmailContent
            );
            if (defaultEmailContent && defaultEmailContent.ponuda) {
                if (defaultEmailContent.ponuda.subject !== null) {
                    if (defaultEmailContent.ponuda.subject.includes("{var}")) {
                        const subjectToReplace =
                            defaultEmailContent.ponuda.subject.replace(
                                "{var}",
                                `${offerData.createdById}-${offerData.number}/${businessYearData.year}`
                            );
                        state.emailFormData.subject = subjectToReplace;
                    } else {
                        state.emailFormData.subject =
                            defaultEmailContent.ponuda.subject !== null
                                ? defaultEmailContent.ponuda.subject
                                : null;
                    }
                }
                state.emailFormData.emailFrom =
                    defaultEmailContent.ponuda.email_from !== null
                        ? defaultEmailContent.ponuda.email_from
                        : null;

                state.emailFormData.message =
                    defaultEmailContent.ponuda.message !== null
                        ? defaultEmailContent.ponuda.message
                        : null;
            }
        }
        const emailSignatureDefaultData = await emailSignatureDefault.load({
            domainId: domainUserData.domainId
        });
        if (domainUserData.emailSignature !== null) {
            state.signature = domainUserData.emailSignature;
        } else {
            state.signature = `--<br>
            <strong>${state.admin.username}</strong><br>
            ${
                emailSignatureDefaultData.signature
                    ? emailSignatureDefaultData.signature
                    : ""
            }`;
        }
    }
    async function handleSendEmail(sendEmailFlag, selectedRowKey) {
        const dontChangeStatus = ["paid", "accepted", "rejected"];
        sendEmailFlag = true;
        // let responseData;
        const pdfGenerationPromise = new Promise((resolve, reject) => {
            const pdfEventListener = (pdfForEmail) => {
                if (pdfForEmail && pdfForEmail.doc) {
                    resolve(pdfForEmail);
                } else {
                    reject(new Error("PDF generation failed"));
                }
            };
            eventBus.on("offerPdfForEmail", pdfEventListener);
            eventBus.emit("exportOfferItemToPDF", sendEmailFlag);
        });

        try {
            const pdfFile = await pdfGenerationPromise;
            state.emailFormData.message =
                state.emailFormData.message.replace(/\\n/g, "<br>") +
                "<br>" +
                state.signature
                    .replace(/<\/p><p>/g, "<br>")
                    .replace(/<p>/g, "")
                    .replace(/<\/p>/g, "");

            const res = await offer.byKey(selectedRowKey);
            // const formData = new FormData();
            const pdfBlob = pdfFile.doc.output("blob");
            // formData.append("file", pdfBlob, pdfFile.documentName);
            // formData.append("json", JSON.stringify(state.emailFormData));
            const reader = new FileReader();
            reader.readAsDataURL(pdfBlob);
            const callbackPromise = new Promise((resolve) => {
                reader.onloadend = async () => {
                    const base64data = reader.result;
                    const requestOptions = {
                        method: "POST",
                        file: {
                            base64: base64data,
                            name: pdfFile.documentName
                        },
                        json: state.emailFormData
                    };
                    try {
                        const response = await emailStore.sendEmail(
                            requestOptions
                        );
                        if (!dontChangeStatus.includes(res.status)) {
                            try {
                                await offer.update(selectedRowKey, {
                                    status: "pending"
                                });
                                sendEmailFlag = false;
                                resolve({ emailFlag: sendEmailFlag, response });
                            } catch (e) {
                                console.error(e);
                            }
                        }
                        sendEmailFlag = false;
                        state.emailFormData = {
                            sourceType: "offer",
                            sourceId: null,
                            senderId: null,
                            dateSent: null,
                            clientId: null,
                            emailFrom: null,
                            emailTo: null,
                            subject: null,
                            message: null,
                            domainId: domainUserStore.user.domainId
                        };
                        resolve({ emailFlag: sendEmailFlag, response });
                    } catch (e) {
                        console.error(e);
                    }
                };
            });
            const result = await callbackPromise;
            return result;
            // const requestOptions = {
            //     method: "POST",
            //     body: formData
            // };

            // const response = await fetch(
            //     "http://billing.gtnet.hr/cli/procedure/emailLogUpload.php",
            //     requestOptions
            // );

            // if (!response.ok) {
            //     throw new Error("Network response was not ok");
            // }

            // responseData = await response.json();
            // if (!dontChangeStatus.includes(res.status)) {
            //     offer.update(selectedRowKey, {
            //         status: "pending"
            //     });
            // }
            // sendEmailFlag = false;
            // return { sendEmailFlag, responseData };
        } catch (error) {
            console.error(
                "Error during PDF generation or email sending: ",
                error
            );
        } finally {
            sendEmailFlag = false;
            state.emailFormData = {
                sourceType: "offer",
                sourceId: null,
                senderId: null,
                dateSent: null,
                clientId: null,
                emailFrom: null,
                emailTo: null,
                subject: null,
                message: null,
                domainId: domainUserStore.user.domainId
            };
        }
    }
    return {
        ...toRefs(state),
        handleGenerateInvoice,
        handleInitNewRowOffer,
        handleRowInsertingOffer,
        handleRowInsertedOffer,
        handleEditingStartOffer,
        handleEditCanceledOffer,
        handleRowUpdatingOffer,
        handleCancelOfferItem,
        handleDeleteOffer,
        handleDeleteOfferItem,
        handleSaveNewOfferItem,
        handleRowInsertingOfferItem,
        handleEditingStartOfferItem,
        handleSendEmailPopupData,
        handleSendEmail,
        handleExportingOfferRecap,
        getDomainData,
        updateTaxValue,
        handleStatusChangeOffer
    };
}
