<template>
    <!-- <div class="table-container"> -->
    <DxDataGrid
        v-if="shouldShowTheDataGrid && !lookupLoading"
        id="initialStateItemTable"
        :data-source="initialStateItem"
        :repaint-changes-only="true"
        :show-column-lines="true"
        :allow-column-resizing="true"
        :show-row-lines="true"
        :show-borders="true"
        :row-alternation-enabled="true"
        :column-min-width="70"
        :column-auto-width="true"
        @initialized="getInitialStateItemTableInstance"
    >
        <DxToolbar>
            <DxItem
                :options="refreshButtonOptions"
                location="after"
                locate-in-menu="auto"
                widget="dxButton"
            />
            <DxItem name="searchPanel" location="after" locate-in-menu="auto" />
        </DxToolbar>
        <DxSearchPanel :visible="true" />
        <DxStateStoring
            :enabled="true"
            type="localStorage"
            storage-key="initialStateItemTableState"
        />
        <DxScrolling column-rendering-mode="virtual" />
        <DxPager
            :visible="true"
            display-mode="adaptive"
            :show-info="true"
            info-text="Stranica {0} od {1} ({2} stavki)"
            :allowed-page-sizes="[10, 20, 30]"
            :show-navigation-buttons="true"
            :show-page-size-selector="true"
        />
        <DxPaging :page-size="10" />
        <DxColumnFixing :enabled="true" />
        <DxColumn
            caption="Rb."
            :width="50"
            alignment="center"
            :calculate-cell-value="setRowIndex"
            :allow-hiding="false"
            :show-in-column-chooser="false"
        />
        <DxColumn data-field="id" data-type="number" :visible="false" />
        <DxColumn
            data-field="priceListItemId"
            data-type="number"
            caption="Šifra"
            name="code"
        >
            <DxLookup
                :items="lookupData.priceListItem"
                value-expr="id"
                display-expr="code"
        /></DxColumn>
        <DxColumn
            data-field="priceListItemId"
            data-type="number"
            caption="Stavka"
        >
            <DxLookup
                :items="lookupData.priceListItem"
                value-expr="id"
                display-expr="name"
            />
        </DxColumn>
        <DxColumn
            width="120"
            data-field="price"
            data-type="number"
            caption="Cijena"
            format="###,###,###,##0.00"
        />
        <DxColumn
            width="120"
            data-field="qty"
            data-type="number"
            caption="Količina"
            format="###,###,###,##0.00"
        />
        <DxColumn
            width="120"
            data-field="priceTotal"
            data-type="number"
            format="###,###,###,##0.00"
            caption="Ukupno"
        />
        <DxSummary>
            <DxTotalItem
                column="priceTotal"
                summary-type="sum"
                :value-format="priceTotalFormat"
                display-format="{0}"
            />
        </DxSummary>
    </DxDataGrid>
    <!-- </div> -->
</template>
<script>
//DevExpress
import {
    DxToolbar,
    DxItem,
    DxSearchPanel,
    DxDataGrid,
    DxColumn,
    DxColumnFixing,
    DxScrolling,
    DxPager,
    DxSummary,
    DxTotalItem,
    DxLookup,
    DxStateStoring,
    DxPaging
} from "devextreme-vue/data-grid";
import notify from "devextreme/ui/notify";
import { useInitialStateItem } from "@/composables/useInitialStateItem.js";

//Utils
import eventBus from "../../eventBus.js";
import { ref, watch, onMounted, onUnmounted } from "vue";
import { useLookupStore } from "@/stores/customLookupStore.js";

export default {
    name: "initialStateItemTableComponent",
    components: {
        DxPager,
        DxPaging,
        DxDataGrid,
        DxColumn,
        DxColumnFixing,
        DxScrolling,
        DxSummary,
        DxTotalItem,
        DxLookup,
        DxStateStoring,
        DxToolbar,
        DxItem,
        DxSearchPanel
    },
    setup() {
        const lookupStore = useLookupStore();
        const lookupData = ref(null);
        const lookupLoading = ref(true);
        const {
            admin,
            priceListItem,
            initialStateItem,
            priceTotalFormat,
            handleInitialStateExporting,
            getDomainData,
            initialStateData,
            fetchInitialStateDataByKey,
            fetchWarehouseDataByKey,
            fetchBusinessYearDataByKey
        } = useInitialStateItem();
        onMounted(async () => {
            eventBus.on(
                "newFocusedRowInitialState",
                handleNewFocusedRowInitialState
            );
            eventBus.on("exportInitialState", handleExportInitialState);
            eventBus.on(
                "newInitialStateItemAdded",
                handleNewInitialStateItemAdded
            );
            eventBus.on("lookupLoading", handleLookupLoading);
            // console.log(lookupStore.isLoading);
            if (
                !lookupStore.isLoading &&
                Object.keys(lookupStore.lookupData).length > 0
            ) {
                lookupData.value = lookupStore.lookupData;
                lookupLoading.value = false;
            }
            await getDomainData();
            await fetchBusinessYearDataByKey();
        });
        onUnmounted(() => {
            eventBus.off(
                "newFocusedRowInitialState",
                handleNewFocusedRowInitialState
            );
            eventBus.off("exportInitialState", handleExportInitialState);
            eventBus.off(
                "newInitialStateItemAdded",
                handleNewInitialStateItemAdded
            );
            eventBus.off("lookupLoading", handleLookupLoading);
        });
        // eventBus.on("exportInitialState", (initialStateId) => {
        //     handleExportInitialState(tableInstance.value, initialStateId);
        // });
        // eventBus.on("newInitialStateItemAdded", () => {
        //     tableInstance.value.refresh();
        // });
        // eventBus.on("newFocusedRowInitialState", async (data) => {
        //     if (data && data !== null) {
        //         await fetchInitialStateDataByKey(data);
        //         await fetchWarehouseDataByKey(
        //             initialStateData.value.warehouseId
        //         );
        //         focusedId.value = true;
        //     } else {
        //         focusedId.value = false;
        //     }
        //     tableInstance.value.refresh();
        // });

        const tableInstance = ref(null);
        const focusedId = ref(false);
        const shouldShowTheDataGrid = ref(true);
        const refreshButtonOptions = ref({
            icon: "refresh",
            onClick: () => {
                refreshData();
            }
        });
        const getInitialStateItemTableInstance = (e) => {
            tableInstance.value = e.component;
            if (!focusedId.value) shouldShowTheDataGrid.value = false;
        };
        const setRowIndex = (rowData) => {
            if (!tableInstance.value) return "";
            const currentPageIndex = tableInstance.value.pageIndex();
            const pageSize = tableInstance.value.pageSize();

            // Find row index in current page (works for UI & export)
            const data = tableInstance.value.getDataSource().items();
            const rowIndexInPage = data.findIndex(
                (item) => item.id === rowData.id
            );

            return currentPageIndex * pageSize + (rowIndexInPage + 1);
        };
        const refreshData = async () => {
            const message = "Podaci osvježeni!";
            tableInstance.value.refresh();
            notify({ message, width: 450 }, "success");
            await lookupStore.fetchLookups(admin.value.domainId);
        };
        const handleLookupLoading = (e) => {
            lookupData.value = lookupStore.getLookupData;
            lookupLoading.value = e;
        };
        const handleNewFocusedRowInitialState = async (data) => {
            if (data && data !== null) {
                await fetchInitialStateDataByKey(data);
                await fetchWarehouseDataByKey(
                    initialStateData.value.warehouseId
                );
                focusedId.value = true;
            } else {
                focusedId.value = false;
            }
            tableInstance.value.refresh();
        };
        const handleNewInitialStateItemAdded = () => {
            tableInstance.value.refresh();
        };
        const handleExportInitialState = (initialStateId) => {
            handleInitialStateExporting(tableInstance.value, initialStateId);
        };
        watch(
            () => focusedId.value,
            () => {
                shouldShowTheDataGrid.value = focusedId.value ? true : false;
            }
        );
        watch(
            () => lookupStore.lookupData,
            () => {
                shouldShowTheDataGrid.value =
                    Object.keys(lookupStore.lookupData).length > 0
                        ? true
                        : false;
                lookupData.value = lookupStore.lookupData;
            }
        );
        return {
            refreshButtonOptions,
            setRowIndex,
            getInitialStateItemTableInstance,
            priceListItem,
            initialStateItem,
            priceTotalFormat,
            shouldShowTheDataGrid,
            lookupLoading,
            lookupData
        };
    }
};
</script>
<style scoped>
.table-container {
    margin: 2vh;
    max-width: 100%;
}
#initialStateItemTable {
    width: 100%;
}
</style>
