<template>
    <!-- <div class="table-container"> -->
    <DxDataGrid
        v-if="shouldShowTheDataGrid && !lookupLoading"
        id="offerItemTable"
        :data-source="offerItem"
        :repaint-changes-only="true"
        :allow-column-resizing="true"
        :show-column-lines="true"
        :show-row-lines="true"
        :show-borders="true"
        :column-auto-width="true"
        :show-toolbar="false"
        @initialized="getOfferItemTableInstance"
    >
        <DxStateStoring
            :enabled="true"
            type="localStorage"
            storage-key="offerItemTableState"
        />
        <DxToolbar>
            <DxItem
                :options="refreshButtonOptions"
                location="after"
                locate-in-menu="auto"
                widget="dxButton"
            />
            <DxItem name="searchPanel" location="after" locate-in-menu="auto" />
        </DxToolbar>
        <DxSearchPanel :visible="true" />
        <DxScrolling column-rendering-mode="virtual" />
        <DxPager
            :visible="true"
            display-mode="adaptive"
            :show-info="true"
            info-text="Stranica {0} od {1} ({2} stavki)"
            :allowed-page-sizes="[10, 20, 30]"
            :show-navigation-buttons="true"
            :show-page-size-selector="true"
        />
        <DxPaging :page-size="10" />
        <DxColumnFixing :enabled="true" />
        <DxColumn data-field="id" data-type="number" :visible="false" />
        <DxColumn
            :allow-exporting="false"
            data-field="priceListItemId"
            data-type="number"
            caption="Šifra"
            name="codeColumn"
        >
            <DxLookup
                :items="lookupData.priceListItem"
                value-expr="id"
                display-expr="code"
            />
        </DxColumn>
        <DxColumn
            data-field="priceListItemId"
            data-type="number"
            caption="Naziv"
            name="nameColumn"
            :width="200"
        >
            <DxLookup
                :items="lookupData.priceListItem"
                value-expr="id"
                display-expr="name"
            />
        </DxColumn>
        <DxColumn
            data-field="priceListItemId"
            data-type="number"
            caption="JMJ"
            name="measureUnitColumn"
        >
            <DxLookup
                :items="lookupData.priceListItem"
                value-expr="id"
                display-expr="measureUnit"
            />
        </DxColumn>
        <DxColumn
            width="120"
            data-field="qty"
            data-type="number"
            caption="Kol."
            format="###,###,###,##0.00"
        />
        <DxColumn
            data-field="price"
            data-type="number"
            caption="Cijena"
            :format="priceTotalFormat"
        />
        <!-- <DxLookup
                :items="lookupData.offerItem"
                value-expr="id"
                display-expr="price"
            />
        </DxColumn> -->
        <DxColumn
            data-field="priceByQty"
            data-type="number"
            caption="Iznos"
            :format="priceTotalFormat"
        />
        <!-- <DxLookup
                :items="lookupData.offerItem"
                value-expr="id"
                display-expr="priceByQty"
            />
        </DxColumn> -->
        <DxColumn
            data-field="discountAmount"
            data-type="number"
            caption="Rabat"
            :format="priceTotalFormat"
        />
        <!-- <DxLookup
                :items="lookupData.offerItem"
                value-expr="id"
                display-expr="discountAmount"
            />
        </DxColumn> -->
        <DxColumn
            data-field="discountPercentage"
            data-type="number"
            caption="Rab%"
            :format="priceTotalFormat"
        />
        <!-- <DxLookup
                :items="lookupData.offerItem"
                value-expr="id"
                display-expr="discountPercentage"
            />
        </DxColumn> -->
        <DxColumn
            data-field="priceTotal"
            data-type="number"
            caption="Konačan iznos"
            :format="priceTotalFormat"
        />
        <!-- <DxLookup
                :items="lookupData.offerItem"
                value-expr="id"
                display-expr="priceTotal"
            /> -->
        <!-- <DxSummary>
            <DxTotalItem
                column="priceTotal"
                summary-type="sum"
                :value-format="priceTotalFormat"
                display-format="UKUPNO: {0}"
            />
        </DxSummary> -->
    </DxDataGrid>
    <!-- </div> -->
</template>
<script>
//DevExpress
import {
    DxPager,
    DxDataGrid,
    DxColumn,
    DxColumnFixing,
    DxScrolling,
    DxLookup,
    DxStateStoring,
    DxItem,
    DxSearchPanel,
    DxToolbar,
    DxPaging
    // DxSummary,
    // DxTotalItem
} from "devextreme-vue/data-grid";
import notify from "devextreme/ui/notify";
//composables
import { useOfferItem } from "@/composables/useOfferItem.js";

//utils
import eventBus from "../../eventBus.js";
import { ref, watch, onMounted, onUnmounted } from "vue";
import { useLookupStore } from "@/stores/customLookupStore.js";

export default {
    name: "offerItemTableComponent",
    components: {
        // DxSummary,
        // DxTotalItem,
        DxPager,
        DxColumn,
        DxLookup,
        DxDataGrid,
        DxScrolling,
        DxColumnFixing,
        DxStateStoring,
        DxItem,
        DxSearchPanel,
        DxToolbar,
        DxPaging
    },
    setup() {
        const lookupStore = useLookupStore();
        const lookupData = ref(null);
        const lookupLoading = ref(true);
        const {
            admin,
            customer,
            domain,
            offerItem,
            getUserByKey,
            businessYear,
            getDomainData,
            priceListItem,
            getOfferByKey,
            priceTotalFormat,
            handleOfferExporting,
            getBusinessYearByKey
        } = useOfferItem();
        const tableInstance = ref(null);
        const focusedId = ref(false);
        const shouldShowTheDataGrid = ref(true);
        const refreshButtonOptions = ref({
            icon: "refresh",
            onClick: () => {
                refreshData();
            }
        });
        onMounted(async () => {
            eventBus.on("newFocusedRowOffer", handleNewFocusedRowOffer);
            eventBus.on("exportOfferItemToPDF", handleExportOffer);
            eventBus.on("newOfferItemAdded", handleNewOfferItemAdded);
            eventBus.on("lookupLoading", handleLookupLoading);
            if (
                !lookupStore.isLoading &&
                Object.keys(lookupStore.lookupData).length > 0
            ) {
                // Object.assign(lookupData, lookupStore.lookupData);
                lookupData.value = lookupStore.lookupData;

                lookupLoading.value = false;
            }
            await getDomainData();
        });
        onUnmounted(() => {
            eventBus.off("newFocusedRowOffer", handleNewFocusedRowOffer);
            eventBus.off("exportOfferItemToPDF", handleExportOffer);
            eventBus.off("newOfferItemAdded", handleNewOfferItemAdded);
            eventBus.off("lookupLoading", handleLookupLoading);
        });
        // eventBus.on("newOfferItemAdded", async (key) => {
        //     await getOfferByKey(key);
        //     await getUserByKey();
        //     await getBusinessYearByKey();
        //     tableInstance.value.refresh();
        // });
        // eventBus.on("newFocusedRowOffer", async (data) => {
        //     if (data !== null) {
        //         focusedId.value = true;
        //         await getOfferByKey(data);
        //         await getUserByKey();
        //         await getBusinessYearByKey();
        //     } else {
        //         focusedId.value = false;
        //     }
        //     tableInstance.value.refresh();
        // });
        // eventBus.on("exportOfferItemToPDF", (sendEmailFlag) => {
        //     handleOfferExporting(tableInstance.value, sendEmailFlag);
        // });
        const getOfferItemTableInstance = (e) => {
            tableInstance.value = e.component;
            if (!focusedId.value) shouldShowTheDataGrid.value = false;
        };
        const refreshData = async () => {
            const message = "Podaci osvježeni!";
            notify({ message, width: 450 }, "success");
            await lookupStore.fetchLookups(admin.value.domainId);
            lookupData.value = lookupStore.lookupData;
            tableInstance.value.refresh();
        };
        const handleLookupLoading = (e) => {
            lookupData.value = lookupStore.lookupData;
            lookupLoading.value = e;
        };
        const handleNewFocusedRowOffer = async (data) => {
            if (data !== null) {
                focusedId.value = true;
                await getOfferByKey(data);
                await getUserByKey();
                await getBusinessYearByKey();
            } else {
                focusedId.value = false;
            }
            tableInstance.value.refresh();
        };
        const handleExportOffer = (sendEmailFlag) => {
            handleOfferExporting(tableInstance.value, sendEmailFlag);
        };
        const handleNewOfferItemAdded = async (key) => {
            await getOfferByKey(key);
            await getUserByKey();
            await getBusinessYearByKey();
            tableInstance.value.refresh();
        };
        watch(
            () => focusedId.value,
            () => {
                shouldShowTheDataGrid.value = focusedId.value ? true : false;
            }
        );
        watch(
            () => lookupStore.lookupData,
            () => {
                shouldShowTheDataGrid.value =
                    Object.keys(lookupStore.lookupData).length > 0
                        ? true
                        : false;
                lookupData.value = lookupStore.lookupData;
            }
        );
        return {
            refreshButtonOptions,
            customer,
            domain,
            offerItem,
            // onExporting,
            businessYear,
            priceListItem,
            priceTotalFormat,
            shouldShowTheDataGrid,
            getOfferItemTableInstance,
            lookupLoading,
            lookupData
        };
    }
};
</script>
<style scoped>
.table-container {
    margin: 2vh;
    max-width: 100%;
}
#offerItemTable {
    width: 100%;
}
</style>
