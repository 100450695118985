<template>
    <!-- <div class="table-container"> -->
    <div class="table-name">
        <p>Cijenik</p>
        <div style="width: 20%">
            <DxProgressBar
                v-if="domainPermissions.id === 1"
                :read-only="true"
                hint="Iskorišteni limit dokumenata"
                id="progress-bar-status"
                :min="0"
                :max="domainPermissions.priceListCount"
                :value="docCount"
                :status-format="
                    (ration, value) => {
                        return `${value}/${domainPermissions.priceListCount}`;
                    }
                "
            />
            <DxProgressBar
                v-if="domainPermissions.id === 1"
                :read-only="true"
                hint="Iskorišteni limit dokumenata"
                id="progress-bar-status"
                :min="0"
                :max="domainPermissions.priceListItemCount"
                :value="itemCount"
                :status-format="
                    (ration, value) => {
                        return `${value}/${domainPermissions.priceListItemCount}`;
                    }
                "
            />
        </div>
    </div>
    <div id="loading" v-if="lookupLoading" style="margin-top: 10vh"></div>
    <DxDataGrid
        v-if="!lookupLoading"
        id="priceListTable"
        :data-source="priceList"
        :repaint-changes-only="true"
        :show-column-lines="true"
        :show-row-lines="true"
        :show-borders="true"
        :column-auto-width="true"
        :hover-state-enabled="true"
        :focused-row-enabled="true"
        :row-alternation-enabled="true"
        :allow-column-resizing="true"
        @initialized="getPriceListTableInstance"
        @init-new-row="onInitNewRowPriceList"
        @focused-row-changed="onFocusedRowChangedPriceList"
        @row-inserted="onRowInsertedPriceList"
        @row-updating="onRowUpdatingPriceList"
        @row-updated="onRowUpdatedPriceList"
        @edit-canceled="onEditCanceledPriceList"
    >
        <DxPaging :page-size="10" />
        <DxPager
            :visible="true"
            display-mode="adaptive"
            :show-info="true"
            info-text="Stranica {0} od {1} ({2} stavki)"
            :show-navigation-buttons="true"
            :show-page-size-selector="true"
            :allowed-page-sizes="[10, 20, 30]"
        />
        <DxStateStoring
            :enabled="true"
            type="localStorage"
            storage-key="priceListTableState"
        />
        <DxToolbar>
            <DxItem
                :options="addButtonOptions"
                location="after"
                locate-in-menu="auto"
                widget="dxButton"
            />
            <DxItem
                :options="refreshButtonOptions"
                location="after"
                locate-in-menu="auto"
                widget="dxButton"
            />
            <DxItem
                :options="columnChooserButtonOptions"
                location="after"
                locate-in-menu="auto"
                widget="dxButton"
            />
            <DxItem name="searchPanel" location="after" locate-in-menu="auto" />
        </DxToolbar>
        <DxSearchPanel :visible="true" />
        <DxHeaderFilter :visible="true" />
        <DxScrolling column-rendering-mode="virtual" />
        <DxEditing :allow-updating="true" mode="row" :use-icons="true">
            <!-- <DxPopup
                    :show-title="true"
                    title="Dodaj novi cijenik"
                    :drag-outside-boundary="true"
                />
                <DxForm>
                    <DxItem :col-count="2" :col-span="1" item-type="group">
                        <DxItem
                            data-field="id"
                            data-type="number"
                            :visible="false"
                        />
                        <DxItem data-field="name" data-type="string" />
                    </DxItem>
                </DxForm> -->
        </DxEditing>
        <DxColumn
            :width="80"
            data-field="id"
            data-type="number"
            caption="ID"
            sort-order="desc"
            :visible="true"
            :allow-editing="false"
            :allow-exporting="false"
            :allow-header-filtering="false"
        />
        <DxColumn
            data-field="name"
            data-type="string"
            caption="Naziv"
            :allow-hiding="false"
        >
            <DxRequiredRule />
        </DxColumn>
        <DxColumn
            data-field="domainId"
            data-type="number"
            caption="Domena"
            :visible="false"
            :allow-editing="false"
        >
            <DxLookup
                :items="lookupData.domain"
                value-expr="id"
                display-expr="name"
        /></DxColumn>
        <DxColumn
            data-field="createdById"
            data-type="number"
            caption="Kreirao"
            :allow-editing="false"
        >
            <DxLookup
                :items="lookupData.domainUser"
                value-expr="id"
                display-expr="username"
            />
        </DxColumn>
        <DxColumn
            data-field="createdDate"
            data-type="datetime"
            format="dd/MM/yyyy HH:mm"
            caption="Kreirano"
            :allow-editing="false"
        />
        <DxColumn
            data-field="modifiedById"
            data-type="number"
            caption="Uredio"
            :allow-editing="false"
        >
            <DxLookup
                :items="lookupData.domainUser"
                value-expr="id"
                display-expr="username"
            />
        </DxColumn>
        <DxColumn
            data-field="modifiedDate"
            data-type="datetime"
            format="dd/MM/yyyy HH:mm"
            caption="Uređeno"
            :allow-editing="false"
        />
        <DxColumn
            type="buttons"
            :auto-width="true"
            :fixed="true"
            :show-in-column-chooser="false"
        >
            <DxButton
                hint="Uredi"
                icon="edit"
                @click="editPriceList"
                :visible="areEditAndDeleteIconsVisible"
            />
            <DxButton
                hint="Učitaj stavke"
                icon="import"
                @click="importPriceListItems"
                :visible="areEditAndDeleteIconsVisible"
                :disabled="!checkImportRights()"
            />
            <!-- <DxButton
                    hint="Obriši"
                    icon="trash"
                    @click="deletePriceList"
                    :visible="areEditAndDeleteIconsVisible"
                /> -->
            <DxButton
                hint="Spremi"
                icon="check"
                @click="saveNewPriceList"
                :visible="areSaveAndCancelIconsVisible"
            />
            <DxButton
                hint="Odustani"
                icon="close"
                @click="cancelPriceList"
                :visible="areSaveAndCancelIconsVisible"
            />
        </DxColumn>
        <DxColumnChooser :enabled="true" :mode="columnChooserMode">
            <DxColumnChooserSelection
                :allow-select-all="true"
                :select-by-click="true"
                :recursive="true"
            />
        </DxColumnChooser>
    </DxDataGrid>
    <AddPopup
        :resize-enabled="true"
        :drag-outside-boundary="true"
        width="600"
        height="500"
        :toolbarItems="importPriceListItemPopupItems"
        title="Učitaj podatke iz XLS/CSV datoteke"
        @initialized="getImportPriceListItemPopupInstance"
        @hiding="onPopupHiding"
    >
        <div
            style="
                height: 100%;
                width: 100%;
                display: flex;
                align-items: center;
                justify-content: center;
                flex-direction: column;
            "
        >
            <span>Struktura XLS datoteke</span>
            <img
                src="@/assets/example-xlsx.png"
                style="margin-bottom: 3vh; width: 100%"
            />
            <a href="/example-xlsm.xlsm" download style="text-decoration: none"
                >Preuzmi primjer XLSM datoteke
            </a>
            <span style="font-size: 8px; margin-bottom: 1vh">
                *Potrebno je omogućiti izvođenje skripti zbog računanja
                cijena</span
            >
            <a
                href="/example-xlsx.xlsx"
                download
                style="margin-bottom: 1vh; text-decoration: none"
                >Preuzmi primjer XLSX datoteke
            </a>
            <a
                href="/example-csv.csv"
                download
                style="margin-bottom: 1vh; text-decoration: none"
                >Preuzmi primjer CSV datoteke
            </a>
            <DxFileUploader
                accept=".xlsx, .xls, .xlsm, .csv"
                uploadMode="useForm"
                selectButtonText="Odaberi datoteku"
                labelText="ili ispusti ovdje"
                @value-changed="onFileSelection"
                @initialized="getFileUploaderInstance"
            >
            </DxFileUploader>
        </div>
    </AddPopup>
    <DxLoadPanel
        :position="loadingPosition"
        :visible="lookupLoading"
        :show-indicator="true"
        message="Učitavanje..."
    />
    <div class="split" v-if="!lookupLoading"></div>
    <!-- <subscriptionPlansPopupComponent /> -->
    <!-- </div> -->
</template>
<script>
//DevExpress
import {
    DxDataGrid,
    DxColumn,
    DxScrolling,
    DxEditing,
    // DxPopup,
    // DxForm,
    DxItem,
    DxButton,
    DxLookup,
    DxToolbar,
    DxSearchPanel,
    DxHeaderFilter,
    DxStateStoring,
    DxColumnChooser,
    DxColumnChooserSelection,
    DxRequiredRule,
    DxPager,
    DxPaging
} from "devextreme-vue/data-grid";
import notify from "devextreme/ui/notify";
import { DxPopup as AddPopup } from "devextreme-vue/popup";
import { DxFileUploader } from "devextreme-vue/file-uploader";
import { DxLoadPanel } from "devextreme-vue/load-panel";
import readXlsxFile from "read-excel-file";
import { DxProgressBar } from "devextreme-vue/progress-bar";
import Papa from "papaparse";
// import { usePriceListItem } from "@/composables/usePriceListItem.js";
// import subscriptionPlansPopupComponent from "./subscriptionPlansPopupComponent.vue";
import {
    checkSubscriptionLimit,
    checkImportRights
} from "@/utils/checkSubscriptionLimits.js";

//composables
import { usePriceList } from "@/composables/usePriceList.js";

//Utils
import { ref, onMounted, onUnmounted } from "vue";
import eventBus from "../../eventBus.js";
import { useImportItemStore } from "@/stores/customImportItemStore.js";
import { useLookupStore } from "@/stores/customLookupStore.js";

export default {
    name: "priceListTableComponent",
    components: {
        DxProgressBar,
        DxLoadPanel,
        // DxForm,
        DxItem,
        // DxPopup,
        DxPager,
        DxColumn,
        DxButton,
        DxLookup,
        DxPaging,
        AddPopup,
        DxToolbar,
        DxEditing,
        DxDataGrid,
        DxScrolling,
        DxSearchPanel,
        DxHeaderFilter,
        DxFileUploader,
        DxStateStoring,
        DxRequiredRule,
        DxColumnChooser,
        DxColumnChooserSelection
        // subscriptionPlansPopupComponent
    },
    setup() {
        //Data
        const {
            tax,
            domain,
            priceList,
            domainPermissions,
            domainUser,
            admin,
            columnChooserMode,
            handleRowInitPriceList,
            handleRowUpdatingPriceList,
            handleRowUpdatedPriceList,
            handleRowInsertedPriceList
        } = usePriceList();
        // const { priceListItem } = usePriceListItem();
        const lookupStore = useLookupStore();
        const lookupData = ref(null);
        const selectedRowKey = ref(null);
        const uploadedFileData = ref(null);
        const lookupLoading = ref(true);
        const loadingPosition = { of: "#loading" };
        const docCount = ref(null);
        const itemCount = ref(null);

        //Instances
        const tableInstance = ref(null);
        const importPopupInstance = ref(null);
        const fileUploaderInstance = ref(null);

        onMounted(async () => {
            eventBus.on("lookupLoading", handleLookupLoading);
            eventBus.on("checkLimits", handleCheckLimits);
            // console.log(lookupStore.isLoading);
            if (
                !lookupStore.isLoading &&
                Object.keys(lookupStore.lookupData).length > 0 &&
                tableInstance.value
            ) {
                lookupLoading.value = false;
            }
            let res = await checkSubscriptionLimit("priceList");
            docCount.value = res.count;
            res = await checkSubscriptionLimit("priceListItem");
            itemCount.value = res.count;
        });
        onUnmounted(() => {
            eventBus.off("lookupLoading", handleLookupLoading);
            eventBus.off("checkLimits", handleCheckLimits);
        });
        //Button options
        const addButtonOptions = ref({
            icon: "add",
            text: "Dodaj",
            hint: "Novi",
            onClick: () => {
                addNewPriceList();
            }
        });
        const refreshButtonOptions = ref({
            icon: "refresh",
            text: "Osvježi",
            hint: "Osvježi podatke",
            onClick: () => {
                refreshData();
            }
        });
        const columnChooserButtonOptions = ref({
            icon: "columnchooser",
            text: "Stupci",
            hint: "Odaberi stupce za prikaz",
            onClick: () => {
                openColumnChooser();
            }
        });
        const importPriceListItemPopupItems = ref([
            {
                widget: "dxButton",
                location: "after",
                toolbar: "bottom",
                options: {
                    text: "Spremi",
                    type: "success",
                    icon: "check",
                    onClick: () => savePriceListItems()
                }
            },
            {
                widget: "dxButton",
                location: "after",
                toolbar: "bottom",
                options: {
                    text: "Odustani",
                    type: "danger",
                    icon: "remove",
                    onClick: () => cancelPriceListItems()
                }
            }
        ]);

        //Instances
        const getPriceListTableInstance = (e) => {
            tableInstance.value = e.component;
        };
        const getImportPriceListItemPopupInstance = (e) => {
            importPopupInstance.value = e.component;
        };
        const getFileUploaderInstance = (e) => {
            fileUploaderInstance.value = e.component;
        };

        //price list table methods
        const onFocusedRowChangedPriceList = (e) => {
            if (e.row) {
                selectedRowKey.value =
                    typeof e.row.key !== "string" ? e.row.data.id : null;
                eventBus.emit("newFocusedRowPriceList", selectedRowKey.value);
            } else {
                eventBus.emit("newFocusedRowPriceList", null);
            }
        };
        const addNewPriceList = async () => {
            const res = await checkSubscriptionLimit("priceList");
            if (res.limitReached) {
                eventBus.emit("openPlans");
            } else {
                tableInstance.value.addRow();
            }
        };
        const editPriceList = (e) => {
            tableInstance.value.editRow(e.row.rowIndex);
        };
        const cancelPriceList = () => {
            tableInstance.value.cancelEditData();
        };
        const deletePriceList = (e) => {
            tableInstance.value.deleteRow(e.row.rowIndex);
        };
        const onInitNewRowPriceList = (e) => {
            tableInstance.value.option("focusedRowIndex", 0);
            handleRowInitPriceList(e);
        };
        const saveNewPriceList = () => {
            tableInstance.value.saveEditData();
        };
        const onRowInsertedPriceList = async (e) => {
            lookupLoading.value = true;
            await handleRowInsertedPriceList(e);
            const res = await checkSubscriptionLimit("priceList");
            docCount.value = res.count;
            lookupLoading.value = false;
        };
        const onEditCanceledPriceList = () => {
            tableInstance.value.refresh();
        };
        const onRowUpdatingPriceList = (e) => {
            handleRowUpdatingPriceList(e);
        };
        const onRowUpdatedPriceList = async (e) => {
            await handleRowUpdatedPriceList(e);
            eventBus.emit("newFocusedRowPriceList", e.key);
        };

        const onFileSelection = async (e) => {
            const now = new Date().toISOString();
            const allTaxes = await tax.value.load();
            const schema = {
                name: {
                    prop: "name",
                    type: String
                },
                code: {
                    prop: "code",
                    type: String
                },
                measure_unit: {
                    prop: "measureUnit",
                    type: String
                },
                vat: {
                    prop: "vat",
                    type: (value) => parseFloat(parseFloat(value).toFixed(2))
                },
                item_type: {
                    prop: "itemType",
                    type: String
                },
                wholesale_price: {
                    prop: "wholesalePrice",
                    type: (value) => parseFloat(parseFloat(value).toFixed(2))
                },
                vat_amount: {
                    prop: "vatAmount",
                    type: (value) => parseFloat(parseFloat(value).toFixed(2))
                },
                retail_price: {
                    prop: "retailPrice",
                    type: (value) => parseFloat(parseFloat(value).toFixed(2))
                }
            };

            const file = e.value[0];
            if (file) {
                try {
                    let fileData = [];
                    if (
                        file.name.endsWith(".xlsx") ||
                        file.name.endsWith(".xlsm")
                    ) {
                        const rows = await readXlsxFile(file, { schema });
                        fileData = rows.rows;
                    } else if (file.name.endsWith(".csv")) {
                        const csvText = await file.text();
                        Papa.parse(csvText, {
                            header: true,
                            complete: (result) => {
                                fileData = result.data.map((row) => {
                                    const transformedRow = {};
                                    for (const [key, value] of Object.entries(
                                        schema
                                    )) {
                                        if (value.prop && value.type) {
                                            transformedRow[value.prop] =
                                                typeof value.type === "function"
                                                    ? value.type(row[key])
                                                    : row[key];
                                        }
                                    }
                                    return transformedRow;
                                });
                            },
                            error: (error) => {
                                throw error;
                            }
                        });
                    } else {
                        throw new Error("Unsupported file type");
                    }
                    uploadedFileData.value = fileData;
                    uploadedFileData.value.forEach((item) => {
                        const matchingTax = allTaxes.find(
                            (tax) => tax.taxValue === item.vat
                        );
                        if (matchingTax) {
                            item.vatId = matchingTax.id;
                        }
                        item.createdDate = now;
                        item.modifiedDate = now;
                        item.createdById = admin.value.id;
                        item.modifiedById = admin.value.id;
                        item.domainId = admin.value.domainId;
                        item.priceListId = selectedRowKey.value;
                        item.priceCalculationType = "Fiksna cijena";
                        item.hidden = 0;
                    });
                } catch (error) {
                    console.error("Error reading file:", error);
                }
            }
        };
        const savePriceListItems = async () => {
            if (uploadedFileData.value !== null) {
                const isValidItem = (item) => {
                    return (
                        Object.hasOwn(item, "code") &&
                        typeof item.code === "string" &&
                        Object.hasOwn(item, "createdById") &&
                        typeof item.createdById === "number" &&
                        Object.hasOwn(item, "createdDate") &&
                        typeof item.createdDate === "string" &&
                        Object.hasOwn(item, "domainId") &&
                        typeof item.domainId === "number" &&
                        Object.hasOwn(item, "hidden") &&
                        typeof item.hidden === "number" &&
                        Object.hasOwn(item, "itemType") &&
                        typeof item.itemType === "string" &&
                        Object.hasOwn(item, "measureUnit") &&
                        typeof item.measureUnit === "string" &&
                        Object.hasOwn(item, "modifiedById") &&
                        typeof item.modifiedById === "number" &&
                        Object.hasOwn(item, "modifiedDate") &&
                        typeof item.modifiedDate === "string" &&
                        Object.hasOwn(item, "name") &&
                        typeof item.name === "string" &&
                        Object.hasOwn(item, "priceCalculationType") &&
                        typeof item.priceCalculationType === "string" &&
                        Object.hasOwn(item, "priceListId") &&
                        typeof item.priceListId === "number" &&
                        Object.hasOwn(item, "retailPrice") &&
                        typeof item.retailPrice === "number" &&
                        Object.hasOwn(item, "vat") &&
                        typeof item.vat === "number" &&
                        Object.hasOwn(item, "vatAmount") &&
                        typeof item.vatAmount === "number" &&
                        Object.hasOwn(item, "vatId") &&
                        typeof item.vatId === "number" &&
                        Object.hasOwn(item, "wholesalePrice") &&
                        typeof item.wholesalePrice === "number"
                    );
                };
                const filteredItems =
                    uploadedFileData.value.filter(isValidItem);
                if (filteredItems.length === 0) {
                    notify(
                        {
                            message:
                                "Nisu pronađene stavke koje odgovaraju strukturi. Provjerite podatke.",
                            width: 450,
                            closeOnClick: true
                        },
                        "warning",
                        5000
                    );
                    return;
                }
                const importStore = useImportItemStore();
                try {
                    const payload = {
                        items: filteredItems
                    };
                    const res = await importStore.importPriceListItems(payload);
                    // console.log(res);
                    if (res.success) {
                        notify(
                            {
                                message: res.message,
                                width: 450,
                                closeOnClick: true
                            },
                            "success",
                            10000
                        );
                        uploadedFileData.value = null;
                        fileUploaderInstance.value.clear();
                        importPopupInstance.value.hide();
                        // eventBus.emit("newPriceListItemAdded");
                        eventBus.emit(
                            "newFocusedRowPriceList",
                            selectedRowKey.value
                        );
                    } else {
                        notify(
                            {
                                message: res.message,
                                width: 450,
                                closeOnClick: true
                            },
                            "error",
                            10000
                        );
                        throw new Error(res.message || "Error updating items.");
                    }
                } catch (error) {
                    console.error("Error inserting data:", error);
                    notify(
                        {
                            message: error.message,
                            width: 450,
                            closeOnClick: true
                        },
                        "error",
                        10000
                    );
                }
            }
        };
        const cancelPriceListItems = () => {
            importPopupInstance.value.hide();
        };

        const handleLookupLoading = (e) => {
            lookupData.value = lookupStore.getLookupData;
            lookupLoading.value = e;
        };
        //button methods
        const refreshData = async () => {
            lookupLoading.value = true;
            const message = "Podaci osvježeni!";
            tableInstance.value.refresh();
            await lookupStore.fetchLookups(admin.value.domainId);
            lookupLoading.value = false;
            notify({ message, width: 450 }, "success");
        };
        const handleCheckLimits = async () => {
            const res = await checkSubscriptionLimit("priceListItem");
            itemCount.value = res.count;
        };
        const openColumnChooser = () => {
            tableInstance.value.showColumnChooser();
        };
        const importPriceListItems = () => {
            importPopupInstance.value.show();
        };
        const onPopupHiding = () => {
            fileUploaderInstance.value.clear();
        };
        const areEditAndDeleteIconsVisible = (e) => {
            return !e.row.isEditing ? true : false;
        };
        const areSaveAndCancelIconsVisible = (e) => {
            return e.row.isEditing ? true : false;
        };
        return {
            lookupLoading,
            loadingPosition,
            lookupData,
            domain,
            priceList,
            domainUser,
            editPriceList,
            cancelPriceList,
            deletePriceList,
            addButtonOptions,
            saveNewPriceList,
            columnChooserMode,
            refreshButtonOptions,
            onInitNewRowPriceList,
            onRowUpdatingPriceList,
            onEditCanceledPriceList,
            getPriceListTableInstance,
            columnChooserButtonOptions,
            onFocusedRowChangedPriceList,
            areSaveAndCancelIconsVisible,
            areEditAndDeleteIconsVisible,
            importPriceListItemPopupItems,
            importPriceListItems,
            getImportPriceListItemPopupInstance,
            onFileSelection,
            getFileUploaderInstance,
            onPopupHiding,
            onRowInsertedPriceList,
            onRowUpdatedPriceList,
            checkImportRights,
            docCount,
            domainPermissions,
            itemCount
        };
    }
};
</script>
<style scoped>
.table-container {
    margin: 1vh;
    max-width: 100%;
}
#priceListTable {
    max-width: 100%;
}
.table-name {
    font-size: 1.6rem;
    text-align: center;
    margin: 1vh;
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.split {
    border: 0.2px solid #1c1c1c;
    margin: 0.5vh;
    /* width: 84.5vw; */
    max-width: 100vw;
}
</style>
