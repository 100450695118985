import { defineStore } from "pinia";
import config from "../../config.json";
import { sendRequest } from "@/helpers/sendRequestHelper";

export const useSudregApiStore = defineStore("sudregApiStore", {
    actions: {
        async fetchCompanyData(values) {
            try {
                const res = await sendRequest(
                    `${config.API_URL}/${config.PATHS.FETCH_COMPANY_DATA}`,
                    "POST",
                    {
                        values: values
                    }
                );
                return res;
            } catch (error) {
                console.error(error);
                throw error;
            }
        }
    }
});
