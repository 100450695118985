import { defineStore } from "pinia";
import config from "../../config.json";
import { sendRequest } from "@/helpers/sendRequestHelper";

export const useImportItemStore = defineStore("importItemStore", {
    actions: {
        async importInitialStateItems(values) {
            try {
                const res = await sendRequest(
                    `${config.API_URL}/${config.PATHS.IMPORT_INITIAL_STATE_ITEMS}`,
                    "POST",
                    {
                        values: values
                    }
                );
                return res;
            } catch (error) {
                console.error(error);
                throw error;
            }
        },
        async importPriceListItems(values) {
            try {
                const res = await sendRequest(
                    `${config.API_URL}/${config.PATHS.IMPORT_PRICE_LIST_ITEMS}`,
                    "POST",
                    {
                        values: values
                    }
                );
                return res;
            } catch (error) {
                console.error(error);
                throw error;
            }
        }
    }
});
