import { useDomainUserStore } from "@/stores/customDomainUserStore";
import { sendRequest } from "@/helpers/sendRequestHelper";
import { customer } from "@/stores/customerStore";
import { supplier } from "@/stores/supplierStore";
import { priceList } from "@/stores/priceListStore";
import { priceListItem } from "@/stores/priceListItemStore";
import { warehouse } from "@/stores/warehouseStore";
import config from "../../config.json";
import { invoice } from "@/stores/invoiceStore";
import { offer } from "@/stores/offerStore";
import { initialState } from "@/stores/initialStateStore";
import { receipt } from "@/stores/receiptStore";
import { dispatchNote } from "@/stores/dispatchNoteStore";
import { intermediateWarehouse } from "@/stores/intermediateWarehouseStore";

// ✅
export async function checkSubscriptionLimit(document) {
    const domainUserStore = useDomainUserStore();
    const admin = domainUserStore.getDomainUser;
    const domainPermissions = domainUserStore.getDomainPermissions;
    let count;
    switch (document) {
        case "customer": {
            count = await customer.totalCount(admin.domainId);
            return domainPermissions.customerCount === -1 ||
                count < domainPermissions.customerCount
                ? { limitReached: false, count }
                : { limitReached: true, count };
        }
        case "supplier": {
            count = await supplier.totalCount(admin.domainId);
            return domainPermissions.supplierCount === -1 ||
                count < domainPermissions.supplierCount
                ? { limitReached: false, count }
                : { limitReached: true, count };
        }
        case "priceList": {
            count = await priceList.totalCount(admin.domainId);
            return domainPermissions.priceListCount === -1 ||
                count < domainPermissions.priceListCount
                ? { limitReached: false, count }
                : { limitReached: true, count };
        }
        case "priceListItem": {
            count = await priceListItem.totalCount(admin.domainId);
            return domainPermissions.priceListItemCount === -1 ||
                count < domainPermissions.priceListItemCount
                ? { limitReached: false, count }
                : { limitReached: true, count };
        }
        case "warehouse": {
            count = await warehouse.totalCount(admin.domainId);
            return domainPermissions.warehouseCount === -1 ||
                count < domainPermissions.warehouseCount
                ? { limitReached: false, count }
                : { limitReached: true, count };
        }
        case "initialState": {
            count = await initialState.totalCount(admin.domainId);
            return domainPermissions.initialStateCount === -1 ||
                count < domainPermissions.initialStateCount
                ? { limitReached: false, count }
                : { limitReached: true, count };
        }
        case "receipt": {
            count = await receipt.totalCount(admin.domainId);
            return domainPermissions.receiptCount === -1 ||
                count < domainPermissions.receiptCount
                ? { limitReached: false, count }
                : { limitReached: true, count };
        }
        case "dispatchNote": {
            count = await dispatchNote.totalCount(admin.domainId);
            return domainPermissions.dispatchNoteCount === -1 ||
                count < domainPermissions.dispatchNoteCount
                ? { limitReached: false, count }
                : { limitReached: true, count };
        }
        case "intermediateWarehouse": {
            count = await intermediateWarehouse.totalCount(admin.domainId);
            return domainPermissions.intermediateWarehouseCount === -1 ||
                count < domainPermissions.intermediateWarehouseCount
                ? { limitReached: false, count }
                : { limitReached: true, count };
        }
        case "invoice": {
            count = await invoice.totalCount(admin.domainId);
            const invoiceSum = await sendRequest(
                `${config.API_URL}/permissions/invoice-sum`,
                "POST",
                {
                    values: {
                        businessYearId:
                            Number(localStorage.getItem("businessYearId")) ||
                            15,
                        domainId: admin.domainId
                    }
                }
            );
            const sumLimit =
                domainPermissions.invoiceSum === -1 ||
                invoiceSum < domainPermissions.invoiceSum
                    ? false
                    : true;
            const countLimit =
                domainPermissions.invoiceCount === -1 ||
                count < domainPermissions.invoiceCount
                    ? false
                    : true;
            return !sumLimit && !countLimit
                ? { limitReached: false, count, invoiceSum }
                : { limitReached: true, count, invoiceSum };
        }
        case "offer": {
            count = await offer.totalCount(admin.domainId);
            return domainPermissions.offerCount === -1 ||
                count < domainPermissions.offerCount
                ? { limitReached: false, count }
                : { limitReached: true, count };
        }
    }
}
// ✅
export function checkImportRights() {
    const domainUserStore = useDomainUserStore();
    const domainPermissions = domainUserStore.getDomainPermissions;
    return domainPermissions.import ? true : false;
}
// ✅
export function checkWatermark() {
    const domainUserStore = useDomainUserStore();
    const domainPermissions = domainUserStore.getDomainPermissions;
    return domainPermissions.watermark ? true : false;
}
// ✅
export function checkRecapitulationRights() {
    const domainUserStore = useDomainUserStore();
    const domainPermissions = domainUserStore.getDomainPermissions;
    return domainPermissions.recapitulation ? true : false;
}
// ✅
export function checkUserCountLimit(count) {
    const domainUserStore = useDomainUserStore();
    const domainPermissions = domainUserStore.getDomainPermissions;
    return domainPermissions.userCount === -1 ||
        count < domainPermissions.userCount
        ? false
        : true;
}
// 🛠️
export function checkOpenInvoiceExportRights() {
    const domainUserStore = useDomainUserStore();
    const domainPermissions = domainUserStore.getDomainPermissions;
    return domainPermissions.openInvoiceExport ? true : false;
}
// 🛠️
export function checkAutomaticNoticeRights() {
    const domainUserStore = useDomainUserStore();
    const domainPermissions = domainUserStore.getDomainPermissions;
    return domainPermissions.automaticNotice ? true : false;
}
