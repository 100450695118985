<template>
    <router-view />
</template>

<script>
export default {
    name: "App"
};
</script>

<style>
@import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");
html {
    font-size: 14px;
}
* {
    font-family: "Poppins", sans-serif;
}
html {
    padding: 0;
    margin: 0;
    scrollbar-width: none;
}
html::-webkit-scrollbar {
    display: none;
}
body {
    width: 100vw;
    padding: 0;
    margin: 0;
}
/* For Webkit browsers (Chrome, Safari, Edge) */
::-webkit-scrollbar {
    width: 6px; /* Horizontal scrollbar */
    height: 6px; /* Vertical scrollbar */
}

::-webkit-scrollbar-track {
    background: #f1f1f1; /* Light background for the track */
}

::-webkit-scrollbar-thumb {
    background: #c2c2c2; /* Color of the thumb */
    border-radius: 10px; /* Rounded edges */
}

::-webkit-scrollbar-thumb:hover {
    background: #a1a1a1; /* Darker color when hovered */
}

/* For Firefox */
scrollbar-width: thin; /* Thin scrollbar */
scrollbar-color: #888 #f1f1f1; /* Thumb and track colors */
</style>
