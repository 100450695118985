import { defineStore } from "pinia";
import config from "../../config.json";
import { sendRequest } from "@/helpers/sendRequestHelper";

export const useLookupStore = defineStore("lookup", {
    state: () => ({
        currentDocumentId: null,
        lookupData: {},
        isLoading: false
    }),
    getters: {
        getLookupData: (state) => state.lookupData,
        getCurrentDocument: (state) => state.currentDocumentId
    },
    actions: {
        async fetchLookups(domainId, documentId = null) {
            this.lookupData = {};
            this.isLoading = true;
            this.currentDocumentId =
                documentId !== null ? documentId : this.currentDocumentId;

            const filterCondition = {
                domainId: domainId,
                documentId: this.currentDocumentId,
                businessYearId: Number(
                    localStorage.getItem("businessYearId") || 15
                )
            };

            try {
                const response = await sendRequest(
                    `${config.API_URL}/${
                        config.PATHS.FETCH_LOOKUP_DATA
                    }?filter=${JSON.stringify(filterCondition)}`
                );
                this.lookupData = response.data;
                return response;
            } finally {
                this.isLoading = false;
            }
        }
    }
});
