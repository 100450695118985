<template>
    <div class="dx-list-container">
        <div class="logo-container">
            <img
                class="logo-img"
                :src="logoSrc"
                width="90"
                height="90"
                @click="goHome"
                title="Home"
            />
        </div>
        <DxList
            :data-source="navigation"
            :hover-state-enabled="true"
            :on-item-click="selectedItem"
            show-scrollbar="onScroll"
            class="panel-list"
            :grouped="true"
            :collapsible-groups="true"
        />
        <div class="user-container">
            <div
                style="
                    text-align: center;
                    border-radius: 5px;
                    margin-bottom: 2vh;
                    width: 100%;
                    padding: 1px;
                    background-color: rgba(228, 208, 10, 0.2);
                    border: 1px solid #e4d00a;
                "
            >
                <p style="margin: 2px">Plan: {{ domainSubscriptionName }}</p>
                <p style="margin: 2px" v-if="domainSubscriptionName !== 'free'">
                    Aktivan do: {{ subscriptionEndISO }}
                </p>
                <button
                    style="
                        background: none;
                        border: none;
                        color: goldenrod;
                        cursor: pointer;
                    "
                    v-if="domainSubscriptionName === 'free'"
                    @click="
                        () => {
                            eventBus.emit('openPlans');
                        }
                    "
                >
                    Nadogradi
                </button>
            </div>
            <img src="@/assets/person.svg" class="user-icon" alt="" />
            <span class="user-name"> {{ adminUsername }} </span>

            <DxButton
                :width="100"
                text="ODJAVA"
                type="danger"
                styling-mode="contained"
                @click="logout"
            />
            <p>v{{ globalData.version }}</p>
        </div>
    </div>
</template>
<script setup>
import { DxList } from "devextreme-vue/list";
import DxButton from "devextreme-vue/button";
import eventBus from "../../eventBus.js";
import { useRouter } from "vue-router";
import notify from "devextreme/ui/notify";
import { useDomainUserStore } from "@/stores/customDomainUserStore.js";
import { ref, onMounted } from "vue";
import { rolePrivileges } from "@/stores/rolePrivilegesStore.js";
import { useLookupStore } from "@/stores/customLookupStore.js";
import { domain } from "@/stores/domainStore.js";
import globalData from "../../package.json";
const router = useRouter();
const domainUserStore = useDomainUserStore();
const lookupStore = useLookupStore();
const admin = domainUserStore.getDomainUser;
const adminUsername = admin.username;
let selectedItemId = null;
const navigation = ref([]);
const adminPrivileges = ref([]);
const logoSrc = ref(null);
const domainPermissions = ref(null);
const domainSubscriptionId = ref(null);
const domainSubscriptionName = ref(null);
const diffInWholeDays = ref(null);
const subscriptionEndISO = ref(null);
eventBus.on("themeChanged", (theme) => {
    if (theme === "generic.light" || theme === "generic.light.compact") {
        logoSrc.value = "logo-minierp.svg";
        document.documentElement.setAttribute("data-theme", theme);
    } else {
        logoSrc.value = "logo-minierp-bijeli.svg";
        document.documentElement.setAttribute("data-theme", theme);
    }
});
onMounted(async () => {
    const currentTheme = localStorage.getItem("dx-theme") || "generic.light";
    logoSrc.value =
        currentTheme === "generic.light" ||
        currentTheme === "generic.light.compact"
            ? "logo-minierp.svg"
            : "logo-minierp-bijeli.svg";
    document.documentElement.setAttribute("data-theme", currentTheme);
    try {
        domainPermissions.value = domainUserStore.getDomainPermissions;
        domainSubscriptionId.value = domainPermissions.value.id;
        domainSubscriptionName.value = domainPermissions.value.name;
        const domainData = await domain.byKey(admin.domainId);
        const subscriptionBegin = new Date(domainData.subscriptionBegin);
        const subscriptionEnd = new Date(domainData.subscriptionEnd);
        subscriptionEndISO.value = domainData.subscriptionEnd
            ? subscriptionEnd.toLocaleDateString("hr")
            : "-";
        const diffInMs = subscriptionEnd - subscriptionBegin;
        diffInWholeDays.value = Math.floor(diffInMs / (1000 * 60 * 60 * 24));
        if (domainData.id === 1) {
            diffInWholeDays.value = "-";
        }
        adminPrivileges.value = await rolePrivileges.load({
            roleId: admin.roleId
        });
        let parsedPrivileges = [];
        if (adminPrivileges.value?.[0]?.privilegeId) {
            parsedPrivileges = JSON.parse(adminPrivileges.value[0].privilegeId);
            if (!Array.isArray(parsedPrivileges)) {
                throw new Error("Parsed privileges is not an array");
            }
        }
        const rawNavigation = [
            {
                key: "Prodaja",
                items: [
                    { id: 9, text: "Ponuda", icon: "box" },
                    { id: 10, text: "Račun", icon: "box" }
                ]
            },
            // {
            //     key: "E-Račun",
            //     items: [
            //         { id: 11, text: "Izlazni", icon: "export" }
            //         // { id: 12, text: "Ulazni", icon: "import" }
            //     ]
            // },
            {
                key: "Materijalno računovodstvo",
                items: [
                    { id: 1, text: "Početno stanje", icon: "fields" },
                    { id: 2, text: "Primka", icon: "arrowdown" },
                    { id: 3, text: "Otpremnica", icon: "arrowup" },
                    { id: 4, text: "Međuskladišnica", icon: "arrowright" },
                    { id: 5, text: "Skladište", icon: "home" }
                ]
            },
            {
                key: "Osnovni podaci",
                items: [
                    { id: 6, text: "Cijenik", icon: "textdocument" },
                    { id: 7, text: "Dobavljači", icon: "group" },
                    { id: 8, text: "Kupci", icon: "group" },
                    { id: 11, text: "E-mail log", icon: "email" }
                ]
            },
            {
                key: "Postavke",
                items: [{ text: "Postavke", icon: "preferences" }]
            }
        ];
        navigation.value = rawNavigation
            .map((category) => {
                if (category.key === "Postavke") {
                    return category;
                }
                return {
                    ...category,
                    items: category.items.filter((item) =>
                        parsedPrivileges.includes(item.id)
                    )
                };
            })
            .filter(
                (category) =>
                    category.items.length > 0 || category.key === "Postavke"
            );
    } catch (error) {
        console.error("Failed to load privileges:", error);
    }
});
const selectedItem = async (e) => {
    router.push({ name: e.itemData.text });
    if (
        e.itemData.id &&
        e.itemData.id !== 8 &&
        e.itemData.id !== 11 &&
        selectedItemId !== e.itemData.id
    ) {
        selectedItemId = e.itemData.id;
        try {
            const res = await lookupStore.fetchLookups(
                admin.domainId,
                e.itemData.id
            );
            if (res.success) {
                eventBus.emit("lookupLoading", false);
            } else {
                notify(
                    {
                        message: res.message,
                        width: 450
                    },
                    "error",
                    2000
                );
            }
        } catch (e) {
            console.error(e);
        }
    }
    if (!e.itemData.id) {
        selectedItemId = null;
    }
};
const goHome = () => {
    eventBus.emit("tableToRender", "Home");
    router.push({ name: "home" });
};
const logout = async () => {
    const message = "Odjavljeni ste.";
    notify(
        {
            message,
            width: 450
        },
        "success",
        2000
    );
    selectedItemId = null;
    await domainUserStore.logout();
    router.push({ name: "login" });
};
</script>
<style>
:root {
    --mobile-bg-color: #ffffff;
}

[data-theme="generic.dark"] {
    --mobile-bg-color: rgb(59, 70, 76);
}
[data-theme="generic.dark.compact"] {
    --mobile-bg-color: rgb(59, 70, 76);
}
[data-theme="generic.light"] {
    --mobile-bg-color: #ffffff;
}
[data-theme="generic.light.compact"] {
    --mobile-bg-color: #ffffff;
}

.panel-list {
    /* padding-top: 60px; */
    max-height: 70dvh !important;
    overflow-y: auto !important;
    /* padding-bottom: 100px; */
    background-color: var(--dx-color-main-bg);
}
.dx-list-container {
    margin-top: 4vh;
    width: 15vw;
    /* height: 85dvh; */
    overflow-y: auto;
    margin-bottom: 10dvh;
}
.user-icon {
    width: 30px;
}
.user-name {
    /* color: #000000; */
    font-size: 1.4rem;
}
.user {
    margin: 0 1vw 1vh 1vw;
    display: flex;
    align-items: flex-end;
    justify-content: center;
}
.logout {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    margin: 0 auto 1vw auto;
}
.user-container {
    position: absolute;
    bottom: 0;
    left: 0;
    display: flex;
    flex-direction: column;
    /* margin: 2vh auto; */
    width: 15vw;
    align-items: center;
}
@media screen and (max-width: 1024px) {
    .dx-list-container {
        height: 100vh;
        width: 100vw;
        margin-top: 0;
        background-color: var(--mobile-bg-color);
    }
    .panel-list {
        background-color: var(--mobile-bg-color);
        width: 100vw;
        max-height: 70vh !important;
        padding-top: 30px;
    }
    .logo-container {
        visibility: hidden;
        height: 0;
        overflow: hidden;
    }
    .user-container {
        position: absolute;
        bottom: 0;
        left: 0;
        right: 0;
        width: 100vw;
        margin: 1dvh auto;
    }
}
@media screen and (max-height: 1024px) and (min-width: 768px) {
    .panel-list {
        max-height: 50dvh !important;
    }
}
.logo-img {
    cursor: pointer;
}
.logo-container {
    margin: 2vh auto 0 auto;
    text-align: center;
}
</style>
