<template>
    <DxPopup
        id="popupPanel"
        :resize-enabled="true"
        :hide-on-outside-click="true"
        @initialized="getSubscriptionPlanPopupInstance"
    >
        <h3 style="margin: 2px; font-weight: bold; text-align: center">
            {{ limitHeader }}
        </h3>
        <DxTabPanel
            :animation-enabled="true"
            :focus-state-enabled="false"
            :show-nav-buttons="true"
        >
            <DxItem title="Mjesečni plan" icon="description">
                <div class="sub-parent">
                    <div
                        v-for="plan in monthlyPlans"
                        :key="plan.id"
                        :class="['plan-panel', plan.color]"
                        :ref="'planPanel' + plan.id"
                        :style="{
                            transition:
                                'width 0.3s ease, opacity 0.3s ease, transform 0.3s ease',
                            width: plan.expanded ? '100%' : '45%'
                        }"
                    >
                        <h2 style="margin: 2px; font-weight: bold">
                            {{ plan.name }}
                        </h2>
                        <h3 style="margin: 2px">{{ plan.price }}</h3>
                        <div
                            v-if="!plan.expanded"
                            style="text-align: left; padding: 2vw 3vw 0 3vw"
                        >
                            <p style="font-weight: bold">
                                Broj tvrtki/korisnika
                            </p>
                            <ul>
                                <li v-for="item in plan.users" :key="item">
                                    {{ item }}
                                </li>
                            </ul>
                            <p style="font-weight: bold">Mogućnosti</p>
                            <ul>
                                <li v-for="item in plan.features" :key="item">
                                    {{ item }}
                                </li>
                            </ul>
                            <p
                                style="font-weight: bold"
                                v-if="plan.limitations.length > 0"
                            >
                                Ograničenja
                            </p>
                            <ul>
                                <li
                                    v-for="item in plan.limitations"
                                    :key="item"
                                >
                                    {{ item }}
                                </li>
                            </ul>
                            <p style="font-weight: bold">Dodatno</p>
                            <ul>
                                <li v-for="item in plan.additional" :key="item">
                                    {{ item }}
                                </li>
                            </ul>
                        </div>
                        <div
                            v-if="plan.expanded && !plan.creditCard"
                            style="
                                display: flex;
                                align-items: center;
                                justify-content: center;
                                flex-direction: column;
                                height: 90%;
                            "
                        >
                            <!-- <button
                                class="choose-button-basic"
                                @click="wireTransferMonthly"
                            >
                                Uplata na račun
                            </button> -->
                            <form
                                style="width: 100%"
                                method="POST"
                                :action="wsPayRoute"
                            >
                                <input
                                    type="hidden"
                                    name="ShopID"
                                    :value="WSPayData.ShopID"
                                />
                                <input
                                    type="hidden"
                                    name="ShoppingCartID"
                                    :value="WSPayData.ShoppingCartID"
                                />
                                <input
                                    type="hidden"
                                    name="Version"
                                    :value="WSPayData.Version"
                                />
                                <input
                                    type="hidden"
                                    name="TotalAmount"
                                    :value="WSPayData.TotalAmount"
                                />
                                <input
                                    type="hidden"
                                    name="Signature"
                                    :value="WSPayData.Signature"
                                />
                                <input
                                    type="hidden"
                                    name="ReturnURL"
                                    :value="WSPayData.ReturnURL"
                                />
                                <input
                                    type="hidden"
                                    name="CancelURL"
                                    :value="WSPayData.CancelURL"
                                />
                                <input
                                    type="hidden"
                                    name="ReturnErrorURL"
                                    :value="WSPayData.ReturnErrorURL"
                                />
                                <!-- <input type="hidden" name="IsTokenRequest" value="1" /> -->
                                <button
                                    type="submit"
                                    class="credit-card-button"
                                >
                                    Plati kreditnom kraticom (Online)
                                </button>
                            </form>
                        </div>
                        <div
                            v-if="plan.creditCard"
                            style="
                                display: flex;
                                align-items: center;
                                justify-content: center;
                                flex-direction: column;
                                height: 90%;
                            "
                        ></div>
                        <div
                            style="
                                margin-top: 3vh;
                                width: 100%;
                                position: absolute;
                                bottom: 0;
                                left: 0;
                            "
                        >
                            <button
                                :class="
                                    'choose-button-' + plan.name.toLowerCase()
                                "
                                @click="choosePlanMonthly(plan)"
                                v-if="
                                    domainSubscriptionId !== plan.id &&
                                    !plan.expanded &&
                                    plan.id !== 1
                                "
                            >
                                <p style="margin: 0">Odaberi</p>
                            </button>
                            <button
                                :class="
                                    'choose-button-' + plan.name.toLowerCase()
                                "
                                @click="unChoosePlanMonthly()"
                                v-if="
                                    domainSubscriptionId !== plan.id &&
                                    plan.expanded &&
                                    !plan.creditCard
                                "
                            >
                                <p style="margin: 0">Natrag</p>
                            </button>
                            <button
                                :class="
                                    'choose-button-' + plan.name.toLowerCase()
                                "
                                @click="unChoosePlanCreditMonthly()"
                                v-if="
                                    domainSubscriptionId !== plan.id &&
                                    plan.expanded &&
                                    plan.creditCard
                                "
                            >
                                <p style="margin: 0">Natrag</p>
                            </button>
                            <button
                                class="current-plan"
                                disabled
                                v-if="
                                    domainSubscriptionId === plan.id &&
                                    domainSubscriptionId !== 1
                                "
                            >
                                <p style="margin: 0">Trenutni plan</p>
                            </button>
                        </div>
                    </div>
                </div>
            </DxItem>
            <DxItem title="Godišnji plan" icon="description">
                <div class="sub-parent">
                    <div
                        v-for="plan in yearlyPlans"
                        :key="plan.id"
                        :class="['plan-panel', plan.color]"
                        :ref="'planPanel' + plan.id"
                        :style="{
                            transition:
                                'width 0.3s ease, opacity 0.3s ease, transform 0.3s ease',
                            width: plan.expanded ? '100%' : '45%'
                        }"
                    >
                        <h2 style="margin: 2px; font-weight: bold">
                            {{ plan.name }}
                        </h2>
                        <h3 style="margin: 2px">{{ plan.price }}</h3>
                        <h3 style="margin: 2px">{{ plan.priceMonthly }}</h3>
                        <div
                            v-if="!plan.expanded"
                            style="text-align: left; padding: 2vw 3vw 0 3vw"
                        >
                            <p style="font-weight: bold">
                                Broj tvrtki/korisnika
                            </p>
                            <ul>
                                <li v-for="item in plan.users" :key="item">
                                    {{ item }}
                                </li>
                            </ul>
                            <p style="font-weight: bold">Mogućnosti</p>
                            <ul>
                                <li v-for="item in plan.features" :key="item">
                                    {{ item }}
                                </li>
                            </ul>
                            <p
                                style="font-weight: bold"
                                v-if="plan.limitations.length > 0"
                            >
                                Ograničenja
                            </p>
                            <ul>
                                <li
                                    v-for="item in plan.limitations"
                                    :key="item"
                                >
                                    {{ item }}
                                </li>
                            </ul>
                            <p style="font-weight: bold">Dodatno</p>
                            <ul>
                                <li v-for="item in plan.additional" :key="item">
                                    {{ item }}
                                </li>
                            </ul>
                        </div>
                        <div
                            v-if="plan.expanded && !plan.creditCard"
                            style="
                                display: flex;
                                align-items: center;
                                justify-content: center;
                                flex-direction: column;
                                height: 90%;
                            "
                        >
                            <!-- <button
                                class="choose-button-basic"
                                @click="wireTransferYearly"
                            >
                                Uplata na račun
                            </button> -->
                            <form
                                style="width: 100%"
                                method="POST"
                                :action="wsPayRoute"
                            >
                                <input
                                    type="hidden"
                                    name="ShopID"
                                    :value="WSPayDataBasicAnnual.ShopID"
                                />
                                <input
                                    type="hidden"
                                    name="ShoppingCartID"
                                    :value="WSPayDataBasicAnnual.ShoppingCartID"
                                />
                                <input
                                    type="hidden"
                                    name="Version"
                                    :value="WSPayDataBasicAnnual.Version"
                                />
                                <input
                                    type="hidden"
                                    name="TotalAmount"
                                    :value="WSPayDataBasicAnnual.TotalAmount"
                                />
                                <input
                                    type="hidden"
                                    name="Signature"
                                    :value="WSPayDataBasicAnnual.Signature"
                                />
                                <input
                                    type="hidden"
                                    name="ReturnURL"
                                    :value="WSPayDataBasicAnnual.ReturnURL"
                                />
                                <input
                                    type="hidden"
                                    name="CancelURL"
                                    :value="WSPayDataBasicAnnual.CancelURL"
                                />
                                <input
                                    type="hidden"
                                    name="ReturnErrorURL"
                                    :value="WSPayDataBasicAnnual.ReturnErrorURL"
                                />
                                <!-- <input type="hidden" name="IsTokenRequest" value="1" /> -->
                                <button
                                    type="submit"
                                    class="credit-card-button"
                                >
                                    Plati kreditnom kraticom (Online)
                                </button>
                            </form>
                        </div>
                        <div
                            v-if="plan.creditCard"
                            style="
                                display: flex;
                                align-items: center;
                                justify-content: center;
                                flex-direction: column;
                                height: 90%;
                            "
                        ></div>
                        <div
                            style="
                                margin-top: 3vh;
                                width: 100%;
                                position: absolute;
                                bottom: 0;
                                left: 0;
                            "
                        >
                            <button
                                :class="
                                    'choose-button-' + plan.name.toLowerCase()
                                "
                                @click="choosePlanYearly(plan)"
                                v-if="
                                    domainSubscriptionId !== plan.id &&
                                    !plan.expanded &&
                                    plan.id !== 1
                                "
                            >
                                <p style="margin: 0">Odaberi</p>
                            </button>
                            <button
                                :class="
                                    'choose-button-' + plan.name.toLowerCase()
                                "
                                @click="unChoosePlanYearly()"
                                v-if="
                                    domainSubscriptionId !== plan.id &&
                                    plan.expanded &&
                                    !plan.creditCard
                                "
                            >
                                <p style="margin: 0">Natrag</p>
                            </button>
                            <button
                                :class="
                                    'choose-button-' + plan.name.toLowerCase()
                                "
                                @click="unChoosePlanCreditYearly()"
                                v-if="
                                    domainSubscriptionId !== plan.id &&
                                    plan.expanded &&
                                    plan.creditCard
                                "
                            >
                                <p style="margin: 0">Natrag</p>
                            </button>
                            <button
                                class="current-plan"
                                disabled
                                v-if="
                                    domainSubscriptionId === plan.id &&
                                    domainSubscriptionId !== 1
                                "
                            >
                                <p style="margin: 0">Trenutni plan</p>
                            </button>
                        </div>
                    </div>
                </div>
            </DxItem>
        </DxTabPanel>
        <div style="margin: 0">
            <p style="font-size: 9px; text-align: center">
                * Plan se automatski otkazuje na kraju razdoblja ukoliko nije
                plaćen prije isteka.
            </p>
        </div>
        <div style="margin: 0">
            <p style="font-size: 9px; text-align: center">
                * Kupovinom drugog plana ako je već aktivan, trajanje aktivnog
                plana se produžuje za vrijeme definirano u novom planu.
            </p>
        </div>
    </DxPopup>
</template>
<script>
// DevExpress
import { DxPopup } from "devextreme-vue/popup";
import DxTabPanel, { DxItem } from "devextreme-vue/tab-panel";
//stores
import { useDomainUserStore } from "@/stores/customDomainUserStore";
//utils
import { generateSHA512Request } from "@/utils/hash.js";
import { v4 } from "uuid";
import { ref, reactive, onMounted, onUnmounted } from "vue";
import eventBus from "../../eventBus.js";
// other data
import config from "../../config.json";

export default {
    name: "subscriptionPlansPopupComponent",
    components: {
        DxItem,
        DxPopup,
        DxTabPanel
    },
    setup() {
        const domainUserStore = useDomainUserStore();
        const admin = domainUserStore.getDomainUser;
        const popupInstance = ref(null);
        const domainPermissions = ref(null);
        const domainSubscriptionId = ref(null);
        domainPermissions.value = domainUserStore.getDomainPermissions;
        domainSubscriptionId.value = domainPermissions.value.id;
        const limitHeader = ref("Nadogradite paket za više opcija.");
        const wsPayRoute = ref(config.WSPAY_ROUTE);
        const monthlyPlans = reactive([
            {
                id: 1,
                name: "FREE",
                price: "0 €",
                color: "green",
                users: ["1 tvrtka", "1 korisnik"],
                features: [
                    "Praćenje stanja skladišta",
                    "Mobilna aplikacija (Web)",
                    "Pretraživanje sudskog registra",
                    "Slanje e-Računa u javnoj nabavi",
                    "Slanje računa e-poštom",
                    "Bar kod za plaćanje računa"
                ],
                limitations: [
                    "Max. 50 dokumenata mjesečno",
                    "Najviše 60.000€ vrijednosti računa godišnje",
                    "Max. 1 skladište",
                    "Max. 1 cijenik",
                    "Max. 200 stavki cijenika",
                    "Max. 200 dobavljača",
                    "Max. 200 kupaca",
                    "Vodeni žig na dokumentima i e-pošti"
                ],
                additional: ["Dnevno arhiviranje", "Nadogradnje programa"],
                expanded: false,
                creditCard: false
            },
            {
                id: 2,
                name: "BASIC",
                price: "9,99 €/mj",
                color: "blue",
                users: ["1 tvrtka", "3 korisnika"],
                features: [
                    "Sve iz besplatnog paketa",
                    "Neograničen broj dokumenata",
                    "Neograničen broj skladišta",
                    "Neograničen broj cijenika",
                    "Neograničen broj stavki cijenika",
                    "Neograničen broj dobavljača",
                    "Neograničen broj kupaca",
                    "Mogućnost rekapitulacije dokumenata",
                    "Mogućnost ispisa otvorenih računa",
                    "Automatsko slanje opomena za račune",
                    "Uvoz stavki na cijenik",
                    "Uvoz početnog stanja"
                ],
                limitations: [],
                additional: [
                    "Dokumenti i e-pošta bez vodenog žiga",
                    "Besplatna e-mail podrška"
                ],
                expanded: false,
                creditCard: false
            }
        ]);
        const yearlyPlans = reactive([
            {
                id: 1,
                name: "FREE",
                price: "0 €",
                // priceMonthly: "0 €/mj",
                color: "green",
                users: ["1 tvrtka", "1 korisnik"],
                features: [
                    "Praćenje stanja skladišta",
                    "Mobilna aplikacija (Web)",
                    "Pretraživanje sudskog registra",
                    "Slanje e-Računa u javnoj nabavi",
                    "Slanje računa e-poštom",
                    "Bar kod za plaćanje računa"
                ],
                limitations: [
                    "Max. 50 dokumenata mjesečno",
                    "Najviše 60.000€ vrijednosti računa godišnje",
                    "Max. 1 skladište",
                    "Max. 1 cijenik",
                    "Max. 200 stavki cijenika",
                    "Max. 200 dobavljača",
                    "Max. 200 kupaca",
                    "Vodeni žig na dokumentima i e-pošti"
                ],
                additional: ["Dnevno arhiviranje", "Nadogradnje programa"],
                expanded: false,
                creditCard: false
            },
            {
                id: 4,
                name: "BASIC ANNUAL",
                price: "99,99 €/god",
                priceMonthly: "8,33 €/mj",
                color: "blue",
                users: ["1 tvrtka", "3 korisnika"],
                features: [
                    "Sve iz besplatnog paketa",
                    "Neograničen broj dokumenata",
                    "Neograničen broj skladišta",
                    "Neograničen broj cijenika",
                    "Neograničen broj stavki cijenika",
                    "Neograničen broj dobavljača",
                    "Neograničen broj kupaca",
                    "Mogućnost rekapitulacije dokumenata",
                    "Mogućnost ispisa otvorenih računa",
                    "Automatsko slanje opomena za račune",
                    "Uvoz stavki na cijenik",
                    "Uvoz početnog stanja"
                ],
                limitations: [],
                additional: [
                    "Dokumenti i e-pošta bez vodenog žiga",
                    "Besplatna e-mail podrška"
                ],
                expanded: false,
                creditCard: false
            }
        ]);
        const WSPayData = ref({
            ShopID: "GTNET",
            ShoppingCartID: `2-${admin.domainId}-${v4()}`,
            Version: "2.0",
            TotalAmount: "1,00",
            Signature: "",
            ReturnURL: `${config.FRONTEND_URL}/#/payment-status`,
            CancelURL: `${config.FRONTEND_URL}/#/payment-status`,
            ReturnErrorURL: `${config.FRONTEND_URL}/#/payment-status`
        });
        const WSPayDataBasicAnnual = ref({
            ShopID: "GTNET",
            ShoppingCartID: `4-${admin.domainId}-${v4()}`,
            Version: "2.0",
            TotalAmount: "99,99",
            Signature: "",
            ReturnURL: `${config.FRONTEND_URL}/#/payment-status`,
            CancelURL: `${config.FRONTEND_URL}/#/payment-status`,
            ReturnErrorURL: `${config.FRONTEND_URL}/#/payment-status`
        });

        onMounted(async () => {
            eventBus.on("openPlans", handleEvent);
            const hashResult = await generateSHA512Request(
                "GTNET",
                WSPayData.value.ShoppingCartID,
                "099"
            );
            WSPayData.value.Signature = hashResult;
            // console.log(WSPayData.value);
            const hashResultAnnual = await generateSHA512Request(
                "GTNET",
                WSPayDataBasicAnnual.value.ShoppingCartID,
                "9999"
            );
            WSPayDataBasicAnnual.value.Signature = hashResultAnnual;
            // console.log(WSPayDataBasicAnnual.value);
        });
        onUnmounted(() => {
            eventBus.off("openPlans", handleEvent);
        });
        const handleEvent = () => {
            popupInstance.value.show();
        };

        // instances
        const getSubscriptionPlanPopupInstance = (e) => {
            popupInstance.value = e.component;
        };

        // monthly methods
        const wireTransferMonthly = () => {
            alert(
                "Na email će Vam biti poslan račun. Slijedite upute u email-u za nastavak nadogradnje."
            );
            unChoosePlanMonthly();
        };
        const choosePlanMonthly = (chosenPlan) => {
            monthlyPlans.forEach((plan) => {
                plan.expanded = plan.id === chosenPlan.id;
            });
        };
        const unChoosePlanMonthly = () => {
            monthlyPlans.forEach((plan) => {
                plan.expanded = false;
                plan.creditCard = false;
            });
        };
        const unChoosePlanCreditMonthly = () => {
            monthlyPlans.forEach((plan) => {
                plan.creditCard = false;
            });
        };
        const creditCardPlanMonthly = (chosenPlan) => {
            monthlyPlans.forEach((plan) => {
                plan.creditCard = plan.id === chosenPlan.id;
            });
        };

        // annual methods
        const wireTransferYearly = () => {
            alert(
                "Na email će Vam biti poslan račun. Slijedite upute u email-u za nastavak nadogradnje."
            );
            unChoosePlanYearly();
        };
        const choosePlanYearly = (chosenPlan) => {
            yearlyPlans.forEach((plan) => {
                plan.expanded = plan.id === chosenPlan.id;
            });
        };
        const unChoosePlanYearly = () => {
            yearlyPlans.forEach((plan) => {
                plan.expanded = false;
                plan.creditCard = false;
            });
        };
        const unChoosePlanCreditYearly = () => {
            yearlyPlans.forEach((plan) => {
                plan.creditCard = false;
            });
        };
        const creditCardPlanYearly = (chosenPlan) => {
            yearlyPlans.forEach((plan) => {
                plan.creditCard = plan.id === chosenPlan.id;
            });
        };

        return {
            WSPayData,
            limitHeader,
            yearlyPlans,
            monthlyPlans,
            choosePlanYearly,
            choosePlanMonthly,
            wireTransferYearly,
            unChoosePlanYearly,
            wireTransferMonthly,
            unChoosePlanMonthly,
            WSPayDataBasicAnnual,
            creditCardPlanYearly,
            domainSubscriptionId,
            creditCardPlanMonthly,
            unChoosePlanCreditYearly,
            unChoosePlanCreditMonthly,
            getSubscriptionPlanPopupInstance,
            wsPayRoute
        };
    }
};
</script>
<style scoped>
.choose-button-free {
    background-color: white;
    border: none;
    margin: 2vh;
    border-radius: 10px;
    width: 50%;
    height: 40px;
    cursor: pointer;
}
.choose-button-free:hover {
    box-shadow: 0px 0px 20px #f5f5f5;
}
.current-plan {
    background-color: white;
    margin: 2vh;
    border: none;
    border-radius: 10px;
    width: 50%;
    height: 40px;
}
.choose-button-basic {
    background-color: white;
    border: none;
    border-radius: 10px;
    width: 50%;
    margin: 2vh;
    height: 40px;
    cursor: pointer;
}
.credit-card-button {
    background-color: white;
    border: none;
    border-radius: 10px;
    width: 50%;
    margin: 2vh;
    height: 40px;
    cursor: pointer;
    box-shadow: 0px 0px 20px #00ff7f;
}
.choose-button-basic:hover {
    box-shadow: 0px 0px 20px #007fff;
}
.choose-button-premium {
    background-color: white;
    border: none;
    margin: 2vh;
    border-radius: 10px;
    width: 50%;
    height: 40px;
    cursor: pointer;
}
.choose-button-premium:hover {
    box-shadow: 0px 0px 20px #e4d00a;
}
.sub-parent {
    width: 100%;
    display: flex;
    flex-direction: row;
    text-align: center;
    margin: auto;
    align-items: center;
    justify-content: space-around;
    margin-top: 3vh;
    margin-bottom: 1vh;
}
.plan-panel {
    width: 45%;
    height: 760px;
    margin: 1vw;
    /* opacity: 0.3; */
    border-radius: 15px;
    position: relative;
    padding-bottom: 80px;
    transition: width 0.3s ease, opacity 0.3s ease, transform 0.3s ease;
}
.green {
    background-color: rgba(192, 192, 192, 0.1);
    border: 0.2px solid #c0c0c0;
    box-shadow: 0px 0px 5px #c0c0c0;
}
.blue {
    background-color: rgba(0, 127, 255, 0.1);
    border: 0.2px solid #007fff;
    box-shadow: 0px 0px 5px #007fff;
}
.gold {
    background-color: rgba(228, 208, 10, 0.1);
    border: 0.2px solid #e4d00a;
    box-shadow: 0px 0px 5px #e4d00a;
}
@media screen and (max-width: 1440px) {
    .sub-parent {
        flex-direction: column;
    }
    .plan-panel {
        height: 100% !important;
        width: 95% !important;
    }
}
</style>
