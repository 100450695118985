import bwipjs from "bwip-js";

export function generateBarcode(data) {
    const canvas = document.createElement("canvas");
    try {
        bwipjs.toCanvas(canvas, {
            bcid: "qrcode",
            text: data,
            scale: 1,
            includetext: false,
            textxalign: "center",
            errorCorrectionLevel: "H"
        });
    } catch (e) {
        console.error(e);
    }
    return canvas;
}
