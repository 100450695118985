<template>
    <div class="container">
        <div
            style="
                padding: 5vh;
                margin: 0 auto;
                max-width: 500px;
                text-align: center;
            "
        >
            <img
                class="logo-img"
                src="@/assets/logo-minierp.svg"
                width="90"
                height="90"
            />
        </div>
        <div class="heading">
            <h1 style="font-weight: 500">Prijava</h1>
        </div>
        <form
            @submit.prevent="login"
            style="
                display: flex;
                justify-content: center;
                align-items: center;
                flex-direction: column;
                width: 100%;
            "
        >
            <DxForm
                :data-source="loggedUser"
                @field-data-changed="onFieldDataChanged"
                label-location="top"
                label-mode="floating"
                class="dxForm"
                :col-count="6"
            >
                <DxSimpleItem
                    data-field="email"
                    :required="true"
                    :editor-options="emailEditorOptions"
                    :col-span="6"
                >
                    <DxEmailRule message="Email nije ispravan." />
                    <DxRequiredRule message="Obavezno polje." />
                </DxSimpleItem>
                <DxSimpleItem
                    data-field="password"
                    :label="{ text: 'Lozinka' }"
                    :editor-options="passwordEditorOptions"
                    :col-span="6"
                >
                    <DxRequiredRule message="Obavezno polje." />
                </DxSimpleItem>
                <DxEmptyItem :col-span="6" />
                <DxEmptyItem :col-span="8" />
                <DxButtonItem
                    :button-options="submitButtonOptions"
                    :col-span="4"
                />
            </DxForm>
        </form>
        <div
            style="
                display: flex;
                justify-content: center;
                margin: 2vh;
                flex-direction: column;
                align-items: center;
            "
        >
            <p><strong>TEST ⬇︎</strong></p>
            <GoogleLogin :callback="signInWithGoogle" />
        </div>
        <div class="forgotPasswordContainer">
            <span
                @click="forgotPassword"
                style="
                    cursor: pointer;
                    color: cadetblue;
                    text-decoration: underline;
                "
            >
                Zaboravili ste lozinku?
            </span>
        </div>
        <div class="registerContainer">
            <span>Nemate registriranu tvrtku?</span>
            <span @click="register" style="cursor: pointer; color: cadetblue">
                Registrirajte novu tvrtku.
            </span>
        </div>
        <div class="errorContainer" v-if="errorMessage !== ''">
            <span>{{ errorMessage }}</span>
        </div>
        <!-- <div class="registerContainer">
            <span
                >Pregledajte cjenik i odaberite uslugu koja vam najbolje
                odgovara</span
            >
            <a
                href="http://gtnet.hr/minierp"
                style="cursor: pointer; color: cadetblue; text-decoration: none"
                target="_blank"
            >
                ovdje.
            </a>
        </div> -->
        <div class="copyrightContainer">
            <span>COPYRIGHT © GT-NET 2024 . ALL RIGHTS RESERVED.</span>
        </div>
    </div>
</template>

<script>
//DevExpress
import {
    DxForm,
    DxSimpleItem,
    DxButtonItem,
    DxEmailRule,
    DxRequiredRule,
    DxEmptyItem
} from "devextreme-vue/form";
import notify from "devextreme/ui/notify";

//Stores
import { domain } from "@/stores/domainStore";
import { useDomainUserStore } from "@/stores/customDomainUserStore.js";
import { loggedUser } from "../../user";
import { ref, onMounted } from "vue";
import { useRouter } from "vue-router";
import themes from "devextreme/ui/themes";
import { GoogleLogin, decodeCredential } from "vue3-google-login";
// import eventBus from "../../eventBus";

export default {
    name: "loginForm",
    props: ["expired"],
    components: {
        DxForm,
        DxSimpleItem,
        DxButtonItem,
        DxEmailRule,
        DxRequiredRule,
        DxEmptyItem,
        GoogleLogin
    },
    setup(props) {
        const errorMessage = ref("");
        const domainUserStore = useDomainUserStore();
        const router = useRouter();
        let userInput = ref({
            email: "",
            password: ""
        });
        const userFound = ref(false);
        const domainActive = ref(false);
        const submitButtonInstance = ref(null);
        const submitButtonOptions = ref({
            text: "Prijava",
            useSubmitBehavior: true,
            stylingMode: "contained",
            type: "success",
            width: "100%",
            alignment: "center",
            onInitialized: (e) => {
                submitButtonInstance.value = e.component;
            }
        });

        const registerDomainButtonOptions = ref({
            text: "Registracija",
            width: "100%",
            alignment: "left",
            type: "default",
            onClick: () => {
                router.push("/register-domain");
            }
        });
        const register = () => {
            router.push("/register-domain");
        };
        const forgotPassword = () => {
            router.push("/forgot-password");
        };
        const emailEditorOptions = ref({
            height: "50px"
        });
        const passwordEditorOptions = ref({
            height: "50px",
            mode: "password"
        });
        const onFieldDataChanged = (e) => {
            userInput.value[e.dataField] = e.value;
            errorMessage.value = "";
        };
        const login = async () => {
            submitButtonInstance.value.option("disabled", true);
            const res = await domainUserStore.loginDomainUser(userInput.value);
            if (res.loginUserData) {
                submitButtonInstance.value.option("disabled", false);
                const domainStatus = await domain.byKey(
                    res.loginUserData.user.domainId
                );
                if (domainStatus.status === "Aktivna") {
                    const savedBusinessYear =
                        localStorage.getItem("businessYearId");
                    if (!savedBusinessYear) {
                        localStorage.setItem("businessYearId", 15);
                    }
                    // eventBus.emit(
                    //     "newUserLoggedIn",
                    //     res.loginUserData.user.domainId
                    // );
                    userFound.value = true;
                    domainActive.value = true;
                } else if (domainStatus.status === "Neaktivna") {
                    userFound.value = true;
                }
            } else if (res.status === 429) {
                submitButtonInstance.value.option("disabled", false);
                const message = res.error;
                notify(
                    {
                        message,
                        width: 450
                    },
                    "error",
                    2000
                );
            } else {
                submitButtonInstance.value.option("disabled", false);
                const message = res.error.message;
                notify(
                    {
                        message,
                        width: 450
                    },
                    "error",
                    2000
                );
            }
            if (userFound.value && domainActive.value) {
                router.push({ name: "home" });
            } else if (userFound.value && !domainActive.value) {
                submitButtonInstance.value.option("disabled", false);
                const message = "Domena neaktivna!";
                notify(
                    {
                        message,
                        width: 450
                    },
                    "error",
                    2000
                );
            }
        };
        const signInWithGoogle = (response) => {
            const userData = decodeCredential(response.credential);
            console.log(userData);
        };
        onMounted(() => {
            if (props.expired === "true") {
                errorMessage.value =
                    "Token istekao ili nije pronađen. Morate se ponovno prijaviti.";
            } else {
                errorMessage.value = props.expired;
            }
            themes.current("generic.light");
        });
        return {
            domainUserStore,
            userInput,
            userFound,
            domainActive,
            submitButtonOptions,
            registerDomainButtonOptions,
            passwordEditorOptions,
            onFieldDataChanged,
            login,
            loggedUser,
            register,
            emailEditorOptions,
            forgotPassword,
            errorMessage,
            signInWithGoogle
        };
    }
};
</script>
<style scoped>
.container {
    height: 100vh;
    width: 100vw;
    margin: 0;
    background-image: url("../assets/background4.svg");
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    background-attachment: fixed; /* This line keeps the background fixed */
}
.forgotPasswordContainer {
    max-width: 500px;
    margin: 3vh auto;
    /* padding: 3vh; */
    text-align: center;
}
.registerContainer {
    max-width: 500px;
    margin: 2vh auto;
    /* padding: 3vh; */
    text-align: center;
}
.errorContainer {
    max-width: 500px;
    margin: 2vh auto;
    /* padding: 3vh; */
    text-align: center;
    color: red;
}
.copyrightContainer {
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    max-width: 500px;
    height: 15px;
    margin: 1vh auto;
    text-align: center;
}
.dxForm {
    width: 30vw;
    max-width: 30vw;
}

@media screen and (max-width: 768px) {
    .dxForm {
        width: 90vw;
        max-width: 90vw;
    }
}
@media screen and (max-width: 1024px) {
    .dxForm {
        width: 75vw; /* Adjust this value as needed for tablet view */
        max-width: 75vw;
    }
}
.heading {
    margin: 10vh auto 0 auto;
    text-align: center;
}
</style>
