import CustomStore from "devextreme/data/custom_store";
import { sendRequest } from "@/helpers/sendRequestHelper";
import config from "../../config.json";
import eventBus from "../../eventBus";

let isSubscribed = false; // Prevent duplicate subscriptions
let filterId = null;
const subscribeToEvent = () => {
    if (!isSubscribed) {
        eventBus.on("newFocusedRowInvoice", (id) => {
            filterId = id;
        });
        isSubscribed = true;
    }
};
subscribeToEvent();
export const invoiceItem = new CustomStore({
    key: "id",
    load: async (loadOptions) => {
        try {
            if (filterId !== null) {
                const filterCondition = {
                    where: {
                        invoiceId: filterId
                    }
                };
                const newData = await sendRequest(
                    `${config.API_URL}/${
                        config.PATHS.INVOICE_ITEM
                    }?filter=${JSON.stringify(filterCondition)}`
                );
                eventBus.emit("invoiceItems", newData);
                return newData;
            } else if (
                loadOptions.invoiceId &&
                loadOptions.invoiceId !== null
            ) {
                const filterCondition = {
                    where: {
                        invoiceId: loadOptions.invoiceId
                    }
                };
                const newData = await sendRequest(
                    `${config.API_URL}/${
                        config.PATHS.INVOICE_ITEM
                    }?filter=${JSON.stringify(filterCondition)}`
                );
                return newData;
            } else {
                eventBus.emit("invoiceItems", []);
            }
        } catch (error) {
            console.error("Error loading data:", error);
            throw error;
        }
    },
    byKey: async (key) => {
        try {
            const res = await sendRequest(
                `${config.API_URL}/${config.PATHS.INVOICE_ITEM}/${key}`
            );
            return res;
        } catch (error) {
            console.error("Error loading data:", error);
            throw error;
        }
    },
    update: async (id, values) => {
        try {
            await sendRequest(
                `${config.API_URL}/${config.PATHS.INVOICE_ITEM}/${id}`,
                "PATCH",
                {
                    values: values
                }
            );
        } catch (error) {
            console.error("Error updating data:", error);
            throw error;
        }
    },
    insert: async (values) => {
        try {
            const response = await sendRequest(
                `${config.API_URL}/${config.PATHS.INVOICE_ITEM}`,
                "POST",
                {
                    values: values
                }
            );
            return response;
        } catch (error) {
            console.error("Error inserting data:", error);
            throw error;
        }
    },
    remove: async (id) => {
        try {
            await sendRequest(
                `${config.API_URL}/${config.PATHS.INVOICE_ITEM}/${id}`,
                "DELETE"
            );
        } catch (error) {
            console.error("Error removing data:", error);
            throw error;
        }
    }
});
