<template>
    <!-- <div class="table-container"> -->
    <div class="table-name">
        <p>Dobavljači</p>
        <div style="width: 20%">
            <DxProgressBar
                v-if="domainPermissions.id === 1"
                :read-only="true"
                hint="Iskorišteni limit dokumenata"
                id="progress-bar-status"
                :min="0"
                :max="domainPermissions.supplierCount"
                :value="docCount"
                :status-format="
                    (ration, value) => {
                        return `${value}/${domainPermissions.supplierCount}`;
                    }
                "
            />
        </div>
    </div>
    <div id="loading" v-if="lookupLoading" style="margin-top: 10vh"></div>
    <DxDataGrid
        v-if="!lookupLoading"
        id="supplierTable"
        :data-source="supplier"
        :repaint-changes-only="true"
        :allow-column-resizing="true"
        :show-column-lines="true"
        :show-row-lines="true"
        :show-borders="true"
        :column-auto-width="true"
        :hover-state-enabled="true"
        @initialized="getSupplierTableInstance"
        @row-updating="onRowUpdatingSupplier"
        @row-updated="onRowUpdatedSupplier"
        @row-inserting="onRowInsertingSupplier"
        @row-inserted="onRowInsertedSupplier"
        @init-new-row="onInitNewRowSupplier"
    >
        <DxToolbar>
            <DxItem
                :options="addButtonOptions"
                location="after"
                locate-in-menu="auto"
                widget="dxButton"
            />
            <DxItem
                :options="refreshButtonOptions"
                location="after"
                locate-in-menu="auto"
                widget="dxButton"
            />
            <DxItem
                :options="columnChooserButtonOptions"
                location="after"
                locate-in-menu="auto"
                widget="dxButton"
            />
            <DxItem
                :options="exportButtonOptions"
                location="after"
                locate-in-menu="auto"
                widget="dxButton"
            />
            <DxItem name="searchPanel" location="after" locate-in-menu="auto" />
        </DxToolbar>
        <DxStateStoring
            :enabled="true"
            type="localStorage"
            storage-key="supplierTableState"
        />

        <DxSearchPanel :visible="true" />
        <DxHeaderFilter :visible="true" />
        <DxScrolling column-rendering-mode="virtual" />
        <DxPaging :page-size="20" />
        <DxPager
            :visible="true"
            display-mode="adaptive"
            :show-info="true"
            info-text="Stranica {0} od {1} ({2} stavki)"
            :allowed-page-sizes="[10, 20, 30]"
            :show-navigation-buttons="true"
            :show-page-size-selector="true"
        />

        <DxEditing
            :allow-deleting="true"
            :allow-updating="true"
            :use-icons="true"
            mode="popup"
        >
            <DxPopup
                :show-title="true"
                title="Dodaj novog dobavljača"
                :toolbar-items="popupToolbarItems"
                :drag-outside-boundary="true"
            />
            <DxForm @initialized="getSupplierFormInstance">
                <DxItem :col-count="1" :col-span="2" item-type="group">
                    <DxButtonItem
                        :button-options="fetchCompanyDataButtonOptions"
                        horizontal-alignment="left"
                    />
                </DxItem>

                <DxItem
                    :col-count="3"
                    :col-span="2"
                    item-type="group"
                    caption="Informacije o dobavljaču"
                >
                    <DxItem
                        data-field="id"
                        data-type="number"
                        :visible="false"
                    />
                    <DxItem
                        data-field="vatNumber"
                        data-type="string"
                        :editor-options="oibOptions"
                    >
                        <DxRequiredRule />
                        <DxStringLengthRule
                            :max="11"
                            :min="11"
                            message="OIB mora sadržavati 11 brojeva."
                        />
                    </DxItem>

                    <DxItem data-field="name" data-type="string" />
                    <DxItem data-field="lastname" data-type="string" />
                    <DxItem
                        data-field="companyName"
                        data-type="string"
                        :editor-options="companyNameOptions"
                    />
                    <!-- <DxItem data-field="vatNumber" data-type="string" /> -->
                    <DxItem
                        data-field="invoiceType"
                        data-type="string"
                        editor-type="dxSelectBox"
                        :editor-options="invoiceTypeSelectBoxOptions"
                    >
                        <DxRequiredRule
                    /></DxItem>
                </DxItem>
                <DxItem
                    :col-count="3"
                    :col-span="2"
                    item-type="group"
                    caption="Adresa/Kontakt"
                >
                    <DxItem
                        data-field="zipCode"
                        data-type="string"
                        :editor-options="zipCodeOptions"
                    />
                    <DxItem
                        data-field="city"
                        data-type="string"
                        :editor-options="cityOptions"
                    />
                    <DxItem
                        data-field="street"
                        data-type="string"
                        :editor-options="streetOptions"
                    />
                    <DxItem
                        data-field="country"
                        data-type="string"
                        :editor-options="countryOptions"
                    />
                    <DxItem data-field="region" data-type="string" />
                    <DxItem data-field="contact" data-type="string" />
                    <DxItem data-field="phone" data-type="string" />
                    <DxItem data-field="fax" data-type="string" />
                    <DxItem
                        data-field="email"
                        data-type="string"
                        :editor-options="emailOptions"
                    >
                        <DxEmailRule />
                    </DxItem>
                    <DxItem data-field="web" data-type="string" />
                </DxItem>
                <DxItem
                    :col-count="3"
                    :col-span="2"
                    item-type="group"
                    caption="Ostale informacije"
                >
                    <DxItem data-field="discount" data-type="number" />
                    <DxItem data-field="profitCenter" data-type="string" />
                </DxItem>
            </DxForm>
        </DxEditing>
        <DxColumn
            :width="80"
            data-field="id"
            data-type="number"
            caption="ID"
            sort-order="desc"
            :show-in-column-chooser="false"
            :allow-exporting="false"
        />
        <!-- <DxColumn
                data-field="oib"
                data-type="string"
                caption="OIB"
                :allow-hiding="false"
                :allow-exporting="false"
            /> -->
        <DxColumn
            data-field="name"
            data-type="string"
            caption="Ime"
            :allow-hiding="false"
            :allow-exporting="false"
        />
        <DxColumn
            data-field="lastname"
            data-type="string"
            caption="Prezime"
            :allow-hiding="false"
            :allow-exporting="false"
        />
        <DxColumn
            data-field="companyName"
            data-type="string"
            caption="Naziv firme"
            :allow-hiding="false"
        />
        <DxColumn
            data-field="vatNumber"
            data-type="string"
            caption="OIB"
            :allow-hiding="false"
            :allow-exporting="false"
        />
        <DxColumn
            data-field="invoiceType"
            data-type="string"
            caption="Tip računa"
            :allow-exporting="false"
        />
        <DxColumn
            data-field="zipCode"
            data-type="string"
            caption="Poštanski broj"
            :allow-exporting="false"
        />
        <DxColumn
            data-field="city"
            data-type="string"
            caption="Grad"
            :allow-exporting="false"
        />
        <DxColumn
            data-field="street"
            data-type="string"
            caption="Ulica"
            :allow-exporting="false"
        />
        <DxColumn
            data-field="country"
            data-type="string"
            caption="Država"
            :allow-exporting="false"
        />
        <DxColumn
            data-field="region"
            data-type="string"
            caption="Regija"
            :allow-exporting="false"
        />
        <DxColumn
            data-field="discount"
            data-type="number"
            caption="Popust"
            :allow-exporting="false"
        />
        <DxColumn
            data-field="profitCenter"
            data-type="string"
            caption="Profitni centar"
            :allow-exporting="false"
        />
        <DxColumn data-field="contact" data-type="string" caption="Kontakt" />
        <DxColumn data-field="phone" data-type="string" caption="Telefon" />
        <DxColumn
            data-field="fax"
            data-type="string"
            caption="Fax"
            :allow-exporting="false"
        />
        <DxColumn
            data-field="email"
            data-type="string"
            caption="E-mail"
            :allow-exporting="false"
        />
        <DxColumn
            data-field="web"
            data-type="string"
            caption="Web"
            :allow-exporting="false"
        />
        <DxColumn
            data-field="createdDate"
            data-type="datetime"
            format="dd/MM/yyyy HH:mm"
            caption="Kreirano"
            :allow-exporting="false"
        />
        <DxColumn
            data-field="createdById"
            data-type="number"
            caption="Kreirao"
            :allow-exporting="false"
        >
            <DxLookup
                :items="lookupData.domainUser"
                value-expr="id"
                display-expr="username"
            />
        </DxColumn>
        <DxColumn
            data-field="modifiedDate"
            data-type="datetime"
            format="dd/MM/yyyy HH:mm"
            caption="Uređeno"
            :allow-exporting="false"
        />
        <DxColumn
            data-field="modifiedById"
            data-type="number"
            caption="Uredio"
            :allow-exporting="false"
        >
            <DxLookup
                :items="lookupData.domainUser"
                value-expr="id"
                display-expr="username"
            />
        </DxColumn>
        <DxColumn
            data-field="domainId"
            data-type="number"
            caption="Domena"
            :visible="false"
            :allow-hiding="false"
            :show-in-column-chooser="false"
            :allow-exporting="false"
        />
        <DxColumn
            type="buttons"
            :auto-width="true"
            :fixed="true"
            :show-in-column-chooser="false"
        >
            <DxButton icon="menu" @click="openContextMenu" />
            <!-- <DxButton
                hint="Uredi"
                icon="rename"
                :visible="true"
                @click="editSupplier"
            />
            <DxButton
                hint="Obriši"
                icon="trash"
                :visible="true"
                @click="deleteSupplier"
            /> -->
        </DxColumn>
        <DxColumnChooser :enabled="true" :mode="columnChooserMode">
            <DxColumnChooserSelection
                :allow-select-all="true"
                :select-by-click="true"
                :recursive="true"
            />
        </DxColumnChooser>
    </DxDataGrid>
    <SudregPopup
        :resize-enabled="true"
        :max-width="1000"
        :drag-outside-boundary="true"
        :toolbarItems="sudregPopupToolbarItems"
        title="Učitaj podatke iz sudskog registra"
        @initialized="getSudregPopupInstance"
    >
        <DxDataGrid
            id="sudregDataTable"
            :data-source="sudregApi"
            :show-column-lines="true"
            :show-row-lines="true"
            :show-borders="true"
            :column-auto-width="true"
            no-data-text="Započnite pretraživanje"
            @initialized="getSudregDataTableInstance"
        >
            <DxToolbar>
                <DxItem
                    location="before"
                    locate-in-menu="auto"
                    widget="dxTextBox"
                    :options="searchBarOptions"
                />
            </DxToolbar>
            <DxPaging :page-size="20" />
            <DxColumnFixing :enabled="true" />
            <DxPager
                :allowed-page-sizes="[]"
                :visible="true"
                display-mode="adaptive"
                :show-info="true"
                info-text="Stranica {0} od {1} ({2} stavki)"
                :show-navigation-buttons="true"
                :show-page-size-selector="true"
            />
            <DxColumn
                type="buttons"
                :width="70"
                :show-in-column-chooser="false"
                :fixed="true"
                caption="Učitaj"
                fixed-position="left"
            >
                <DxButton
                    hint="Učitaj"
                    icon="chevrondoubleright"
                    :visible="true"
                    @click="fetchCompanyData"
                />
            </DxColumn>
            <DxColumn data-field="mbs" data-type="string" caption="MBS" />
            <DxColumn data-field="oib" data-type="string" caption="OIB" />
            <DxColumn data-field="ime" data-type="string" caption="Naziv" />
        </DxDataGrid>
    </SudregPopup>
    <DxContextMenu
        @initialized="getContextMenuInstance"
        showEvent="openContextMenu"
    />
    <DxLoadPanel
        :position="loadingPosition"
        :visible="lookupLoading"
        :show-indicator="true"
        message="Učitavanje..."
    />
    <!-- <subscriptionPlansPopupComponent /> -->
    <!-- </div> -->
</template>
<script>
//DevExpress
import {
    DxItem,
    DxForm,
    DxPager,
    DxPopup,
    DxButton,
    DxPaging,
    DxColumn,
    DxLookup,
    DxToolbar,
    DxEditing,
    DxDataGrid,
    DxEmailRule,
    DxScrolling,
    DxSearchPanel,
    DxHeaderFilter,
    DxStateStoring,
    DxRequiredRule,
    DxColumnChooser,
    DxColumnFixing,
    DxColumnChooserSelection,
    DxStringLengthRule
} from "devextreme-vue/data-grid";
import notify from "devextreme/ui/notify";
import { DxPopup as SudregPopup } from "devextreme-vue/popup";
import { DxLoadPanel } from "devextreme-vue/load-panel";
// import {
//     DxForm as SudregForm,
//     // DxSimpleItem,
// } from "devextreme-vue/form";
import { DxButtonItem } from "devextreme-vue/cjs/form";
import eventBus from "../../eventBus.js";
import { useSudregApiStore } from "@/stores/customSudregApiStore";
import { confirm } from "devextreme/ui/dialog";
import { ref, onMounted, onUnmounted } from "vue";
import { useSupplier } from "@/composables/useSupplier.js";
import DxContextMenu from "devextreme-vue/context-menu";
import { DxProgressBar } from "devextreme-vue/progress-bar";
// import subscriptionPlansPopupComponent from "./subscriptionPlansPopupComponent.vue";
import { checkSubscriptionLimit } from "@/utils/checkSubscriptionLimits.js";
import { useLookupStore } from "@/stores/customLookupStore.js";

export default {
    name: "supplierTableComponent",
    components: {
        DxProgressBar,
        DxContextMenu,
        DxItem,
        DxForm,
        DxPager,
        DxPopup,
        DxButton,
        DxPaging,
        DxColumn,
        DxLookup,
        DxLoadPanel,
        DxToolbar,
        DxEditing,
        DxDataGrid,
        DxEmailRule,
        DxScrolling,
        DxSearchPanel,
        DxHeaderFilter,
        DxStateStoring,
        DxRequiredRule,
        DxColumnChooser,
        DxColumnChooserSelection,
        DxButtonItem,
        SudregPopup,
        DxStringLengthRule,
        DxColumnFixing
        // subscriptionPlansPopupComponent
        // SudregForm,
    },
    setup() {
        const sudregApiStore = useSudregApiStore();
        const lookupStore = useLookupStore();
        const {
            admin,
            supplier,
            domainPermissions,
            domainUser,
            sudregApi,
            saveLastData,
            lastSavedData,
            columnChooserMode,
            handleNewRowInitSupplier,
            handleRowUpdatingSupplier,
            handleRowInsertingSupplier,
            handleRowInsertedSupplier,
            handleRowUpdatedSupplier,
            handleRowRemovingSupplier,
            handleExportSupplier,
            getDomainData
        } = useSupplier();
        onMounted(async () => {
            eventBus.on("lookupLoading", handleLookupLoading);
            // console.log(lookupStore.isLoading, lookupStore.lookupData);
            if (
                !lookupStore.isLoading &&
                Object.keys(lookupStore.lookupData).length > 0
            ) {
                lookupData.value = lookupStore.lookupData;
                lookupLoading.value = false;
            }
            const res = await checkSubscriptionLimit("supplier");
            docCount.value = res.count;
            await getDomainData();
        });
        onUnmounted(() => {
            eventBus.off("lookupLoading", handleLookupLoading);
        });
        //instances
        const tableInstance = ref(null);
        const sudregPopupInstance = ref(null);
        const sudregApiDataTableInstance = ref(null);
        const searchBarInstance = ref(null);
        const supplierFormInstance = ref(null);
        const oibInstance = ref(null);
        const companyNameInstance = ref(null);
        const zipCodeInstance = ref(null);
        const cityInstance = ref(null);
        const streetInstance = ref(null);
        const countryInstance = ref(null);
        const emailInstance = ref(null);
        const contextMenuInstance = ref(null);

        const rowEvent = ref(null);
        const docCount = ref(null);
        const lookupLoading = ref(true);
        const loadingPosition = { of: "#loading" };
        const lookupData = ref(null);
        const invoiceTypes = ref([{ value: "R1" }, { value: "R2" }]);
        const invoiceTypeSelectBoxOptions = ref({
            dataSource: invoiceTypes.value,
            valueExpr: "value",
            displayExpr: "value",
            placeholder: "Izaberi tip računa"
        });
        //button options
        const addButtonOptions = ref({
            icon: "add",
            text: "Dodaj",
            hint: "Novi",
            onClick: () => {
                addSupplier();
            }
        });
        const refreshButtonOptions = ref({
            icon: "refresh",
            text: "Osvježi",
            hint: "Osvježi podatke",
            onClick: () => {
                refreshData();
            }
        });
        const columnChooserButtonOptions = ref({
            icon: "columnchooser",
            text: "Stupci",
            hint: "Odaberi stupce za prikaz",
            onClick: () => {
                openColumnChooser();
            }
        });
        const exportButtonOptions = ref({
            icon: "export",
            text: "Izvoz",
            hint: "Izvoz",
            onClick: () => {
                exportSupplier();
            }
        });
        const fetchCompanyDataButtonOptions = ref({
            text: "Učitaj podatke iz sudskog registra",
            stylingMode: "contained",
            width: 300,
            type: "default",
            onClick: () => {
                openSudregPopup();
            }
        });
        const searchBarOptions = ref({
            width: 240,
            label: "Pretraživanje",
            labelMode: "floating",
            onInitialized: (e) => {
                searchBarInstance.value = e.component;
            },
            onValueChanged: async (e) => {
                eventBus.emit("searchValue", e.value);
                sudregApiDataTableInstance.value.refresh();
            }
        });

        //toolbar items
        const popupToolbarItems = ref([
            {
                widget: "dxButton",
                location: "after",
                toolbar: "bottom",
                options: {
                    text: "Spremi i novi",
                    onClick: () => saveAndContinueSupplier()
                }
            },
            {
                widget: "dxButton",
                location: "after",
                toolbar: "bottom",
                options: {
                    text: "Spremi",
                    onClick: () => saveSupplier()
                }
            },
            {
                widget: "dxButton",
                location: "after",
                toolbar: "bottom",
                options: {
                    text: "Odustani",
                    onClick: () => cancelSupplier()
                }
            }
        ]);
        const sudregPopupToolbarItems = ref([
            {
                widget: "dxButton",
                location: "after",
                toolbar: "bottom",
                options: {
                    text: "Odustani",
                    type: "danger",
                    icon: "remove",
                    width: 120,
                    onClick: () => sudregPopupInstance.value.hide()
                }
            }
        ]);
        const oibOptions = ref({
            onInitialized: (e) => {
                oibInstance.value = e.component;
            }
        });
        const companyNameOptions = ref({
            onInitialized: (e) => {
                companyNameInstance.value = e.component;
            }
        });
        const zipCodeOptions = ref({
            onInitialized: (e) => {
                zipCodeInstance.value = e.component;
            }
        });
        const cityOptions = ref({
            onInitialized: (e) => {
                cityInstance.value = e.component;
            }
        });
        const streetOptions = ref({
            onInitialized: (e) => {
                streetInstance.value = e.component;
            }
        });
        const countryOptions = ref({
            onInitialized: (e) => {
                countryInstance.value = e.component;
            }
        });
        const emailOptions = ref({
            onInitialized: (e) => {
                emailInstance.value = e.component;
            }
        });

        //instance getters
        const getSupplierTableInstance = (e) => {
            tableInstance.value = e.component;
        };
        const getSudregPopupInstance = (e) => {
            sudregPopupInstance.value = e.component;
        };
        const getSupplierFormInstance = (e) => {
            supplierFormInstance.value = e.component;
        };
        const getSudregDataTableInstance = (e) => {
            sudregApiDataTableInstance.value = e.component;
        };
        const getContextMenuInstance = (e) => {
            contextMenuInstance.value = e.component;
        };

        //supplier table methods
        const addSupplier = async () => {
            const res = await checkSubscriptionLimit("supplier");
            if (res.limitReached) {
                eventBus.emit("openPlans");
            } else {
                tableInstance.value.addRow();
            }
        };
        const editSupplier = (e) => {
            tableInstance.value.editRow(e.row.rowIndex);
        };
        const cancelSupplier = () => {
            tableInstance.value.cancelEditData();
        };
        const deleteSupplier = (e) => {
            let res = confirm(
                "Jeste li sigurni da želite obrisati dobavljača?"
            );
            res.then(async (c) => {
                if (c) {
                    await handleRowRemovingSupplier(e);
                    tableInstance.value.deleteRow(e.row.rowIndex);
                }
            });
        };
        const onInitNewRowSupplier = (e) => {
            handleNewRowInitSupplier(e);
        };
        const saveSupplier = () => {
            saveLastData.value = false;
            lastSavedData.value = {};
            tableInstance.value.saveEditData();
        };
        const saveAndContinueSupplier = () => {
            saveLastData.value = true;
            tableInstance.value.saveEditData();
        };
        const onRowInsertingSupplier = (e) => {
            e.data = handleRowInsertingSupplier(e);
        };
        const onRowInsertedSupplier = async (e) => {
            if (saveLastData.value) {
                addSupplier();
            }
            lookupLoading.value = true;
            await handleRowInsertedSupplier(e);
            const res = await checkSubscriptionLimit("supplier");
            docCount.value = res.count;
            lookupLoading.value = false;
            tableInstance.value.refresh();
        };
        const onRowUpdatingSupplier = async (e) => {
            e.newData = await handleRowUpdatingSupplier(e);
        };
        const onRowUpdatedSupplier = async (e) => {
            await handleRowUpdatedSupplier(e);
        };

        //button methods
        const openContextMenu = (e) => {
            rowEvent.value = e;
            const targetElement = e.event.currentTarget;
            contextMenuInstance.value.option("items", getContextMenuItems());
            contextMenuInstance.value.option("position", {
                my: "top right",
                at: "bottom left",
                of: targetElement
            });
            contextMenuInstance.value.option("target", targetElement);
            contextMenuInstance.value.show();
        };

        const refreshData = async () => {
            lookupLoading.value = true;
            const message = "Podaci osvježeni!";
            tableInstance.value.refresh();
            await lookupStore.fetchLookups(admin.value.domainId);
            lookupLoading.value = false;
            notify({ message, width: 450 }, "success");
        };
        const openColumnChooser = () => {
            tableInstance.value.showColumnChooser();
        };
        const exportSupplier = () => {
            handleExportSupplier(tableInstance.value);
        };

        //
        const openSudregPopup = () => {
            sudregPopupInstance.value.show();
        };
        const handleLookupLoading = (e) => {
            lookupData.value = lookupStore.getLookupData;
            lookupLoading.value = e;
        };

        //Funkcija za dohvat podataka iz sudskog registra
        const fetchCompanyData = async (e) => {
            const res = await sudregApiStore.fetchCompanyData({
                tip_identifikatora: "oib",
                identifikator: e.row.data.oib.toString()
            });
            oibInstance.value.option(
                "value",
                res.potpuni_oib ? res.potpuni_oib : res.oib
            );
            companyNameInstance.value.option("value", res.tvrtka.ime);
            cityInstance.value.option(
                "value",
                res.sjediste.naziv_naselja ? res.sjediste.naziv_naselja : ""
            );
            streetInstance.value.option(
                "value",
                res.sjediste.ulica && res.sjediste.kucni_broj
                    ? res.sjediste.ulica + " " + res.sjediste.kucni_broj
                    : ""
            );
            countryInstance.value.option("value", "Hrvatska");
            emailInstance.value.option(
                "value",
                res.email_adrese ? res.email_adrese[0].adresa : ""
            );
            sudregPopupInstance.value.hide();
            eventBus.emit("searchValue", "");
            searchBarInstance.value.option("value", "");
        };
        const getContextMenuItems = () => {
            return [
                {
                    text: "Uredi",
                    icon: "edit",
                    onClick: () => {
                        editSupplier(rowEvent.value);
                    }
                }
                // {
                //     text: "Obriši",
                //     icon: "trash",
                //     onClick: () => {
                //         deleteSupplier(rowEvent.value);
                //     }
                // }
            ];
        };
        return {
            getContextMenuInstance,
            openContextMenu,
            invoiceTypeSelectBoxOptions,
            popupToolbarItems,
            columnChooserButtonOptions,
            refreshButtonOptions,
            addButtonOptions,
            getSupplierTableInstance,
            columnChooserMode,
            invoiceTypes,
            supplier,
            domainUser,
            addSupplier,
            editSupplier,
            cancelSupplier,
            deleteSupplier,
            onInitNewRowSupplier,
            saveSupplier,
            saveAndContinueSupplier,
            onRowInsertingSupplier,
            onRowInsertedSupplier,
            onRowUpdatingSupplier,
            refreshData,
            openColumnChooser,
            exportButtonOptions,
            fetchCompanyDataButtonOptions,
            fetchCompanyData,
            getSudregPopupInstance,
            sudregPopupToolbarItems,
            searchBarOptions,
            getSupplierFormInstance,
            oibOptions,
            companyNameOptions,
            zipCodeOptions,
            cityOptions,
            streetOptions,
            countryOptions,
            emailOptions,
            sudregApi,
            getSudregDataTableInstance,
            onRowUpdatedSupplier,
            lookupLoading,
            loadingPosition,
            lookupData,
            docCount,
            domainPermissions
        };
    }
};
</script>
<style scoped>
.table-container {
    margin: 2vh;
    max-width: 100%;
}
#supplierTable {
    max-width: 100%;
}
.table-name {
    font-size: 1.6rem;
    text-align: center;
    margin: 1vh;
    display: flex;
    align-items: center;
    justify-content: space-between;
}
</style>
