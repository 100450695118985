import router from "@/router";
import config from "../../config.json";
import eventBus from "../../eventBus.js";
export const sendRequest = async (url, method = "GET", data = {}) => {
    const otherMethods = ["POST", "PATCH"];
    const request = {};

    request.method = method;
    if (otherMethods.includes(method)) {
        request.headers = {
            "Content-Type": "application/json"
        };
        request.body = JSON.stringify(data.values);
    }
    request.credentials = "include";
    const controller = new AbortController();
    const signal = controller.signal;
    request.signal = signal;
    const timeoutId = setTimeout(() => controller.abort(), 75000); // 1-minuta i 15 sekundi timeout
    let response;
    try {
        response = await fetch(url, request);
        clearTimeout(timeoutId);
    } catch (e) {
        if (e.name === "AbortError") {
            eventBus.emit("errorFetchingData", {
                message:
                    "Vrijeme zahtjeva je isteklo. Molimo kontaktirajte administratora."
            });
            return {
                message:
                    "Vrijeme zahtjeva je isteklo. Molimo kontaktirajte administratora."
            };
        }
        eventBus.emit("errorFetchingData", {
            message:
                "Došlo je do pogreške, molimo kontaktirajte administratora."
        });
        return {
            message:
                "Došlo je do pogreške, molimo kontaktirajte administratora."
        };
    }

    let result;
    if (response.status === 200) {
        result = await response.json();
        return result;
    }
    if (response.status === 401) {
        request.method = "POST";
        result = await response.json();
        await fetch(
            `${config.API_URL}/${config.PATHS.USERS}/${config.PATHS.LOGOUT}`,
            request
        );
        router.push({ name: "login", query: { expired: true } });
        return result;
    }
    if (response.status === 403) {
        request.method = "POST";
        result = await response.json();
        await fetch(
            `${config.API_URL}/${config.PATHS.USERS}/${config.PATHS.LOGOUT}`,
            request
        );
        router.push({
            name: "login",
            query: { expired: result.error.message }
        });
        return result;
    }
    if (!response.ok) {
        result = await response.json();
        console.error(result.error.message);
        // throw new Error(result.error.message);
    }
};
