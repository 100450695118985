<template>
    <div v-if="showSetupWizard" class="setup-wizard">
        <!-- <h2 class="setup-title">Postavljanje</h2> -->
        <div class="setup-grid">
            <div
                v-for="step in incompleteSteps"
                :key="step.id"
                class="setup-card"
            >
                <h3 class="setup-card-title">
                    {{ step.rb }}. {{ step.title }}
                </h3>
                <p class="setup-card-description">{{ step.description }}</p>
                <button class="setup-card-button" @click="completeStep(step)">
                    {{ step.actionText }}
                </button>
            </div>
        </div>
    </div>
</template>

<script>
import { ref, computed, onMounted } from "vue";
import { checkSubscriptionLimit } from "@/utils/checkSubscriptionLimits";
import { useRouter } from "vue-router";
import eventBus from "../../eventBus.js";
import { useDomainUserStore } from "@/stores/customDomainUserStore.js";
import { useLookupStore } from "@/stores/customLookupStore.js";
import { domain } from "@/stores/domainStore.js";
import notify from "devextreme/ui/notify";

export default {
    name: "setupWizardComponent",
    setup() {
        // Stores completed step IDs
        const completedSteps = ref([]);
        const domainUserStore = useDomainUserStore();
        const lookupStore = useLookupStore();
        const admin = domainUserStore.getDomainUser;
        onMounted(async () => {
            await checkCompletedSteps();
        });
        const showSetupWizard = ref(false);
        const router = useRouter();
        // Setup steps
        const steps = [
            {
                id: 6,
                rb: 1,
                route: "Cijenik",
                title: "Definirajte cijenik",
                description:
                    "Potrebno je definirati cijenik kako bi mogli dodati stavke cijenika na dokumente.",
                actionText: "Definiraj",
                required: true
            },
            {
                id: 5,
                rb: 2,
                title: "Definirajte skladište",
                route: "Skladište",
                description:
                    "Definirajte skladište kako bi mogli pratiti stanje robe.",
                actionText: "Definiraj",
                required: true
            },
            {
                id: 6,
                rb: 3,
                route: "Cijenik",
                title: "Dodajte stavke cijenika",
                description: "Dodajte stavke na cijenik.",
                actionText: "Dodaj",
                required: true
            },
            {
                id: 8,
                rb: 4,
                title: "Dodajte kupce",
                route: "Kupci",
                description:
                    "Dodajte kupce u listu kupaca kako bi mogli napraviti račune, ponude, otpremnice.",
                actionText: "Dodaj",
                required: true
            },
            {
                id: 7,
                rb: 5,
                title: "Dodajte dobavljače",
                route: "Dobavljači",
                description:
                    "Dodajte dobavljače u listu dobavljača kako bi mogli napraviti primke.",
                actionText: "Dodaj",
                required: true
            },
            {
                rb: 6,
                title: "Zaglavlje i podnožje dokumenata",
                route: "Postavke",
                description:
                    "Postavite zaglavlje i podnožje dokumenata koji odgovaraju zahtjevima Vaše tvrtke.",
                actionText: "Postavi",
                required: false
            },
            {
                rb: 7,
                title: "Podaci o tvrtki (Opcionalno)",
                route: "Postavke",
                description:
                    "Postavite podatke o tvrtki kako bi imali mogućnost slanja e-Računa prema obveznicima javne nabave.",
                actionText: "Postavi",
                required: false
            }
        ];

        // Filter out completed steps
        const incompleteSteps = computed(() =>
            steps.filter((step) => !completedSteps.value.includes(step.rb))
        );
        const checkCompletedSteps = async () => {
            const domainData = await domain.byKey(admin.domainId);
            const priceList = await checkSubscriptionLimit("priceList");
            const priceListItem = await checkSubscriptionLimit("priceListItem");
            const warehouse = await checkSubscriptionLimit("warehouse");
            const customer = await checkSubscriptionLimit("customer");
            const supplier = await checkSubscriptionLimit("supplier");
            if (priceList.count > 0) {
                completedSteps.value.push(1);
            }
            if (warehouse.count > 0) {
                completedSteps.value.push(2);
            }
            if (priceListItem.count > 0) {
                completedSteps.value.push(3);
            }
            if (customer.count > 0) {
                completedSteps.value.push(4);
            }
            if (supplier.count > 0) {
                completedSteps.value.push(5);
            }
            if (domainData.documentSections !== null) {
                completedSteps.value.push(6);
            }
            if (incompleteSteps.value[0].rb !== 7) {
                showSetupWizard.value = true;
            }
        };
        // Mark a step as completed
        async function completeStep(step) {
            // completedSteps.value.push(id);
            router.push({ name: step.route });
            if (step.id && step.id !== 8) {
                try {
                    const res = await lookupStore.fetchLookups(
                        admin.domainId,
                        step.id
                    );
                    if (res.success) {
                        eventBus.emit("lookupLoading", false);
                    } else {
                        notify(
                            {
                                message: res.message,
                                width: 450
                            },
                            "error",
                            2000
                        );
                    }
                } catch (e) {
                    console.error(e);
                }
            }
        }

        return { incompleteSteps, completeStep, showSetupWizard };
    }
};
</script>

<style scoped>
/* Setup Wizard Container */
.setup-wizard {
    padding: 20px;
    /* background-color: #f9f9f9; */
    border-radius: 8px;
}

/* Title */
.setup-title {
    font-size: 22px;
    font-weight: bold;
    margin-bottom: 16px;
}

/* Grid Layout */
.setup-grid {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(350px, 1fr));
    gap: 16px;
}

/* Card Styling */
.setup-card {
    /* background: white; */
    padding: 16px;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    border: 1px solid #ddd;
}

/* Card Title */
.setup-card-title {
    font-size: 18px;
    font-weight: bold;
    margin-bottom: 8px;
}

/* Card Description */
.setup-card-description {
    font-size: 14px;
    /* color: #666; */
    margin-bottom: 12px;
}

/* Button */
.setup-card-button {
    background: #007bff;
    color: white;
    padding: 8px 12px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    font-size: 14px;
    transition: background 0.3s ease;
}

.setup-card-button:hover {
    background: #0056b3;
}
</style>
