import CustomStore from "devextreme/data/custom_store";
import { sendRequest } from "@/helpers/sendRequestHelper";
import config from "../../config.json";
import eventBus from "../../eventBus";
let userDomainId;
let setBusinessYearId;
let isSubscribed = false; // Prevent duplicate subscriptions
const subscribeToEvent = () => {
    if (!isSubscribed) {
        eventBus.on("newUserLoggedIn", (data) => {
            userDomainId = data.userDomainId;
            setBusinessYearId =
                Number(localStorage.getItem("businessYearId")) || 15;
        });
        eventBus.on("newBusinessYearSelected", (data) => {
            setBusinessYearId = data.businessYearId;
        });
        isSubscribed = true;
    }
};
subscribeToEvent();
export const warehouse = new CustomStore({
    key: "id",
    load: async (loadOptions) => {
        try {
            if (userDomainId) {
                const filterCondition = {
                    where: {
                        and: [
                            {
                                domainId: userDomainId
                            },
                            { businessYearId: setBusinessYearId }
                        ]
                    }
                };
                if (
                    loadOptions.searchValue !== null &&
                    loadOptions.searchValue !== ""
                ) {
                    filterCondition.where.and.push({
                        name: {
                            regexp: `/${loadOptions.searchValue}/i`
                        }
                    });
                } else {
                    filterCondition.where.and.splice(2, 1);
                    // console.log(filterCondition.where.and);
                }
                const newData = sendRequest(
                    `${config.API_URL}/${
                        config.PATHS.WAREHOUSE
                    }?filter=${JSON.stringify(filterCondition)}`
                );
                return newData;
            }
        } catch (error) {
            console.error("Error loading data:", error);
            throw error;
        }
    },
    totalCount: async (key) => {
        try {
            const filterCondition = {
                domainId: key
            };
            const res = await sendRequest(
                `${config.API_URL}/${
                    config.PATHS.WAREHOUSE
                }/count?where=${JSON.stringify(filterCondition)}`
            );
            return res.count;
        } catch (error) {
            console.error("Error loading data:", error);
            throw error;
        }
    },
    byKey: async (key) => {
        try {
            const res = await sendRequest(
                `${config.API_URL}/${config.PATHS.WAREHOUSE}/${key}`
            );
            return res;
        } catch (error) {
            console.error("Error loading data:", error);
            throw error;
        }
    },
    insert: async (values) => {
        try {
            const res = await sendRequest(
                `${config.API_URL}/${config.PATHS.WAREHOUSE}`,
                "POST",
                {
                    values: values
                }
            );
            return res;
        } catch (error) {
            console.error("Error inserting data:", error);
            throw error;
        }
    },
    update: async (id, values) => {
        try {
            await sendRequest(
                `${config.API_URL}/${config.PATHS.WAREHOUSE}/${id}`,
                "PATCH",
                {
                    values: values
                }
            );
        } catch (error) {
            console.error("Error updating data:", error);
            throw error;
        }
    },
    remove: async (id) => {
        try {
            await sendRequest(
                `${config.API_URL}/${config.PATHS.WAREHOUSE}/${id}`,
                "DELETE"
            );
        } catch (error) {
            console.error("Error removing data:", error);
            throw error;
        }
    }
});
