import CustomStore from "devextreme/data/custom_store";
import { sendRequest } from "@/helpers/sendRequestHelper";
import config from "../../config.json";
import eventBus from "../../eventBus";

let userDomainId;
let isSubscribed = false; // Prevent duplicate subscriptions
const subscribeToEvent = () => {
    if (!isSubscribed) {
        eventBus.on("newUserLoggedIn", (data) => {
            userDomainId = data.userDomainId;
        });
        isSubscribed = true;
    }
};
subscribeToEvent();
export const role = new CustomStore({
    key: "id",
    load: async (loadOptions) => {
        try {
            let filterCondition;
            if (userDomainId) {
                filterCondition = {
                    where: {
                        domainId: userDomainId
                    }
                };
            } else if (loadOptions.domainId) {
                filterCondition = {
                    where: {
                        domainId: loadOptions.domainId
                    }
                };
            }
            let res = await sendRequest(
                `${config.API_URL}/${config.PATHS.ROLE}?filter=${JSON.stringify(
                    filterCondition
                )}`
            );
            res = res.filter((el) => {
                return el.name !== "superadmin";
            });
            return res;
        } catch (error) {
            console.error("Error loading data:", error);
            throw error;
        }
    },
    byKey: async (key) => {
        try {
            const res = await sendRequest(
                `${config.API_URL}/${config.PATHS.ROLE}/${key}`
            );
            return res;
        } catch (error) {
            console.error("Error loading data:", error);
            throw error;
        }
    },
    insert: async (values) => {
        try {
            const res = await sendRequest(
                `${config.API_URL}/${config.PATHS.ROLE}`,
                "POST",
                {
                    values: values
                }
            );
            return res;
        } catch (error) {
            console.error("Error inserting data:", error);
            throw error;
        }
    },
    update: async (id, values) => {
        try {
            await sendRequest(
                `${config.API_URL}/${config.PATHS.ROLE}/${id}`,
                "PATCH",
                {
                    values: values
                }
            );
        } catch (error) {
            console.error("Error updating data:", error);
            throw error;
        }
    }
});
