<template>
    <!-- <div class="table-container"> -->
    <!-- <DxProgressBar
            id="progress-bar-status"
            :min="0"
            :max="50"
            :value="30"
            :status-format="
                (ration, value) => {
                    return `${value}/50`;
                }
            "
        /> -->
    <h1>Dobar dan, {{ adminUsername }}! 🎉</h1>
    <!-- <button @click="test">TEST</button> -->
    <setup-wizard-component />
    <!-- </div> -->
</template>
<script>
import { useDomainUserStore } from "@/stores/customDomainUserStore";
import SetupWizardComponent from "@/components/SetupWizardComponent.vue";
// import { sendRequest } from "@/helpers/sendRequestHelper";
// import { DxProgressBar } from "devextreme-vue/progress-bar";
import { ref } from "vue";

export default {
    name: "dashboardComponent",
    components: {
        // DxProgressBar
        SetupWizardComponent
    },
    setup() {
        const domainUserStore = useDomainUserStore();
        const admin = domainUserStore.getDomainUser;
        const adminUsername = ref(admin.username);
        // const test = async () => {
        //     const res = await sendRequest(
        //         "http://localhost:3010/send-outgoing-invoice-b2g-billing",
        //         "POST",
        //         { values: { invoiceId: 135504 } }
        //     );
        //     console.log(res);
        // };
        return {
            adminUsername
            // test
        };
    }
};
</script>
<style scoped>
.table-container {
    margin: 2vh;
    max-width: 100%;
}
#offerItemTable {
    width: 100%;
}
</style>
