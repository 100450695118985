import { reactive, toRefs } from "vue";
import { user } from "@/stores/userStore";
import { customer } from "@/stores/customerStore";
import { domain } from "@/stores/domainStore";
import { invoice } from "@/stores/invoiceStore";
import { warehouse } from "@/stores/warehouseStore";
import { businessYear } from "@/stores/businessYearStore";
import { dispatchNote } from "@/stores/dispatchNoteStore";
import { dispatchNoteItem } from "@/stores/dispatchNoteItemStore";
import { domainUser } from "@/stores/domainUserStore";
import { warehouseItemStatus } from "@/stores/warehouseItemStatusStore";
import { priceListItem } from "@/stores/priceListItemStore";
import { useDomainUserStore } from "@/stores/customDomainUserStore";
// import // updateWarehouseItemStatusOnNewDispatchNote,
// updateWarehouseItemStatusOnRemovedDispatchNote,
// updateWarehouseItemStatusOnUpdatedDispatchNote,
// "@/utils/updateWarehouseItemStatus";
import { changesLog } from "@/stores/changesLogStore";
import { useWarehouseItemStore } from "@/stores/customWarehouseItemStatusStore";
import { useLookupStore } from "@/stores/customLookupStore";

export function useDispatchNote() {
    const domainUserStore = useDomainUserStore();
    const warehouseItemStatusStore = useWarehouseItemStore();
    const lookupStore = useLookupStore();
    const state = reactive({
        customer,
        user,
        domain,
        invoice,
        warehouse,
        businessYear,
        dispatchNote,
        dispatchNoteItem,
        priceListItem,
        domainUser,
        warehouseItemStatus,
        rowsToBeDeleted: [],
        dispatchNoteItemData: [],
        editingDispatchNoteItemOldData: [],
        editingDispatchNoteItemWarehouseItemStatus: [],
        snapshotDispatchNoteItems: [],
        newEditingDispatchNoteItemWarehouseItemStatus: [],
        editingDispatchNote: null,
        editingDispatchNoteItem: null,
        columnChooserMode: "select",
        priceTotalFormat: {
            type: "fixedPoint",
            precision: 2
        },
        admin: domainUserStore.getDomainUser,
        domainPermissions: domainUserStore.domainPermissions,
        businessYearId: localStorage.getItem("businessYearId") || 15
    });
    function handleRowInsertingDispatchNote(e, number, date) {
        const now = new Date().toISOString();
        delete e.data.id;
        e.data = {
            ...e.data,
            number: number,
            date: date,
            createdById: state.admin.id,
            modifiedById: state.admin.id,
            createdDate: now,
            modifiedDate: now,
            domainId: state.admin.domainId,
            status: "active"
        };
    }
    async function handleRowInsertedDispatchNote(e) {
        let dispatchNotePriceTotal = 0;
        const now = new Date().toISOString();
        const allItemPromises = state.dispatchNoteItemData.map((item) => {
            if (item.id) {
                delete item.id;
            }
            delete item.__KEY__;
            Object.assign(item, {
                dispatchNoteId: e.key,
                priceTotal: Number(item.priceTotal),
                createdById: state.admin.id,
                modifiedById: state.admin.id,
                createdDate: now,
                modifiedDate: now,
                status: "active"
            });
            dispatchNotePriceTotal += item.priceTotal;
            return item;
            // return Promise.all([
            //     dispatchNoteItem.insert(item),
            //     // updateWarehouseItemStatusOnNewDispatchNote(
            //     //     item,
            //     //     e.data.warehouseId
            //     // ),
            // ]);
        });
        // await Promise.all(allItemPromises);
        await dispatchNote.update(e.key, {
            priceTotal: Number(dispatchNotePriceTotal)
        });
        state.dispatchNoteItemData = [];

        const updatedDispatchNote = await dispatchNote.byKey(e.key);
        const logData = [{ dispatchNote: updatedDispatchNote }];
        const dispatchNoteItems = await dispatchNoteItem.load({
            dispatchNoteId: e.key
        });
        dispatchNoteItems.forEach((item) => {
            logData.push({ item });
        });
        const changesLogData = {
            actionType: "INSERT",
            documentType: "dispatch_note",
            oldValue: null,
            newValue: JSON.stringify(logData),
            modifiedById: state.admin.id,
            modifiedDate: new Date().toISOString()
        };
        await changesLog.insert(changesLogData);
        await warehouseItemStatusStore.updateWarehouseItemStatusOnNewDispatchNote(
            {
                warehouseId: e.data.warehouseId,
                items: allItemPromises
            }
        );
        await lookupStore.fetchLookups(state.admin.domainId);
    }
    async function handleEditingStartDispatchNote(e) {
        state.editingDispatchNote = e.data;
        await dispatchNoteItem.load();
    }
    function handleEditCanceledDispatchNote() {
        state.dispatchNoteItemData = [];
        state.rowsToBeDeleted = [];
    }
    async function handleRowUpdatingDispatchNote(e) {
        let dispatchNotePriceTotal = 0;
        const now = new Date().toISOString();
        // const allPromises = [];
        const snapshotDispatchNoteItems =
            state.snapshotDispatchNoteItems.length === 0
                ? [...state.dispatchNoteItemData]
                : [...state.snapshotDispatchNoteItems];
        const oldDispatchNote = await dispatchNote.byKey(e.key);
        const oldDispatchNoteItems = await dispatchNoteItem.load({
            dispatchNoteId: e.key
        });
        const oldDataLog = [{ dispatchNote: oldDispatchNote }];
        oldDispatchNoteItems.forEach((item) => {
            oldDataLog.push({ item });
        });

        // const deletePromises = state.rowsToBeDeleted.map((item) => {
        //     return dispatchNoteItem
        //         .remove(item.id)
        //         .then(() => {
        //             const loadOptions = {
        //                 priceListItemId: item.priceListItemId,
        //                 warehouseId: e.oldData.warehouseId,
        //             };
        //             return warehouseItemStatus.load(loadOptions);
        //         })
        //         .then((res) => {
        //             if (res.length > 0) {
        //                 const newQty = res[0].qty + item.qty;
        //                 const updateData = {
        //                     qty: newQty,
        //                 };
        //                 return warehouseItemStatus.update(
        //                     res[0].id,
        //                     updateData
        //                 );
        //             }
        //         });
        // });
        state.rowsToBeDeleted = [];
        // allPromises.push(...deletePromises);
        // allPromises.push(
        //     updateWarehouseItemStatusOnUpdatedDispatchNote(
        //         e.oldData.warehouseId,
        //         state.newEditingDispatchNoteItemWarehouseItemStatus,
        //         state.editingDispatchNoteItemOldData,
        //         snapshotDispatchNoteItems
        //     )
        // );
        const dispatchNoteItemPromises = snapshotDispatchNoteItems.map(
            (item) => {
                if (item.id) {
                    delete item.id;
                }
                if (item.__KEY__) {
                    delete item.__KEY__;
                }
                Object.assign(item, {
                    dispatchNoteId: e.key,
                    priceTotal: Number(item.priceTotal),
                    createdById: state.admin.id,
                    modifiedById: state.admin.id,
                    createdDate: now,
                    modifiedDate: now,
                    status: "active"
                });
                dispatchNotePriceTotal += item.priceTotal;
                return item;
            }
        );
        await warehouseItemStatusStore.updateWarehouseItemStatusOnUpdatedDispatchNote(
            {
                warehouseId: e.oldData.warehouseId,
                dispatchNoteId: e.key,
                newItems: dispatchNoteItemPromises
            }
        );
        // allPromises.push(...dispatchNoteItemPromises);
        // await Promise.all(allPromises);
        dispatchNote.update(e.key, {
            priceTotal: Number(dispatchNotePriceTotal)
        });
        const updatedDispatchNote = await dispatchNote.byKey(e.key);
        const updatedDispatchNoteItems = await dispatchNoteItem.load({
            dispatchNoteId: e.key
        });

        const newDataLog = [{ dispatchNote: updatedDispatchNote }];
        updatedDispatchNoteItems.forEach((item) => {
            newDataLog.push({ item });
        });

        const changesLogData = {
            actionType: "UPDATE",
            documentType: "dispatch_note",
            oldValue: JSON.stringify(oldDataLog),
            newValue: JSON.stringify(newDataLog),
            modifiedById: state.admin.id,
            modifiedDate: now
        };

        await changesLog.insert(changesLogData);

        state.dispatchNoteItemData = [];
        state.newEditingDispatchNoteItemWarehouseItemStatus = [];
        state.editingDispatchNoteItemOldData = [];
        await lookupStore.fetchLookups(state.admin.domainId);
    }
    async function handleRowRemovingDispatchNote(e) {
        const oldDispatchNote = await dispatchNote.byKey(e.row.data.id);
        const oldDispatchNoteItems = await dispatchNoteItem.load({
            dispatchNoteId: e.row.data.id
        });
        const oldDataLog = [{ dispatchNote: oldDispatchNote }];
        oldDispatchNoteItems.forEach((item) => {
            oldDataLog.push({ item });
        });
        await dispatchNote.update(e.row.data.id, { status: "deleted" });
        await warehouseItemStatusStore.updateWarehouseItemStatusOnRemovedDispatchNote(
            {
                warehouseId: e.row.data.warehouseId,
                dispatchNoteId: e.row.data.id
            }
        );
        // const dispatchNoteItemPromises = state.dispatchNoteItemData.map(
        //     (item) => {
        //         return dispatchNoteItem
        //             .update(item.id, { status: "deleted" })
        //             .then(() => {
        //                 return updateWarehouseItemStatusOnRemovedDispatchNote(
        //                     item,
        //                     e.row.data.warehouseId
        //                 );
        //             });
        //     }
        // );
        // await Promise.all(dispatchNoteItemPromises);
        const changesLogData = {
            actionType: "DELETE",
            documentType: "dispatch_note",
            oldValue: JSON.stringify(oldDataLog),
            newValue: null,
            modifiedById: state.admin.id,
            modifiedDate: new Date().toISOString()
        };

        await changesLog.insert(changesLogData);
        await lookupStore.fetchLookups(state.admin.domainId);
    }
    function handleCancelDispatchNoteItem() {
        state.editingDispatchNoteItemOldData.pop();
        state.editingDispatchNoteItem = null;
    }
    function handleSaveNewDispatchNoteItem(qty, price, priceTotal) {
        const now = new Date().toISOString();
        if (state.editingDispatchNoteItem !== null) {
            state.editingDispatchNoteItem.qty = qty;
            state.editingDispatchNoteItem.price = price;
            state.editingDispatchNoteItem.priceTotal = Number(priceTotal);
            state.editingDispatchNoteItem.modifiedDate = now;
            state.editingDispatchNoteItem.modifiedById = Number(state.admin.id);
            state.newEditingDispatchNoteItemWarehouseItemStatus.push(
                state.editingDispatchNoteItem
            );
            const index = state.dispatchNoteItemData.findIndex((el) => {
                return (
                    (state.editingDispatchNoteItem.__KEY__ &&
                        el.__KEY__ === state.editingDispatchNoteItem.__KEY__) ||
                    el.id === state.editingDispatchNoteItem.id
                );
            });
            if (index !== -1) {
                state.dispatchNoteItemData[index] =
                    state.editingDispatchNoteItem;
                state.snapshotDispatchNoteItems = [
                    ...state.dispatchNoteItemData
                ];
            }
            // state.dispatchNoteItemData.find((el) => {
            //     if (
            //         state.editingDispatchNoteItem.__KEY__ &&
            //         el.__KEY__ === state.editingDispatchNoteItem.__KEY__
            //     ) {
            //         el = state.editingDispatchNoteItem;
            //     } else if (el.id === state.editingDispatchNoteItem.id) {
            //         el = state.editingDispatchNoteItem;
            //     }
            // });
            state.editingDispatchNoteItem = null;
        } else {
            state.editingDispatchNoteItem = null;
        }
    }
    function handleRowInsertingDispatchNoteItem(
        e,
        qty,
        price,
        priceTotal,
        priceListItemId
    ) {
        e.data = {
            ...e.data,
            qty: qty,
            price: price,
            priceTotal: priceTotal,
            priceListItemId: priceListItemId
        };
    }
    function handleEditingStartDispatchNoteItem(e) {
        state.editingDispatchNoteItem = e.data;
        const oldData = {
            ...state.editingDispatchNoteItem
        };
        state.editingDispatchNoteItemOldData.push(oldData);
    }
    return {
        ...toRefs(state),
        handleRowInsertingDispatchNote,
        handleRowInsertedDispatchNote,
        handleEditingStartDispatchNote,
        handleEditCanceledDispatchNote,
        handleRowUpdatingDispatchNote,
        handleRowRemovingDispatchNote,
        handleCancelDispatchNoteItem,
        handleSaveNewDispatchNoteItem,
        handleRowInsertingDispatchNoteItem,
        handleEditingStartDispatchNoteItem
    };
}
