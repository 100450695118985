import { reactive, toRefs } from "vue";
import { user } from "@/stores/userStore";
import { customer } from "@/stores/customerStore.js";
import { domain } from "@/stores/domainStore";
import { offer } from "@/stores/offerStore";
import { offerItem } from "@/stores/offerItemStore";
import { businessYear } from "@/stores/businessYearStore";
import { priceListItem } from "@/stores/priceListItemStore";
import { exportDataGrid as exportPDF } from "devextreme/pdf_exporter";
import eventBus from "../../eventBus.js";
import { jsPDF } from "jspdf";
import { poppins } from "@/poppinsbase64";
import { poppinsBold } from "@/poppinsboldbase64.js";
import { generateBarcode } from "@/utils/generateBarcode.js";
import { getCurrentDateTime } from "@/utils/getCurrentDateTime.js";
import { divWrapper } from "@/utils/divWrapper.js";
import { useDomainUserStore } from "@/stores/customDomainUserStore";
import { checkWatermark } from "@/utils/checkSubscriptionLimits.js";
export function useOfferItem() {
    const domainUserStore = useDomainUserStore();
    const state = reactive({
        user,
        customer,
        domain,
        offerItem,
        businessYear,
        priceListItem,
        userData: null,
        offerData: null,
        businessYearData: null,
        documentHeader: null,
        documentFooter: null,
        priceTotalFormat: {
            type: "fixedPoint",
            precision: 2
        },
        admin: domainUserStore.getDomainUser
    });
    async function getDomainData() {
        const domainData = await domain.byKey(state.admin.domainId);
        if (
            domainData.documentSections &&
            domainData.documentSections !== null
        ) {
            const sections = JSON.parse(domainData.documentSections);
            state.documentHeader = sections.header
                ? sections.header
                : "Ovdje postavite svoje zaglavlje za dokumente.";
            state.documentFooter = sections.footer
                ? sections.footer
                : "Ovdje postavite svoje podnožje za dokumente.";
        } else {
            state.documentHeader =
                "Ovdje postavite svoje zaglavlje za dokumente.";
            state.documentFooter =
                "Ovdje postavite svoje podnožje za dokumente.";
        }
        state.documentHeader = divWrapper(state.documentHeader);
        state.documentFooter = divWrapper(state.documentFooter);
    }
    async function getOfferByKey(id) {
        state.offerData = await offer.byKey(id);
    }
    async function getUserByKey() {
        state.userData =
            state.admin.domainId === 1
                ? await user.byKey(state.offerData.userId)
                : await customer.byKey(state.offerData.userId);
    }
    async function getBusinessYearByKey() {
        state.businessYearData = await businessYear.byKey(
            state.offerData.businessYearId
        );
    }
    async function handleOfferExporting(table, sendEmailFlag) {
        const doc = new jsPDF();
        doc.addFileToVFS("@/assets/Poppins-Regular.ttf", poppins);
        doc.addFileToVFS("@/assets/Poppins-Bold.ttf", poppinsBold);
        doc.addFont(
            "@/assets/Poppins-Regular.ttf",
            "Poppins-Regular",
            "normal"
        );
        doc.addFont("@/assets/Poppins-Bold.ttf", "Poppins-Bold", "bold");
        doc.setFont("Poppins-Regular");
        doc.setFontSize(8);
        const lastPoint = { x: 0, y: 0 };
        doc.setTextColor(0, 0, 0);
        exportPDF({
            jsPDFDocument: doc,
            component: table,
            repeatHeaders: true,
            topLeft: { x: 1, y: 85 },
            columnWidths: [50, 13, 12, 22, 22, 22, 17, 22],
            onRowExporting: (e) => {
                e.rowHeight = 12;
            },
            customDrawCell({ rect, gridCell, pdfCell }) {
                if (gridCell.rowType === "header") {
                    pdfCell.font.size = 10;
                } else if (gridCell.rowType === "data") {
                    pdfCell.font.size = 8;
                }
                if (lastPoint.x < rect.x + rect.w) {
                    lastPoint.x = rect.x + rect.w;
                }
                if (lastPoint.y < rect.y + rect.h) {
                    lastPoint.y = rect.y + rect.h;
                }
            },
            customizeCell({ gridCell, pdfCell }) {
                pdfCell.wordWrapEnabled = true;
                if (gridCell.rowType === "header") {
                    pdfCell.textColor = "#000000";
                    pdfCell.horizontalAlign = "center";
                    pdfCell.backgroundColor = "#D3D3D3";
                    if (gridCell.column.caption === "Konačan iznos") {
                        pdfCell.text = "Iznos";
                    }
                }
            }
        }).then(async () => {
            const offerItems = await offerItem.load({
                offerId: state.offerData.id
            });
            const taxGroups = {};
            offerItems.forEach((item) => {
                const parsedItem = JSON.parse(item.priceListItem);
                const vatRate = parsedItem.vat;
                const itemNetTotal = item.priceTotal || 0;
                const itemVatAmount = (itemNetTotal * vatRate) / 100;
                if (!taxGroups[vatRate]) {
                    taxGroups[vatRate] = { TaxableAmount: 0, TaxAmount: 0 };
                }
                taxGroups[vatRate].TaxableAmount += itemNetTotal;
                taxGroups[vatRate].TaxAmount += itemVatAmount;
            });
            const taxSubtotals = Object.keys(taxGroups).map((rate) => ({
                taxName: `PDV ${rate}%`,
                taxPercent: rate,
                taxableAmount: taxGroups[rate].TaxableAmount.toFixed(2),
                taxAmount: taxGroups[rate].TaxAmount.toFixed(2)
            }));
            const pageHeight = doc.internal.pageSize.getHeight();
            //User data
            const userNameLong =
                state.userData.userType === "business"
                    ? state.userData.companyName
                    : `${state.userData.name} ${state.userData.lastname}`;
            const userName =
                userNameLong.length > 45
                    ? `${userNameLong.substring(0, 44)}...`
                    : userNameLong;
            const userPhone =
                state.userData.tel !== "" && state.userData.tel !== null
                    ? `Tel: ${state.userData.tel}`
                    : state.userData.mobile !== "" &&
                      state.userData.mobile !== null
                    ? `Mob: ${state.userData.mobile}`
                    : `Tel: - `;
            const userMail =
                state.userData.email !== null && state.userData.email !== ""
                    ? `Mail: ${state.userData.email}`
                    : "Mail: -";
            const userData = {
                name: userName,
                street:
                    state.userData.address !== ""
                        ? `${state.userData.address}`
                        : "-",
                city:
                    state.userData.postalCode !== "" &&
                    state.userData.city !== ""
                        ? `${state.userData.postalCode} ${state.userData.city}`
                        : "-",
                oib:
                    state.userData.vatId !== "" &&
                    state.userData.userType === "business"
                        ? `OIB: ${state.userData.vatId}`
                        : "OIB: -",
                phone: userPhone,
                email: userMail
            };
            //General data
            const date = new Date(state.offerData.date);
            const day = date.getDate();
            const month = date.getMonth() + 1;
            const year = date.getFullYear();
            const currentDate = `Datum: ${day}.${month}.${year}`;
            const watermark = `Dokument izrađen u besplatnoj verziji aplikacije "Skladište" - skladiste.gtnet.hr`;
            const paddingBelowTable = 10;
            const textYPosition = lastPoint.y + paddingBelowTable;
            const pageWidth = doc.internal.pageSize.getWidth();
            //Company data
            // const companyData = {
            //     name: "Info Network d.o.o, OIB: 92134883875",
            //     street: "Valturska 78/1, 52100 Pula",
            //     phone: "T: +385 52 637 000; M: +385 95 731 7698",
            //     email: "Email: prodaja@gtnet.hr",
            //     commercialist: `Komercijalist: ${state.adminUsername}`,
            //     iban: "IBAN: HR3123400091110649671",
            //     swift: "Swift Code: PBZGHR2X",
            //     bank: "Privredna Banka Zagreb",
            // };
            //Offer data
            //TODO: Mjesto izdavanja u domenu
            //TODO: Način plaćanja u offer
            const createdDate = new Date(state.offerData.date);
            const expireDate = new Date(state.offerData.expireDate);
            const differenceInMilliseconds = expireDate - createdDate;
            const differenceInDays = Math.floor(
                differenceInMilliseconds / (1000 * 60 * 60 * 24)
            );
            // const remark =
            //     state.offerData.remark !== null ? state.offerData.remark : "";
            const offerData = {
                number:
                    state.offerData.createdById +
                    "-" +
                    state.offerData.number +
                    "-" +
                    year,
                place: `Mjesto izdavanja: Pula`,
                payment: `Plaćanje: avans`,
                daysToExpire: `Ponuda vrijedi: ${differenceInDays} dan/a`,
                accountNumber: `Poziv na broj: ${state.offerData.createdById}-${state.offerData.number}/${year}`,
                remark:
                    state.offerData.remark !== null
                        ? state.offerData.remark
                        : "",
                fileData: `Ponuda br: ${state.offerData.createdById}-${state.offerData.number}/${year}`,
                totalAmountDiscounted:
                    state.offerData.totalAmountDiscounted.toFixed(2),
                totalTaxAmount: state.offerData.totalTaxAmount.toFixed(2),
                totalAmountTaxed: state.offerData.totalAmountTaxed.toFixed(2),
                taxName: state.offerData.taxName
            };
            const totalAmountTaxedInCents = offerData.totalAmountTaxed * 100;
            const totalAmountTaxedInCents15 = totalAmountTaxedInCents
                .toFixed(0)
                .padStart(15, 0);
            //Barcode data
            const barcodeData = `HRVHUB30
        EUR
        ${totalAmountTaxedInCents15}
        ${userNameLong}
        ${userData.street}
        ${userData.city}
        Info Network d.o.o.
        Valturska 78/1
        52100 Pula
        HR3123400091110649671
        HR00
        ${offerData.number}
        
        Plaćanje po ponudi ${offerData.number}`;
            const barcode = generateBarcode(barcodeData);
            //Footers
            // const footerAdGtNet = "www.gtnet.hr";
            // const footerAdDP = "shop.digitalnaproizvodnja.hr";
            const footerGenerated = `Generirano:`;
            const footerDatetime = getCurrentDateTime();
            //Setting up document
            doc.setFont("Poppins-Regular", "normal");
            doc.setFontSize(10);
            doc.setPage(1);
            doc.text(userData.name, 17, 60);
            doc.setFontSize(10);
            doc.text(currentDate, pageWidth - 65, 60);
            doc.text(offerData.place, pageWidth - 65, 65);
            doc.text(offerData.payment, pageWidth - 65, 70);
            doc.text(offerData.daysToExpire, pageWidth - 65, 75);
            doc.text(offerData.accountNumber, pageWidth - 65, 80);
            doc.text(offerData.fileData, pageWidth / 2, 94, {
                align: "center"
            });
            doc.setFontSize(10);
            // doc.setFontSize(12);
            // doc.setFont("Poppins-Bold", "bold");
            // doc.text(companyData.name, 15, 15);
            // doc.text(companyData.street, 15, 20);
            // doc.setFont("Poppins-Regular", "normal");
            // doc.setFontSize(10);
            // doc.text(companyData.phone, 15, 25);
            // doc.text(companyData.email, 15, 30);
            // doc.text(companyData.commercialist, 15, 35);
            // doc.text(companyData.iban, pageWidth - 65, 15);
            // doc.text(companyData.swift, pageWidth - 65, 20);
            // doc.text(companyData.bank, pageWidth - 65, 25);
            // doc.setFontSize(10);
            doc.text(userData.street, 17, 65);
            doc.text(userData.city, 17, 70);
            doc.text(userData.oib, 17, 75);
            doc.text(userData.phone, 17, 80);
            doc.text(userData.email, 17, 85);
            doc.line(15, 87, 110, 87);
            doc.line(15, 56, 110, 56);
            doc.line(15, 56, 15, 87);
            doc.line(110, 56, 110, 87);
            if (checkWatermark()) {
                doc.setFontSize(8);
                doc.setTextColor("gainsboro");
                doc.text(watermark, 7.5, pageHeight - 10, {
                    angle: 90,
                    rotationDirection: "1"
                });
                doc.setTextColor(0, 0, 0);
            }
            doc.setFontSize(9);
            doc.setPage(doc.internal.getNumberOfPages());
            doc.setFontSize(11);
            // doc.text(
            //     "Osnovica za porez na dodatnu vrijednost (EUR):",
            //     pageWidth - 45,
            //     textYPosition,
            //     { align: "right" }
            // );
            // doc.text(
            //     `${offerData.totalAmountDiscounted}`,
            //     pageWidth - 17,
            //     textYPosition,
            //     { align: "right" }
            // );
            // doc.text(
            //     `${offerData.taxName} (EUR):`,
            //     pageWidth - 45,
            //     textYPosition + 5,
            //     {
            //         align: "right"
            //     }
            // );
            // doc.text(
            //     `${offerData.totalTaxAmount}`,
            //     pageWidth - 17,
            //     textYPosition + 5,
            //     { align: "right" }
            // );
            let textY = textYPosition;
            // Header
            doc.setFont("Poppins-Bold", "bold");
            doc.text("Stopa", pageWidth - 73, textY, { align: "right" });
            doc.text("Porez", pageWidth - 45, textY, { align: "right" });
            doc.text("Osnovica", pageWidth - 17, textY, { align: "right" });

            textY += 5; // Move down for tax details

            doc.setFont("Poppins-Regular", "normal");

            // Iterate through tax subtotals
            taxSubtotals.forEach((tax) => {
                doc.text(`${tax.taxName}`, pageWidth - 73, textY, {
                    align: "right"
                });
                doc.text(`${tax.taxAmount}`, pageWidth - 45, textY, {
                    align: "right"
                });
                doc.text(`${tax.taxableAmount}`, pageWidth - 17, textY, {
                    align: "right"
                });
                textY += 5; // Move down for the next tax row
            });

            // Draw separator line
            doc.line(pageWidth - 90, textY, pageWidth - 15, textY);

            textY += 5; // Move down for totals

            // Total tax row
            doc.setFont("Poppins-Bold", "bold");
            doc.text("Zbroj", pageWidth - 73, textY, { align: "right" });
            doc.text(`${offerData.totalTaxAmount}`, pageWidth - 45, textY, {
                align: "right"
            });
            doc.text(
                `${offerData.totalAmountDiscounted}`,
                pageWidth - 17,
                textY,
                { align: "right" }
            );

            textY += 7; // Move down for final total
            doc.line(pageWidth - 100, textY - 5, pageWidth - 15, textY - 5);
            doc.line(pageWidth - 100, textY + 3, pageWidth - 15, textY + 3);
            doc.line(pageWidth - 100, textY - 5, pageWidth - 100, textY + 3);
            doc.line(pageWidth - 15, textY - 5, pageWidth - 15, textY + 3);
            doc.setFont("Poppins-Bold", "bold");
            doc.text("Ukupno za platiti (EUR):", pageWidth - 45, textY, {
                align: "right"
            });
            doc.text(`${offerData.totalAmountTaxed}`, pageWidth - 17, textY, {
                align: "right"
            });
            // doc.line(
            //     pageWidth - 100,
            //     textYPosition + 8,
            //     pageWidth - 15,
            //     textYPosition + 8
            // );
            // doc.line(
            //     pageWidth - 100,
            //     textYPosition + 15,
            //     pageWidth - 15,
            //     textYPosition + 15
            // );
            // doc.line(
            //     pageWidth - 100,
            //     textYPosition + 8,
            //     pageWidth - 100,
            //     textYPosition + 15
            // );
            // doc.line(
            //     pageWidth - 15,
            //     textYPosition + 8,
            //     pageWidth - 15,
            //     textYPosition + 15
            // );
            // doc.setFont("Poppins-Bold", "bold");
            // doc.text(
            //     "Ukupno za platiti (EUR):",
            //     pageWidth - 45,
            //     textYPosition + 13,
            //     {
            //         align: "right"
            //     }
            // );
            // doc.text(
            //     `${offerData.totalAmountTaxed}`,
            //     pageWidth - 17,
            //     textYPosition + 13,
            //     { align: "right" }
            // );
            doc.setFont("Poppins-Regular", "normal");
            doc.addImage(
                barcode,
                "PNG",
                pageWidth - 50,
                textY + 9
                // 53,
                // 13
            );
            doc.text(
                "Napomena:\n\n" + offerData.remark.replace(/(.{50})/g, "$1\n"),
                15,
                textY + 10
            );
            doc.setFontSize(8);
            // doc.text(footerAdGtNet, 15, 281);
            // doc.text(footerAdDP, 15, 285);
            doc.text(footerGenerated, pageWidth - 35, 276);
            doc.text(footerDatetime, pageWidth - 35, 280);
            const documentName = `${state.offerData.createdById}${state.offerData.number}-${year}_${userNameLong}.pdf`;
            doc.html(state.documentHeader, {
                x: 15,
                y: 10,
                width: 180,
                windowWidth: 800,
                margin: [0, 0, 0, 0],
                callback: (doc) => {
                    doc.html(state.documentFooter, {
                        x: 15,
                        y: pageHeight - 10,
                        width: 180,
                        windowWidth: 794,
                        margin: [0, 0, 0, 0],
                        callback: (doc) => {
                            if (!sendEmailFlag) {
                                doc.save(documentName);
                            } else {
                                if (doc) {
                                    const pdfForEmail = {
                                        doc: doc,
                                        documentName: documentName
                                    };

                                    eventBus.emit(
                                        "offerPdfForEmail",
                                        pdfForEmail
                                    );
                                } else {
                                    console.error(
                                        "PDF generation failed: doc is null or undefined"
                                    );
                                }
                            }
                        }
                    });
                }
            });
        });
    }
    return {
        ...toRefs(state),
        getDomainData,
        handleOfferExporting,
        getOfferByKey,
        getUserByKey,
        getBusinessYearByKey
    };
}
