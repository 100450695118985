import CustomStore from "devextreme/data/custom_store";
import { sendRequest } from "@/helpers/sendRequestHelper";
import config from "../../config.json";
import eventBus from "../../eventBus";
//event bus
let userDomainId;
// let setBusinessYearId;
// let searchValue = null;
let isSubscribed = false; // Prevent duplicate subscriptions
const subscribeToEvent = () => {
    if (!isSubscribed) {
        eventBus.on("newUserLoggedIn", (data) => {
            userDomainId = data.userDomainId;
            // setBusinessYearId =
            //     Number(localStorage.getItem("businessYearId")) || 15;
        });
        isSubscribed = true;
    }
};
subscribeToEvent();
export const emailLog = new CustomStore({
    key: "id",
    load: async () => {
        try {
            if (userDomainId) {
                let filterCondition = {
                    where: {
                        and: [
                            {
                                domainId: userDomainId
                            }
                            // { businessYearId: setBusinessYearId }
                        ]
                    }
                };
                // if (loadOptions.businessYearId || loadOptions.warehouseId) {
                //     filterCondition = {
                //         where: {
                //             and: [
                //                 { domainId: userDomainId },
                //                 { businessYearId: loadOptions.businessYearId },
                //                 { warehouseId: loadOptions.warehouseId }
                //             ]
                //         },
                //         order: "number DESC",
                //         limit: 1
                //     };
                // }
                // if (loadOptions.invoiceId) {
                //     filterCondition = {
                //         where: {
                //             and: [
                //                 { domainId: userDomainId },
                //                 {
                //                     businessYearId: loadOptions.businessYearId
                //                 },
                //                 {
                //                     invoiceId: loadOptions.invoiceId
                //                 }
                //             ]
                //         }
                //     };
                // }
                // if (searchValue !== null && searchValue !== "") {
                //     filterCondition = {
                //         where: {
                //             and: [
                //                 {
                //                     domainId: userDomainId
                //                 },
                //                 { businessYearId: setBusinessYearId }
                //             ]
                //         },
                //         searchValue: searchValue
                //     };
                //     const newData = await sendRequest(
                //         `${config.API_URL}/${
                //             config.PATHS.DISPATCH_NOTE_FILTER
                //         }?filter=${JSON.stringify(filterCondition)}`
                //     );
                //     // console.log(newData);
                //     searchValue = null;
                //     return newData;
                // }
                const newData = await sendRequest(
                    `${config.API_URL}/${
                        config.PATHS.EMAIL_LOG
                    }?filter=${JSON.stringify(filterCondition)}`
                );
                return newData;
            }
        } catch (error) {
            console.error("Error loading data:", error);
            throw error;
        }
    },
    byKey: async (key) => {
        try {
            const res = await sendRequest(
                `${config.API_URL}/${config.PATHS.DISPATCH_NOTE}/${key}`
            );
            return res;
        } catch (error) {
            console.error("Error loading data:", error);
            throw error;
        }
    }
});
