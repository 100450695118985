import { defineStore } from "pinia";
import config from "../../config.json";
import { sendRequest } from "@/helpers/sendRequestHelper";

export const useEmailStore = defineStore("emailStore", {
    actions: {
        async sendEmail(values) {
            try {
                const res = await sendRequest(
                    `${config.API_URL}/${config.PATHS.SEND_EMAIL}`,
                    "POST",
                    {
                        values: values
                    }
                );
                return res;
            } catch (error) {
                console.error(error);
                throw error;
            }
        }
    }
});
