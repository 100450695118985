import { reactive, toRefs } from "vue";

import { domain } from "@/stores/domainStore";
import { warehouse } from "@/stores/warehouseStore";
import { domainUser } from "@/stores/domainUserStore";
import { businessYear } from "@/stores/businessYearStore";
import { priceList } from "@/stores/priceListStore";
import { priceListItem } from "@/stores/priceListItemStore";
import { warehouseItemStatus } from "@/stores/warehouseItemStatusStore.js";
import { intermediateWarehouse } from "@/stores/intermediateWarehouseStore.js";
import { intermediateWarehouseItem } from "@/stores/intermediateWarehouseItemStore.js";

import // updateWarehouseItemStatusOnNewIntermediateWarehouse,
// updateWarehouseItemStatusOnUpdatedIntermediateWarehouse
// updateWarehouseItemStatusOnRemovedIntermediateWarehouse
"@/utils/updateWarehouseItemStatus";
import { useDomainUserStore } from "@/stores/customDomainUserStore";
import { changesLog } from "@/stores/changesLogStore";
import { useWarehouseItemStore } from "@/stores/customWarehouseItemStatusStore";
import { useLookupStore } from "@/stores/customLookupStore";

export function useIntermediateWarehouse() {
    const domainUserStore = useDomainUserStore();
    const warehouseItemStatusStore = useWarehouseItemStore();
    const lookupStore = useLookupStore();
    const state = reactive({
        domain,
        warehouse,
        domainUser,
        businessYear,
        priceList,
        priceListItem,
        warehouseItemStatus,
        intermediateWarehouse,
        intermediateWarehouseItem,
        editingIntermediateWarehouse: null,
        editingIntermediateWarehouseItem: null,
        rowsToBeDeleted: [],
        intermediateWarehouseItemData: [],
        editingIntermediateWarehouseItemOldData: [],
        snapshotIntermediateWarehouseItems: [],
        newEditingIntermediateWarehouseWarehouseItemStatus: [],
        columnChooserMode: "select",
        priceTotalFormat: {
            type: "fixedPoint",
            precision: 2
        },
        admin: domainUserStore.getDomainUser,
        domainPermissions: domainUserStore.domainPermissions,
        businessYearId: localStorage.getItem("businessYearId") || 15
    });

    function handleRowInsertingIntermediateWarehouse(e, number, date) {
        const now = new Date().toISOString();
        delete e.data.id;
        e.data = {
            ...e.data,
            date: date,
            number: number,
            createdById: state.admin.id,
            modifiedById: state.admin.id,
            createdDate: now,
            modifiedDate: now,
            domainId: state.admin.domainId,
            status: "active"
        };
    }
    async function handleRowInsertedIntermediateWarehouse(e) {
        let intermediateWarehousePriceTotal = 0;
        const now = new Date().toISOString();
        const allItemPromises = state.intermediateWarehouseItemData.map(
            (item) => {
                if (item.id) {
                    delete item.id;
                }
                delete item.__KEY__;
                Object.assign(item, {
                    intermediateWarehouseId: e.key,
                    priceTotal: Number(item.priceTotal),
                    createdById: state.admin.id,
                    modifiedById: state.admin.id,
                    createdDate: now,
                    modifiedDate: now,
                    status: "active"
                });
                intermediateWarehousePriceTotal += item.priceTotal;
                return item;
            }
        );
        // for (let i = 0; i < state.intermediateWarehouseItemData.length; i++) {
        //     const item = state.intermediateWarehouseItemData[i];
        //     if (item.id) {
        //         delete item.id;
        //     }
        //     delete item.__KEY__;
        //     item.intermediateWarehouseId = e.key;
        //     item.priceTotal = Number(item.priceTotal);
        //     item.createdById = state.admin.id;
        //     item.modifiedById = state.admin.id;
        //     item.createdDate = now;
        //     item.modifiedDate = now;
        //     item.status = "active";
        //     intermediateWarehousePriceTotal += item.priceTotal;
        //     return item;
        //     // intermediateWarehouseItem.insert(item);
        //     // await updateWarehouseItemStatusOnNewIntermediateWarehouse(
        //     //     item,
        //     //     e.data.fromWarehouseId,
        //     //     e.data.toWarehouseId
        //     // );
        // }
        intermediateWarehouse.update(e.key, {
            priceTotal: Number(intermediateWarehousePriceTotal)
        });
        state.intermediateWarehouseItemData = [];
        const updatedIntermediateWarehouse = await intermediateWarehouse.byKey(
            e.key
        );
        const logData = [
            { intermediateWarehouse: updatedIntermediateWarehouse }
        ];
        const intermediateWarehouseItems = await intermediateWarehouseItem.load(
            { intermediateWarehouseId: e.key }
        );
        intermediateWarehouseItems.forEach((item) => {
            logData.push({ item });
        });
        const changesLogData = {
            actionType: "INSERT",
            documentType: "intermediate_warehouse",
            oldValue: null,
            newValue: JSON.stringify(logData),
            modifiedById: state.admin.id,
            modifiedDate: new Date().toISOString()
        };
        await changesLog.insert(changesLogData);
        await warehouseItemStatusStore.updateWarehouseItemStatusOnNewIntermediateWarehouse(
            {
                fromWarehouseId: e.data.fromWarehouseId,
                toWarehouseId: e.data.toWarehouseId,
                items: allItemPromises
            }
        );
        await lookupStore.fetchLookups(state.admin.domainId);
    }
    async function handleEditingStartIntermediateWarehouse(e) {
        state.editingIntermediateWarehouse = e.data;
        await intermediateWarehouseItem.load();
    }
    function handleEditCanceledIntermediateWarehouse() {
        state.rowsToBeDeleted = [];
        state.intermediateWarehouseItemData = [];
    }
    async function handleRowUpdatingIntermediateWarehouse(e) {
        let intermediateWarehousePriceTotal = 0;
        const now = new Date().toISOString();
        // const allPromises = [];
        const snapshotIntermediateWarehouseItems =
            state.snapshotIntermediateWarehouseItems.length === 0
                ? [...state.intermediateWarehouseItemData]
                : [...state.snapshotIntermediateWarehouseItems];
        const oldIntermediateWarehouse = await intermediateWarehouse.byKey(
            e.key
        );
        const oldIntermediateWarehouseItems =
            await intermediateWarehouseItem.load({
                intermediateWarehouseId: e.key
            });
        const oldDataLog = [
            { intermediateWarehouse: oldIntermediateWarehouse }
        ];
        oldIntermediateWarehouseItems.forEach((item) => {
            oldDataLog.push({ item });
        });

        // const deletePromises = state.rowsToBeDeleted.map((item) => {
        //     intermediateWarehouseItem
        //         .remove(item.id)
        //         .then(() => {
        //             const loadOptions = {
        //                 priceListItemId: item.priceListItemId,
        //                 warehouseId: e.oldData.toWarehouseId
        //             };
        //             return warehouseItemStatus.load(loadOptions);
        //         })
        //         .then((res) => {
        //             const updateData = {
        //                 qty: res[0].qty - item.qty
        //             };
        //             return warehouseItemStatus.update(res[0].id, updateData);
        //         })
        //         .then(() => {
        //             const loadOptions = {
        //                 priceListItemId: item.priceListItemId,
        //                 warehouseId: e.oldData.fromWarehouseId
        //             };
        //             return warehouseItemStatus.load(loadOptions);
        //         })
        //         .then((res) => {
        //             const updateData = {
        //                 qty: res[0].qty + item.qty
        //             };
        //             return warehouseItemStatus.update(res[0].id, updateData);
        //         });
        // });
        state.rowsToBeDeleted = [];
        // allPromises.push(...deletePromises);
        // allPromises.push(
        //     updateWarehouseItemStatusOnUpdatedIntermediateWarehouse(
        //         state.newEditingIntermediateWarehouseWarehouseItemStatus,
        //         state.editingIntermediateWarehouseItemOldData,
        //         snapshotIntermediateWarehouseItems,
        //         e.oldData.fromWarehouseId,
        //         e.oldData.toWarehouseId
        //     )
        // );
        const insertPromises = snapshotIntermediateWarehouseItems.map(
            (item) => {
                if (item.id) {
                    delete item.id;
                }
                if (item.__KEY__) {
                    delete item.__KEY__;
                }
                Object.assign(item, {
                    intermediateWarehouseId: e.key,
                    priceTotal: Number(item.priceTotal),
                    createdById: state.admin.id,
                    modifiedById: state.admin.id,
                    createdDate: now,
                    modifiedDate: now,
                    status: "active"
                });
                intermediateWarehousePriceTotal += item.priceTotal;
                return item;
            }
        );
        await warehouseItemStatusStore.updateWarehouseItemStatusOnUpdatedIntermediateWarehouse(
            {
                intermediateWarehouseId: e.key,
                fromWarehouseId: e.oldData.fromWarehouseId,
                toWarehouseId: e.oldData.toWarehouseId,
                newItems: insertPromises
            }
        );
        // allPromises.push(...insertPromises);
        // await Promise.all(allPromises);
        await intermediateWarehouse.update(e.key, {
            priceTotal: Number(intermediateWarehousePriceTotal)
        });
        const updatedIntermediateWarehouse = await intermediateWarehouse.byKey(
            e.key
        );
        const updatedIntermediateWarehouseItems =
            await intermediateWarehouseItem.load({
                intermediateWarehouseId: e.key
            });

        const newDataLog = [
            { intermediateWarehouse: updatedIntermediateWarehouse }
        ];
        updatedIntermediateWarehouseItems.forEach((item) => {
            newDataLog.push({ item });
        });

        const changesLogData = {
            actionType: "UPDATE",
            documentType: "intermediate_warehouse",
            oldValue: JSON.stringify(oldDataLog),
            newValue: JSON.stringify(newDataLog),
            modifiedById: state.admin.id,
            modifiedDate: now
        };

        await changesLog.insert(changesLogData);

        state.intermediateWarehouseItemData = [];
        state.newEditingIntermediateWarehouseWarehouseItemStatus = [];
        state.editingIntermediateWarehouseItemOldData = [];
        await lookupStore.fetchLookups(state.admin.domainId);
    }
    async function handleRowRemovingIntermediateWarehouse(e) {
        const oldIntermediateWarehouse = await intermediateWarehouse.byKey(
            e.row.data.id
        );
        const oldIntermediateWarehouseItems =
            await intermediateWarehouseItem.load({
                intermediateWarehouseId: e.row.data.id
            });
        const oldDataLog = [
            { intermediateWarehouse: oldIntermediateWarehouse }
        ];
        oldIntermediateWarehouseItems.forEach((item) => {
            oldDataLog.push({ item });
        });
        await intermediateWarehouse.update(e.row.data.id, {
            status: "deleted"
        });
        await warehouseItemStatusStore.updateWarehouseItemStatusOnRemovedIntermediateWarehouse(
            {
                intermediateWarehouseId: e.row.data.id,
                fromWarehouseId: e.row.data.fromWarehouseId,
                toWarehouseId: e.row.data.toWarehouseId
            }
        );
        // const deletePromises = state.intermediateWarehouseItemData.map(
        //     (item) => {
        //         return Promise.all([
        //             intermediateWarehouseItem.update(item.id, {
        //                 status: "deleted"
        //             }),
        //             updateWarehouseItemStatusOnRemovedIntermediateWarehouse(
        //                 item,
        //                 e.row.data.fromWarehouseId,
        //                 e.row.data.toWarehouseId
        //             )
        //         ]);
        //     }
        // );
        // await Promise.all(deletePromises);
        const changesLogData = {
            actionType: "DELETE",
            documentType: "intermediate_warehouse",
            oldValue: JSON.stringify(oldDataLog),
            newValue: null,
            modifiedById: state.admin.id,
            modifiedDate: new Date().toISOString()
        };

        await changesLog.insert(changesLogData);
        await lookupStore.fetchLookups(state.admin.domainId);
    }
    function handleCancelItermediateWarehouseItem() {
        state.editingIntermediateWarehouseItemOldData.pop();
        state.editingIntermediateWarehouseItem = null;
    }
    function handleSaveNewIntermediateWarehouseItem(qty, price, priceTotal) {
        const now = new Date().toISOString();
        if (state.editingIntermediateWarehouseItem !== null) {
            state.editingIntermediateWarehouseItem.qty = qty;
            state.editingIntermediateWarehouseItem.price = price;
            state.editingIntermediateWarehouseItem.priceTotal =
                Number(priceTotal);
            state.editingIntermediateWarehouseItem.modifiedDate = now;
            state.editingIntermediateWarehouseItem.modifiedById = Number(
                state.admin.id
            );
            state.newEditingIntermediateWarehouseWarehouseItemStatus.push(
                state.editingIntermediateWarehouseItem
            );

            const index = state.intermediateWarehouseItemData.findIndex(
                (el) => {
                    return (
                        (state.editingIntermediateWarehouseItem.__KEY__ &&
                            el.__KEY__ ===
                                state.editingIntermediateWarehouseItem
                                    .__KEY__) ||
                        el.id === state.editingIntermediateWarehouseItem.id
                    );
                }
            );
            if (index !== -1) {
                state.intermediateWarehouseItemData[index] =
                    state.editingIntermediateWarehouseItem;
                state.snapshotIntermediateWarehouseItems = [
                    ...state.intermediateWarehouseItemData
                ];
            }
            // state.intermediateWarehouseItemData.find((el) => {
            //     if (
            //         state.editingIntermediateWarehouseItem.__KEY__ &&
            //         el.__KEY__ == state.editingIntermediateWarehouseItem.__KEY__
            //     ) {
            //         el = state.editingIntermediateWarehouseItem;
            //     } else if (
            //         el.id === state.editingIntermediateWarehouseItem.id
            //     ) {
            //         el = state.editingIntermediateWarehouseItem;
            //     }
            // });

            state.editingIntermediateWarehouseItem = null;
        } else {
            state.editingIntermediateWarehouseItem = null;
        }
    }
    function handleRowInsertingIntermediateWarehouseItem(
        e,
        qty,
        price,
        priceTotal,
        priceListItemId
    ) {
        e.data = {
            ...e.data,
            qty: qty,
            price: price,
            priceTotal: Number(priceTotal),
            priceListItemId: priceListItemId
        };
    }
    function handleEditingStartIntermediateWarehouseItem(e) {
        state.editingIntermediateWarehouseItem = e.data;
        const oldData = {
            ...state.editingIntermediateWarehouseItem
        };
        state.editingIntermediateWarehouseItemOldData.push(oldData);
    }
    return {
        ...toRefs(state),
        handleRowInsertingIntermediateWarehouse,
        handleRowInsertedIntermediateWarehouse,
        handleEditingStartIntermediateWarehouse,
        handleEditCanceledIntermediateWarehouse,
        handleRowUpdatingIntermediateWarehouse,
        handleRowRemovingIntermediateWarehouse,
        handleCancelItermediateWarehouseItem,
        handleSaveNewIntermediateWarehouseItem,
        handleRowInsertingIntermediateWarehouseItem,
        handleEditingStartIntermediateWarehouseItem
    };
}
