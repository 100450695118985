import CustomStore from "devextreme/data/custom_store";
import { sendRequest } from "@/helpers/sendRequestHelper";
import config from "../../config.json";
import eventBus from "../../eventBus";

let isSubscribed = false; // Prevent duplicate subscriptions
let filterId = null;
const subscribeToEvent = () => {
    if (!isSubscribed) {
        eventBus.on("newFocusedRowDispatchNote", (id) => {
            filterId = id;
        });
        isSubscribed = true;
    }
};
subscribeToEvent();

export const dispatchNoteItem = new CustomStore({
    key: "id",
    load: async (loadOptions) => {
        try {
            let filterCondition;
            if (filterId !== null) {
                filterCondition = {
                    where: {
                        dispatchNoteId: filterId
                    }
                };
            }
            if (loadOptions.dispatchNoteId) {
                filterCondition = {
                    where: {
                        dispatchNoteId: loadOptions.dispatchNoteId
                    }
                };
            }
            if (!filterCondition) {
                eventBus.emit("dispatchNoteItems", []);
                return;
            }
            const newData = await sendRequest(
                `${config.API_URL}/${
                    config.PATHS.DISPATCH_NOTE_ITEM
                }?filter=${JSON.stringify(filterCondition)}`
            );
            eventBus.emit("dispatchNoteItems", newData);
            return newData;
        } catch (error) {
            console.error("Error loading data:", error);
            throw error;
        }
    },
    byKey: async (key) => {
        try {
            await sendRequest(
                `${config.API_URL}/${config.PATHS.DISPATCH_NOTE_ITEM}/${key}`
            );
        } catch (error) {
            console.error("Error loading data:", error);
            throw error;
        }
    },
    update: async (id, values) => {
        try {
            await sendRequest(
                `${config.API_URL}/${config.PATHS.DISPATCH_NOTE_ITEM}/${id}`,
                "PATCH",
                {
                    values: values
                }
            );
        } catch (error) {
            console.error("Error updating data:", error);
            throw error;
        }
    },
    insert: async (values) => {
        try {
            await sendRequest(
                `${config.API_URL}/${config.PATHS.DISPATCH_NOTE_ITEM}`,
                "POST",
                {
                    values: values
                }
            );
        } catch (error) {
            console.error("Error inserting data:", error);
            throw error;
        }
    },
    remove: async (id) => {
        try {
            await sendRequest(
                `${config.API_URL}/${config.PATHS.DISPATCH_NOTE_ITEM}/${id}`,
                "DELETE"
            );
        } catch (error) {
            console.error("Error removing data:", error);
            throw error;
        }
    }
});
