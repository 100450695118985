import { defineStore } from "pinia";
import config from "../../config.json";
import { sendRequest } from "@/helpers/sendRequestHelper";

export const useWarehouseItemStore = defineStore("warehouseItemStatusStore", {
    actions: {
        async updateWarehouseItemStatusOnNewDispatchNote(values) {
            try {
                const res = await sendRequest(
                    `${config.API_URL}/${config.PATHS.WAREHOUSE_ITEM_STATUS}/${config.PATHS.NEW}/${config.PATHS.DISPATCH_NOTE}`,
                    "PATCH",
                    {
                        values: values
                    }
                );
                return res;
            } catch (error) {
                console.error(error);
                throw error;
            }
        },
        async updateWarehouseItemStatusOnUpdatedDispatchNote(values) {
            try {
                const res = await sendRequest(
                    `${config.API_URL}/${config.PATHS.WAREHOUSE_ITEM_STATUS}/${config.PATHS.UPDATED}/${config.PATHS.DISPATCH_NOTE}`,
                    "PATCH",
                    {
                        values: values
                    }
                );
                return res;
            } catch (error) {
                console.error(error);
                throw error;
            }
        },
        async updateWarehouseItemStatusOnRemovedDispatchNote(values) {
            try {
                const res = await sendRequest(
                    `${config.API_URL}/${config.PATHS.WAREHOUSE_ITEM_STATUS}/${config.PATHS.REMOVED}/${config.PATHS.DISPATCH_NOTE}`,
                    "PATCH",
                    {
                        values: values
                    }
                );
                return res;
            } catch (error) {
                console.error(error);
                throw error;
            }
        },
        async updateWarehouseItemStatusOnNewReceipt(values) {
            try {
                const res = await sendRequest(
                    `${config.API_URL}/${config.PATHS.WAREHOUSE_ITEM_STATUS}/${config.PATHS.NEW}/${config.PATHS.RECEIPT}`,
                    "PATCH",
                    {
                        values: values
                    }
                );
                return res;
            } catch (error) {
                console.error(error);
                throw error;
            }
        },
        async updateWarehouseItemStatusOnUpdatedReceipt(values) {
            try {
                const res = await sendRequest(
                    `${config.API_URL}/${config.PATHS.WAREHOUSE_ITEM_STATUS}/${config.PATHS.UPDATED}/${config.PATHS.RECEIPT}`,
                    "PATCH",
                    {
                        values: values
                    }
                );
                return res;
            } catch (error) {
                console.error(error);
                throw error;
            }
        },
        async updateWarehouseItemStatusOnRemovedReceipt(values) {
            try {
                const res = await sendRequest(
                    `${config.API_URL}/${config.PATHS.WAREHOUSE_ITEM_STATUS}/${config.PATHS.REMOVED}/${config.PATHS.RECEIPT}`,
                    "PATCH",
                    {
                        values: values
                    }
                );
                return res;
            } catch (error) {
                console.error(error);
                throw error;
            }
        },
        async updateWarehouseItemStatusOnNewInitialState(values) {
            try {
                const res = await sendRequest(
                    `${config.API_URL}/${config.PATHS.WAREHOUSE_ITEM_STATUS}/${config.PATHS.NEW}/${config.PATHS.INITIAL_STATE}`,
                    "PATCH",
                    {
                        values: values
                    }
                );
                return res;
            } catch (error) {
                console.error(error);
                throw error;
            }
        },
        async updateWarehouseItemStatusOnUpdatedInitialState(values) {
            try {
                const res = await sendRequest(
                    `${config.API_URL}/${config.PATHS.WAREHOUSE_ITEM_STATUS}/${config.PATHS.UPDATED}/${config.PATHS.INITIAL_STATE}`,
                    "PATCH",
                    {
                        values: values
                    }
                );
                return res;
            } catch (error) {
                console.error(error);
                throw error;
            }
        },
        async updateWarehouseItemStatusOnRemovedInitialState(values) {
            try {
                const res = await sendRequest(
                    `${config.API_URL}/${config.PATHS.WAREHOUSE_ITEM_STATUS}/${config.PATHS.REMOVED}/${config.PATHS.INITIAL_STATE}`,
                    "PATCH",
                    {
                        values: values
                    }
                );
                return res;
            } catch (error) {
                console.error(error);
                throw error;
            }
        },
        async updateWarehouseItemStatusOnNewIntermediateWarehouse(values) {
            try {
                const res = await sendRequest(
                    `${config.API_URL}/${config.PATHS.WAREHOUSE_ITEM_STATUS}/${config.PATHS.NEW}/${config.PATHS.INTERMEDIATE_WAREHOUSE}`,
                    "PATCH",
                    {
                        values: values
                    }
                );
                return res;
            } catch (error) {
                console.error(error);
                throw error;
            }
        },
        async updateWarehouseItemStatusOnUpdatedIntermediateWarehouse(values) {
            try {
                const res = await sendRequest(
                    `${config.API_URL}/${config.PATHS.WAREHOUSE_ITEM_STATUS}/${config.PATHS.UPDATED}/${config.PATHS.INTERMEDIATE_WAREHOUSE}`,
                    "PATCH",
                    {
                        values: values
                    }
                );
                return res;
            } catch (error) {
                console.error(error);
                throw error;
            }
        },
        async updateWarehouseItemStatusOnRemovedIntermediateWarehouse(values) {
            try {
                const res = await sendRequest(
                    `${config.API_URL}/${config.PATHS.WAREHOUSE_ITEM_STATUS}/${config.PATHS.REMOVED}/${config.PATHS.INTERMEDIATE_WAREHOUSE}`,
                    "PATCH",
                    {
                        values: values
                    }
                );
                return res;
            } catch (error) {
                console.error(error);
                throw error;
            }
        }
    }
});
